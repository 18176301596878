import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogProps,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Radio,
  RadioGroup,
  Select,
  Stack,
  StepConnector,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
  AccordionSummary,
  Tooltip,
  FormControl,
  InputLabel,
} from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
// import SearchIcon from '@mui/icons-material/Search';
import {
  addOrganisationData,
  editOrganisationData,
  getDemogByOrg,
  getOrganisationData,
} from '../../reduxStore/reducer/organisationReducer';
import { AppDispatch, RootState } from '../../reduxStore/Store';
import MenuItem from '@mui/material/MenuItem';
import * as Yup from 'yup';
import { Organization } from '../organisation/OrganizationForm';
import { useEffect, useState } from 'react';
import { getDepartmentName } from '../../reduxStore/reducer/departmentReducer';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { display } from '@mui/system';
import {
  addCourseData,
  editCourseData,
  searchFromOtherOrg,
} from '../../reduxStore/reducer/courseReducer';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { AddCoursePrefix } from './AddCoursePrefix';
import { CoursePrefix } from '../coursePrefix/CoursePrefix';
import {
  editCoursePrefixData,
  getCoursePrefixName,
} from '../../reduxStore/reducer/coursePrefixReducer';
import { getCourseDetailsName } from '../../reduxStore/reducer/courseDetailsReducer';
import AddCourseDetails from './AddCourseDetails';
import { getUserById } from '../../reduxStore/reducer/registerReducer';
import { getUsersByOrganization } from '../../reduxStore/route/userRoute';
import {
  addUserAdmin,
  getInstructors,
  handleGetInstructors,
  inviteUser,
} from '../../reduxStore/reducer/userReducer';
import { AiOutlineClose } from 'react-icons/ai';
import SearchTransferList from './SearchTransferList';
import { withFormik } from 'formik';
import RemoveIcon from '@mui/icons-material/Remove';
import axios from 'axios';
// import { searchIcon } from "../../utils/icons";
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { getOrganisation } from '../../reduxStore/route/organisationRoute';
import { getOrganizationName } from '../../reduxStore/reducer/organisationReducer';
import jwt_decode from 'jwt-decode';
import { Scrollbar } from 'react-scrollbars-custom';
import { entries } from 'lodash';
import Alert from '../../utils/Alert/Alert';
import SunEditor from 'suneditor-react';
import katex from 'katex';
import { GiBreakingChain } from 'react-icons/gi';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { ClassNames } from '@emotion/react';
import { editCourse } from '../../reduxStore/route/courseRoute';
import {
  ArrowDownward,
  ArrowDropDownCircleOutlined,
  CheckCircle,
} from '@mui/icons-material';
import { BiErrorCircle } from 'react-icons/bi';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import ErrorIcon from '@mui/icons-material/Error';

type Options = {
  inputValue?: string;
  label: string;
  option: string;
};

type CourseOptions = {
  inputValue?: string;
  id: string;
  name: string;
};
interface CourseForm {
  id: string;
  term: Options | null;
  year: string;
  course_prefix_id: Options | null; // CoursePrefix table
  course_details_id: Options | null;

  times_taught: number;

  interactive_lecture: Options | null; //courseStrategies
  integrated_lab_lecture: Options | null; //..
  lecture: Options | null; //..
  discuss_idea: Options | null; //..
  design_experiments: Options | null; //..
  students_work_together: Options | null; //..
  instructor_quantitative_problems: Options | null; //..
  instructor_qualitative_problems: Options | null; //..
  student_quantitative_problems: Options | null; //..
  student_qualitative_problems: Options | null; //courseStrategies

  problem_solving: Options | null; //courseGoals
  conceptual_understanding: Options | null;
  attitude_expression: Options | null; //courseGoals

  is_collaborative_learning: boolean;
  is_usage: boolean;

  times_taught_la: number;
  times_used_la: number;
  usage_description1: string;
  usage_description2: string;
  week_plan: number;
  la_other_courses: string;
  usage_description3: string;

  is_credit: string;
  // pretest_credit: boolean;
  // posttest_credit: boolean;
  email_reminder: boolean;
  class_reminder: boolean;

  description: string;
  student_message_box: string;
  requirements: string;
  misc_data: string;
  is_agree: boolean;
  student_specific_message: boolean;
  is_test_course: boolean;
  is_active: true;
  created_by: string;
}

var mCoursePrefixName: any;
var mCourseNames: any;

export function correctBeforeAddValues(values: any, showOrgDemo: boolean) {
  const formState = { ...values };
  formState.term = values?.term.option;
  // formState.course_prefix_id = values.course_prefix_id.id;

  formState.course_details_id = values.course_details_id.id;
  formState.assessment_coordinators = values.assessment_coordinators.map(
    (e: any) => e.id
  );
  formState.instructors = values.instructors.map((e: any) => e.id);

  for (let key in formState.weekly_contact_hours)
    if (formState.weekly_contact_hours[key] == '')
      formState.weekly_contact_hours[key] = '0';

  // formState.org_specific_demographics = showOrgDemo;
  return formState;
}

export function correctBeforeEditValues(values: any) {
  const chooseOpt = (opt: number | string) => {
    switch (opt) {
      case 1 || '1':
        return { label: 'Never', option: '1' };
      case 2 || '2':
        return { label: 'Once or twice per term', option: '2' };
      case 3 || '3':
        return { label: 'Several times per term', option: '3' };
      case 4 || '4':
        return { label: 'Weekly', option: '4' };
      case 5 || '5':
        return { label: 'Nearly every class', option: '5' };
      case 6 || '6':
        return { label: 'Multiple times per class', option: '6' };
      default:
        return opt;
    }
  };

  switch (values.term) {
    case '1':
      values.term = { label: 'Fall', option: '1' };
      break;
    case '2':
      values.term = { label: 'Spring', option: '2' };
      break;
    case '3':
      values.term = { label: 'Summer', option: '3' };
      break;
    case '4':
      values.term = { label: 'Winter', option: '4' };
      break;
    default:
      break;
  }

  return values;
}

function AddEditCourse() {
  const dispatch = useDispatch<AppDispatch>();
  const [button, setButton] = useState<any>(false);
  const [courseField, setCourseField] = useState(false);

  const [openForm, setOpenForm] = useState<any>({
    open: false,
    callback: null,
  });
  const [openFormDetails, setOpenFormDetails] = useState(false);
  const [openLookupForm, setOpenLookupForm] = useState(false);
  const token: any = localStorage.getItem('token');

  const userData: {
    id: string;
    type: string;
    user: string;
  } = jwt_decode(token);

  const [openAddInstructorForm, setOpenAddInstructorForm] = useState<any>({
    isOpen: false,
    callback: null,
    data: null,
    addnew: null,
  });

  console.log(openAddInstructorForm, 'openAddInstructorForm');

  const navigate = useNavigate();
  let [initialValues, setInitialValues] = useState<any>();

  const orgDemographics: any = useSelector(
    (state: RootState) => state.organization.organizationDemographics
  );
  const orgDemographicsCopy = structuredClone(orgDemographics);

  if (orgDemographicsCopy?.type) {
    delete orgDemographicsCopy?.type;
  } else {
    console.log("Cannot delete 'type' because it is non-configurable");
  }

  console.log(orgDemographicsCopy, 'org demo');

  const courseNames: { id: string; name: string }[] = useSelector(
    (state: RootState) => state.coursedetails.CourseDetailsName
  );

  const coursePrefixName: { id: string; name: string }[] = useSelector(
    (state: RootState) => state.courseprefix.coursePrefixName
  );

  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationName
  );

  //

  const getUserDataAndType: any = useSelector(
    (state: any) => state.register.getUserById
  );

  const instructorsData: any = useSelector((state: any) => {
    const instructor: any = [...state.users.instructorsData];
    instructor.unshift({
      id: '',
      name: 'INVITE NEW USER',
      organization_name: '',
    });
    instructor.unshift({
      id: '',
      name: 'SEARCH BY EMAIL',
      organization_name: '',
    });

    const coordinator: any = [...state.users.instructorsData];
    coordinator.unshift({
      id: '',
      name: 'INVITE NEW USER',
      organization_name: '',
    });
    coordinator.unshift({
      id: '',
      name: 'SEARCH BY EMAIL',
      organization_name: '',
    });

    return { instructor: instructor, coordinator: coordinator };
  });

  if (courseNames) {
    mCourseNames = [...courseNames];
    mCourseNames = mCourseNames.map((course: any, index: any) => {
      return Object.assign({}, course, { key: index + 1 });
    });
    mCourseNames.unshift({
      id: '',
      name: 'ADD NEW COURSE NAME',
      prefix: '',
      number: '',
    });
  }

  if (coursePrefixName) {
    mCoursePrefixName = [...coursePrefixName];
    mCoursePrefixName = mCoursePrefixName.map((course: any, index: any) => {
      return Object.assign({}, course, { key: index + 1 });
    });

    // mCoursePrefixName.unshift({
    //   id: "",
    //   name: "ADD NEW PREFIX",
    // });
  }
  const { state, pathname }: any = useLocation();
  console.log(pathname, 'pathname');

  useEffect(() => {
    dispatch(
      getUserById({
        id: `${JSON.parse(localStorage.getItem('token') || '{}')}`,
      })
    );

    //get all instructors if admin else from particular orgnization
    if (getUserDataAndType.data?.type == 'faculty') {
      dispatch(getInstructors());
      dispatch(getCoursePrefixName());
      dispatch(getCourseDetailsName());
      dispatch(getDemogByOrg());
    }
    if (getUserDataAndType?.data?.type == 'admin') {
      dispatch(getOrganizationName());

      dispatch(getDemogByOrg(state?.organization_name?.id));
      // dispatch(getInstructors(state?.organization_name?.id));
    }

    console.log(state?.organization_name?.id, 'org id');
  }, [getUserDataAndType.data?.type]);

  const [organization, setOrganization] = useState<any>(null);

  const [openTerm, setOpenTerm] = useState(false);

  useEffect(() => {
    setOrganization(initialValues?.organization);
    dispatch(getInstructors(state?.organization_name?.id));
  }, [state?.organization]);

  const storedData = localStorage.getItem('courseFormData');
  console.log(storedData);
  const parsedStoredData = storedData ? JSON.parse(storedData) : null;

  if (state) {
    // const editFormValue = correctBeforeEditValues(state);
    //
    initialValues = state;

    if (!initialValues.instructors) initialValues.instructors = [];
    if (!initialValues.assessment_coordinators)
      initialValues.assessment_coordinators = [];

    //condition only statisifes @copy course
    if (pathname == '/addcourse') {
      const idLookup: any = {};
      initialValues.instructors = [
        {
          id: userData?.id,
          name: userData?.user,
          organization_name: '',
        },
        ...initialValues?.instructors,
      ].filter((obj: any) => {
        if (!idLookup[obj.id]) {
          idLookup[obj.id] = true;
          return true;
        }
        return false;
      });
    }

    // initialValues.is_agree = true;
  } else if (storedData && pathname === '/addcourse') {
    initialValues = JSON.parse(storedData);
  } else {
    initialValues = {
      id: '',
      term: null,
      year: moment().year().toString(),
      // course_prefix_id: null,
      course_details_id: null,
      is_credit: null,
      // pretest_credit: false,
      // posttest_credit: false,
      email_reminder: null,
      class_reminder: null,

      instructors: [
        {
          id: userData?.id,
          name: userData.user,
          organization_name: '',
        },
      ],
      assessment_coordinators: [],
      learning_type: [], //Is the course
      learning_type_other: '',
      course_enrollment_major: 0,
      course_enrollment_other: 0,
      weekly_contact_hours: { '1': '', '2': '', '3': '', '4': '', '5': '' },
      student_take_plan: [],
      use_near_peer: [],
      primary_use_of_near_peer: '',
      primary_use_of_near_peer_other: '',
      secondary_use_of_near_peer: '',
      secondary_use_of_near_peer_other: '',

      likert_questions: {
        '14': '',
        '15': '',
        '16': '',
        '17': '',
        '18': '',
        '19': '',
        '20': '',
        '21': '',
        '22': '',
        '23': '',
        '24': '',
        '25': '',
        '26': '',
        '27': '',
        '28': '',
        '29': '',
        '30': '',
        '31': '',
        '32': '',
        '33': '',
        '34': '',
        '35': '',
        '36': '',
        '37': '',
      },

      description: '',
      student_message_box: '',
      // requirements: "",
      is_agree: true,
      student_specific_message: '',
      // org_specific_demographics: isEmpty(orgDemographicsCopy) === false,
      // is_test_course: false,
      created_by: '',
    };
  }

  // if (!state || )
  //   if (storedData) {
  //     initialValues = JSON.parse(storedData);
  //   }

  // },[getUserDataAndType])

  const [coursePrefix, setCoursePrefix] = useState<any>(null);

  const [initialValuesPrefix, setInitialValuePrefix] = useState<CoursePrefix>({
    id: '',
    organization_name: null,
    name: '',
    is_active: true,
    created_by: '',
  });

  const [initialValuesDetails, setInitialValueDetails] = useState<any>({
    id: '',
    organization_name: null,
    name: '',
    number: '',
    course_prefix_name: null,
    is_active: true,
    created_by: '',
  });

  const termData: Options[] = [
    { label: 'Fall', option: '1' },
    { label: 'Spring', option: '2' },
    { label: 'Summer', option: '3' },
    { label: 'Winter', option: '4' },
  ];

  const validation = Yup.object({
    // learning_type: Yup.array().min(
    //   1,
    //   'Learning type must contain at least one value'
    // ),
    // use_near_peer: Yup.array().min(
    //   1,
    //   'use_near_peer must contain at least one value'
    // ),
    // courseQuestion: Yup.array().of(
    //   Yup.object().shape({
    //     questionContent: Yup.string().default('').required('Required field'),
    //   })
    // ),
  });

  const userValidation = Yup.object({
    first_name: Yup.string()
      .matches(
        /^[a-zA-z]/,
        'starting letter should not be a space, numbers or special characters'
      )
      .required('Required Field'),
    middle_name: Yup.string().matches(
      /^[a-zA-z]/,
      'starting letter should not be a space, numbers or special characters'
    ),
    last_name: Yup.string()
      .matches(
        /^[a-zA-z]/,
        'starting letter should not be a space, numbers or special characters'
      )
      .required('Required Field'),
    email: Yup.string().email().required('entered text is not a valid email'),
  });

  // const filter = createFilterOptions<CourseOptions>();
  const handleCloseAddUserForm = () => {
    Alert.confirm(() => {
      setOpenAddInstructorForm({
        ...openAddInstructorForm,
        addnew: null,
        isOpen: false,
      });
    });
    // setOpenAddInstructorForm({ ...openNewUserForm,  });
  };

  const navigateToCourse = () => {
    Alert.confirm(() => {
      navigate('/course');
      localStorage.removeItem('courseFormData');
    });
  };

  const likertScaleQuestions = [
    {
      question:
        'I guide students through major topics as they listen and take notes',
      no: 14,
    },
    {
      question:
        "I design activities that connect course content to my student's lives and future work",
      no: 15,
    },
    {
      question:
        'My syllabus contains the specific topics that will be covered in every class session',
      no: 16,
    },
    {
      question:
        'I provide students with immediate feedback on their work during class (e.g., student response systems, short quizzes)',
      no: 17,
    },
    {
      question:
        'I structure my course with the assumption that most of the students have little useful knowledge of the topics',
      no: 18,
    },
    {
      question:
        'I use student assessment results to guide the direction of my instruction during the semester',
      no: 19,
    },
    {
      question:
        'I frequently ask students to respond to questions during class time',
      no: 20,
    },
    {
      question:
        'I use student questions and comments to determine the focus and direction of classroom discussion',
      no: 21,
    },
    {
      question:
        'I have students use a variety of means (models, drawings, graphs, symbols, simulations, etc.) to represent phenomena',
      no: 22,
    },
    {
      question:
        'I structure class so that students explore or discuss their understanding of new concepts before formal instruction',
      no: 23,
    },
    {
      question:
        'My class sessions are structured to give students a good set of notes',
      no: 24,
    },
    {
      question:
        'I structure class so that students regularly talk with one another about course concepts',
      no: 25,
    },
    {
      question:
        "I structure class so that students constructively criticize one another's ideas",
      no: 26,
    },
    {
      question:
        'I structure class so that students discuss the difficulties they have with this subject with other students',
      no: 27,
    },
    {
      question: 'I require students to work together in small groups',
      no: 28,
    },
    {
      question:
        'I structure problems so that students consider multiple approaches to finding a solution',
      no: 29,
    },
    {
      question:
        'I provide time for students to reflect about the processes they use to solve problems',
      no: 30,
    },
    {
      question:
        'I give students frequent assignments worth a small portion of their grade',
      no: 31,
    },
    {
      question:
        'I require students to make connections between related ideas or concepts when completing assignments',
      no: 32,
    },
    {
      question:
        'I provide feedback on student assignments without assigning a formal grade',
      no: 33,
    },
    {
      question:
        'My test questions focus on important facts and definitions from the course',
      no: 34,
    },
    {
      question:
        'My test questions require students to apply course concepts to unfamiliar situations',
      no: 35,
    },
    {
      question:
        'My test questions contain well-defined problems with one correct solution',
      no: 36,
    },
    {
      question:
        'I adjust student scores (e.g. curve) when necessary to reflect a proper distribution of grades',
      no: 37,
    },
  ];
  const likertScaleAnswers = [
    {
      label: 'Not at all',
      val: '1',
    },
    {
      label: 'Minimally',
      val: '2',
    },
    {
      label: 'Somewhat',
      val: '3',
    },
    {
      label: 'Mostly',
      val: '4',
    },
    {
      label: 'Very',
      val: '5',
    },
  ];
  const requiredQuestions = [14, 24, 25, 27];
  const optionalQuestions = likertScaleQuestions.filter(
    (q) => !requiredQuestions.includes(q.no)
  );

  const Item = styled(Box)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    // ...theme.typography.body2,
    padding: theme.spacing(1),
    // textAlign: "center",
    // color: theme.palette.text.secondary,
  }));

  const questionStyle = 'font-[450]';

  const manualValidation = (values: any) => {
    let error = false;

    if (values.learning_type.length == 0) {
      Alert.error({
        title: 'Validation failed',
        text: 'Is the course is required',
      });
      return true;
    }

    if (values.student_take_plan.length == 0) {
      Alert.error({
        title: 'Validation failed',
        text: 'Student take plan is required',
      });
      return true;
    }

    if (values.use_near_peer.length == 0) {
      Alert.error({
        title: 'Validation failed',
        text: 'Use near peer is required',
      });
      return true;
    }

    // Check use_near_peer value
    const useNearPeerValue = parseInt(values.use_near_peer);
    if (
      useNearPeerValue == 1 ||
      useNearPeerValue == 2 ||
      useNearPeerValue == 3 ||
      useNearPeerValue == 4
    ) {
      // Check if primary_use_of_near_peer or primary_use_of_near_peer_other is filled
      if (
        values.primary_use_of_near_peer.length == 0 &&
        values.primary_use_of_near_peer_other.length == 0
      ) {
        Alert.error({
          title: 'Validation failed',
          text: 'Primary use of near peer is required',
        });
        return true;
      }
    }

    // Check use_near_peer value
    const useNearPeerSecondaryValue = parseInt(values.use_near_peer);
    if (
      useNearPeerSecondaryValue == 1 ||
      useNearPeerSecondaryValue == 2 ||
      useNearPeerSecondaryValue == 3 ||
      useNearPeerSecondaryValue == 4
    ) {
      // Check if primary_use_of_near_peer or primary_use_of_near_peer_other is filled
      if (
        values.secondary_use_of_near_peer.length == 0 &&
        values.secondary_use_of_near_peer_other.length == 0
      ) {
        Alert.error({
          title: 'Validation failed',
          text: 'Secondary use of near peer is required',
        });
        return true;
      }
    }

    return error;
  };

  const [isTyping, setIsTyping] = useState(false);

  return (
    <main>
      <header className='header'>
        {/* <Typography variant='h5'> */}
        {pathname == '/addcourse' ? 'Create a New Course' : 'Edit Course'}
        {/* </Typography> */}
      </header>
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={(values) => {
          if (manualValidation(values)) return;
          const formState = correctBeforeAddValues(
            values,
            isEmpty(orgDemographicsCopy) === false
          );

          const data: any = { ...formState };

          if (pathname == '/addcourse') {
            setButton(true);
            addCourseData(data, navigate, '', setButton);
          } else {
            setButton(true);
            editCourseData(data, navigate, setButton);
          }
          localStorage.removeItem('courseFormData');
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
        }) => (
          <Form>
            <Accordion className='border border-[#CBD5E1] rounded-md  p-3 pl-2 mb-5'>
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls='panel2-content'
                id='panel2-header'
              >
                {values?.term &&
                values?.course_details_id &&
                (getUserDataAndType?.data?.type === 'faculty' ||
                  values?.organization_name) ? (
                  <CheckCircle fontSize='large' style={{ color: '#0E660A' }} />
                ) : (
                  <ErrorIcon fontSize='large' style={{ color: '#F9765C' }} />
                )}
                <Typography
                  component='h2'
                  variant='h6'
                  sx={{ marginLeft: '12px' }}
                >
                  Course Name (Required)
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container rowGap={2} columnGap={10} className='p-3 pl-6'>
                  <Grid item xs={12} sm={3}>
                    <label className={`${questionStyle} right-aligning-labels`}>
                      Term
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name='term'
                      as={Autocomplete}
                      className='max-w-xs'
                      value={values?.term}
                      size='small'
                      options={termData?.length ? termData : []}
                      getOptionLabel={(option: Options) => option?.label}
                      isOptionEqualToValue={(option: Options, value: Options) =>
                        option?.label === value?.label
                      }
                      onChange={(
                        event: React.SyntheticEvent,
                        term: Options
                      ) => {
                        setFieldValue('term', term);
                        if (pathname === '/addcourse' && !state) {
                          localStorage.setItem(
                            'courseFormData',
                            JSON.stringify({
                              ...values,
                              term: term,
                            })
                          );
                        }
                      }}
                      onBlur={() => setFieldTouched('term', true)}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextField
                          {...params}
                          variant='outlined'
                          placeholder='Select'
                          error={errors?.term && touched?.term ? true : false}
                          required
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <label className={`${questionStyle} right-aligning-labels`}>
                      Year
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className='w-full max-w-xs'
                        views={['year']}
                        value={values?.year}
                        onChange={(newValue: any) => {
                          setFieldValue('year', newValue.year().toString());
                        }}
                        renderInput={(params: any) => (
                          <TextField {...params} required size='small' />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  {getUserDataAndType?.data?.type === 'admin' && (
                    <>
                      <Grid item xs={12} sm={3}>
                        <label
                          className={`${questionStyle} right-aligning-labels`}
                        >
                          Organization
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          name='organization_name'
                          as={Autocomplete}
                          className='max-w-xs'
                          value={values?.organization_name}
                          size='small'
                          options={
                            organizationData?.length ? organizationData : []
                          }
                          fullWidth
                          getOptionLabel={(org: any) =>
                            org?.name === undefined ? '' : org?.name
                          }
                          isOptionEqualToValue={(option: any, value: any) =>
                            option?.id === value
                          }
                          onChange={(_: any, name: any) => {
                            dispatch(getDemogByOrg(name?.id));
                            setOrganization(name);
                            setFieldValue('organization_name', name);
                            if (pathname === '/addcourse' && !state) {
                              localStorage.setItem(
                                'courseFormData',
                                JSON.stringify({
                                  ...values,
                                  organization_name: name,
                                })
                              );
                            }
                            setFieldValue('course_details_id', null);
                            if (values?.instructors.length > 0)
                              setFieldValue('instructors', [
                                {
                                  id: getUserDataAndType?.data?.id,
                                  name: `${getUserDataAndType?.data?.first_name} ${getUserDataAndType?.data?.last_name}`,
                                  organization_name: '',
                                },
                              ]);
                            setFieldValue('assessment_coordinators', []);
                            dispatch(getCoursePrefixName(name?.id));
                            dispatch(
                              getCourseDetailsName(name?.id, setCourseField)
                            );
                            dispatch(getInstructors(name?.id));
                          }}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              name='organization_name'
                              placeholder='Select'
                              value={values?.organization_name}
                              error={
                                errors?.organization_name &&
                                touched?.organization_name
                                  ? true
                                  : false
                              }
                              required
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} sm={3}>
                    <label className={`${questionStyle} right-aligning-labels`}>
                      Course
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {getUserDataAndType?.data?.type === 'admin' && (
                      <>
                        <Field
                          name='course_id'
                          as={Autocomplete}
                          open={courseField}
                          onOpen={() => {
                            dispatch(
                              getCourseDetailsName(
                                values?.organization_name?.id,
                                setCourseField
                              )
                            );
                          }}
                          onClose={() => {
                            setCourseField(false);
                          }}
                          className='max-w-xs'
                          disabled={values?.organization_name ? false : true}
                          value={values?.course_details_id}
                          size='small'
                          options={mCourseNames?.length ? mCourseNames : []}
                          getOptionLabel={(option: any) => {
                            let optionLabelValues = mCourseNames?.find(
                              (e: any) => e?.id == values?.course_details_id?.id
                            );
                            let optionPrefix = option?.prefix
                              ? option?.prefix
                              : optionLabelValues?.prefix || ' ';
                            optionPrefix =
                              option?.name == 'ADD NEW COURSE NAME'
                                ? ' '
                                : optionPrefix;
                            return `${optionPrefix} ${option?.number} ${
                              option?.name == 'ADD NEW COURSE NAME' ? '' : ' - '
                            } ${option?.name}`;
                          }}
                          isOptionEqualToValue={(
                            option: CourseOptions,
                            value: CourseOptions
                          ) => option?.name === value?.name}
                          onChange={(
                            event: React.SyntheticEvent,
                            course_id: { id: string; name: string }
                          ) => {
                            if (course_id?.name == 'ADD NEW COURSE NAME') {
                              setOrganization(values.organization_name);
                              setOpenFormDetails({
                                ...openForm,
                                ...{
                                  open: true,
                                  callback: (field: any, value: any) => {
                                    setFieldValue(field, value);
                                    if (pathname === '/addcourse' && !state) {
                                      localStorage.setItem(
                                        'courseFormData',
                                        JSON.stringify({
                                          ...values,
                                          course_details_id: value,
                                        })
                                      );
                                    }
                                  },
                                },
                              });
                            } else {
                              setFieldValue('course_details_id', course_id);
                            }
                            if (pathname === '/addcourse' && !state) {
                              localStorage.setItem(
                                'courseFormData',
                                JSON.stringify({
                                  ...values,
                                  course_details_id: course_id,
                                })
                              );
                            }
                          }}
                          onBlur={() =>
                            setFieldTouched('course_details_id', true)
                          }
                          renderInput={(
                            params: AutocompleteRenderInputParams
                          ) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              placeholder='Select'
                              error={
                                errors.course_details_id &&
                                touched.course_details_id
                                  ? true
                                  : false
                              }
                              required
                            />
                          )}
                          renderOption={(props: any, option: any) => (
                            <li
                              {...props}
                              style={
                                option.name === 'ADD NEW COURSE NAME'
                                  ? { color: '#5fb0ed' }
                                  : {}
                              }
                            >
                              {option.name}
                            </li>
                          )}
                        />
                      </>
                    )}

                    {getUserDataAndType?.data?.type === 'faculty' && (
                      <Field
                        name='course_id'
                        as={Autocomplete}
                        className='max-w-xs'
                        open={courseField}
                        onOpen={() => {
                          setCourseField(true);
                        }}
                        onClose={() => {
                          setCourseField(false);
                        }}
                        value={values?.course_details_id}
                        size='small'
                        options={mCourseNames?.length ? mCourseNames : []}
                        getOptionLabel={(option: any) => {
                          let optionLabelValues = mCourseNames?.find(
                            (e: any) => e?.id == values?.course_details_id?.id
                          );
                          let optionPrefix = option?.prefix
                            ? option?.prefix
                            : optionLabelValues?.prefix || ' ';
                          optionPrefix =
                            option?.name == 'ADD NEW COURSE NAME'
                              ? ' '
                              : optionPrefix;
                          return `${optionPrefix} ${option?.number} ${
                            option?.name == 'ADD NEW COURSE NAME' ? '' : ' - '
                          } ${option?.name}`;
                        }}
                        isOptionEqualToValue={(
                          option: CourseOptions,
                          value: CourseOptions
                        ) => option?.name === value?.name}
                        onChange={(
                          event: React.SyntheticEvent,
                          course_id: { id: string; name: string }
                        ) => {
                          if (course_id?.name == 'ADD NEW COURSE NAME') {
                            setOpenFormDetails({
                              ...openForm,
                              ...{ open: true, callback: setFieldValue },
                            });
                          } else {
                            setFieldValue('course_details_id', course_id);
                          }
                          if (pathname === '/addcourse' && !state) {
                            localStorage.setItem(
                              'courseFormData',
                              JSON.stringify({
                                ...values,
                                course_details_id: course_id,
                              })
                            );
                          }
                        }}
                        onBlur={() =>
                          setFieldTouched('course_details_id', true)
                        }
                        renderInput={(
                          params: AutocompleteRenderInputParams
                        ) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            placeholder='Select'
                            error={
                              errors.course_details_id &&
                              touched.course_details_id
                                ? true
                                : false
                            }
                            required
                          />
                        )}
                        renderOption={(props: any, option: any) => (
                          <li
                            {...props}
                            style={
                              option.name === 'ADD NEW COURSE NAME'
                                ? { color: '#5fb0ed' }
                                : {}
                            }
                          >
                            {option.name}
                          </li>
                        )}
                      />
                    )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion className='border border-[#CBD5E1] rounded-md  p-3 pl-2 mb-5'>
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls='panel2-content'
                id='panel2-header'
              >
                {Array.isArray(values?.instructors) &&
                values?.instructors?.length > 0 &&
                Array.isArray(values?.assessment_coordinators) &&
                values?.assessment_coordinators?.length > 0 &&
                ((Array.isArray(values?.learning_type) &&
                  values?.learning_type?.length > 0) ||
                  values?.learning_type_other) &&
                values?.course_enrollment_major &&
                values?.course_enrollment_other &&
                Array.isArray(values?.student_take_plan) &&
                values?.student_take_plan.length > 0 &&
                Array.isArray(values?.use_near_peer) &&
                values?.use_near_peer?.length > 0 ? (
                  <CheckCircle fontSize='large' style={{ color: '#0E660A' }} />
                ) : (
                  <ErrorIcon fontSize='large' style={{ color: '#F9765C' }} />
                )}
                <Typography
                  component='h2'
                  variant='h6'
                  sx={{ marginLeft: '12px' }}
                >
                  {' '}
                  Course Information (Required){' '}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container rowGap={2} columnGap={10} className='p-3 pl-6'>
                  <Grid item xs={12} sm={3}>
                    <label className={`${questionStyle} right-aligning-labels`}>
                      Instructors
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {getUserDataAndType?.data?.type === 'admin' && (
                      <Field
                        name='instructors'
                        multiple
                        disabled={values?.organization_name ? false : true}
                        as={Autocomplete}
                        disableCloseOnSelect
                        filterSelectedOptions
                        clearOnEscape
                        openOnFocus
                        className='max-w-xs'
                        value={values?.instructors}
                        size='small'
                        options={
                          instructorsData?.instructor
                            ? instructorsData?.instructor
                            : []
                        }
                        getOptionLabel={(option: any) => option?.name}
                        isOptionEqualToValue={(option: any, value: any) => {
                          return option.id === value.id;
                        }}
                        onChange={(event: any, instructor: any) => {
                          if (
                            instructor[instructor?.length - 1]?.name ==
                            'INVITE NEW USER'
                          ) {
                            setOrganization(values.organization_name);

                            setOpenAddInstructorForm({
                              isOpen: true,
                              callback: (field: any, value: any) => {
                                setFieldValue(field, value);
                                if (pathname === '/addcourse' && !state) {
                                  localStorage.setItem(
                                    'courseFormData',
                                    JSON.stringify({
                                      ...values,
                                      instructors: value,
                                    })
                                  );
                                }
                              },
                              name: 'instructor',
                              data: instructor,
                            });
                          } else if (
                            instructor[instructor?.length - 1]?.name ==
                            'SEARCH BY EMAIL'
                          ) {
                            setOpenLookupForm(true);
                            setOpenAddInstructorForm({
                              isOpen: false,
                              callback: (field: any, value: any) => {
                                setFieldValue(field, value);
                                if (pathname === '/addcourse' && !state) {
                                  localStorage.setItem(
                                    'courseFormData',
                                    JSON.stringify({
                                      ...values,
                                      instructors: value,
                                    })
                                  );
                                }
                              },
                              name: 'instructor',
                              data: instructor,
                            });
                          } else {
                            setFieldValue('instructors', instructor);
                            if (pathname === '/addcourse' && !state) {
                              localStorage.setItem(
                                'courseFormData',
                                JSON.stringify({
                                  ...values,
                                  instructors: instructor,
                                })
                              );
                            }
                          }
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            placeholder='Select'
                            required={values?.instructors?.length === 0}
                          />
                        )}
                      />
                    )}
                    {getUserDataAndType?.data?.type === 'faculty' && (
                      <Field
                        name='instructors'
                        multiple
                        as={Autocomplete}
                        disableCloseOnSelect
                        filterSelectedOptions
                        clearOnEscape
                        openOnFocus
                        className='max-w-xs'
                        value={values.instructors}
                        size='small'
                        options={
                          instructorsData?.instructor
                            ? instructorsData?.instructor
                            : []
                        }
                        getOptionLabel={(option: any) => option?.name}
                        isOptionEqualToValue={(option: any, value: any) => {
                          return option.id === value.id;
                        }}
                        onChange={(event: any, instructor: any) => {
                          if (
                            instructor[instructor?.length - 1]?.name ==
                            'INVITE NEW USER'
                          ) {
                            setOpenAddInstructorForm({
                              isOpen: true,
                              callback: (field: any, value: any) => {
                                setFieldValue(field, value);
                                if (pathname === '/addcourse' && !state) {
                                  localStorage.setItem(
                                    'courseFormData',
                                    JSON.stringify({
                                      ...values,
                                      instructors: value,
                                    })
                                  );
                                }
                              },
                              name: 'instructor',
                              data: instructor,
                            });
                          } else if (
                            instructor[instructor?.length - 1]?.name ==
                            'SEARCH BY EMAIL'
                          ) {
                            setOpenLookupForm(true);
                            setOpenAddInstructorForm({
                              isOpen: false,
                              callback: (field: any, value: any) => {
                                setFieldValue(field, value);
                                if (pathname === '/addcourse' && !state) {
                                  localStorage.setItem(
                                    'courseFormData',
                                    JSON.stringify({
                                      ...values,
                                      instructors: value,
                                    })
                                  );
                                }
                              },
                              name: 'instructor',
                              data: instructor,
                            });
                          } else {
                            setFieldValue('instructors', instructor);
                            if (pathname === '/addcourse' && !state) {
                              localStorage.setItem(
                                'courseFormData',
                                JSON.stringify({
                                  ...values,
                                  instructors: instructor,
                                })
                              );
                            }
                          }
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            placeholder='Select'
                            required={values?.instructors?.length === 0}
                          />
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <label className={`${questionStyle} right-aligning-labels`}>
                      Assessment Coordinators
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {getUserDataAndType?.data?.type === 'admin' && (
                      <Field
                        name='assessment_coordinators'
                        multiple
                        as={Autocomplete}
                        disabled={values?.organization_name ? false : true}
                        disableCloseOnSelect
                        filterSelectedOptions
                        clearOnEscape
                        openOnFocus
                        className='max-w-xs'
                        value={values?.assessment_coordinators}
                        size='small'
                        options={
                          instructorsData?.coordinator
                            ? instructorsData?.coordinator
                            : []
                        }
                        getOptionLabel={(option: any) => option?.name}
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                        onChange={(
                          event: any,
                          assessment_coordinators: any
                        ) => {
                          const filteredCoordinators =
                            assessment_coordinators.filter(
                              (coordinator: any) =>
                                coordinator.name !== 'SEARCH BY EMAIL'
                            );

                          if (
                            assessment_coordinators[
                              assessment_coordinators?.length - 1
                            ]?.name === 'INVITE NEW USER'
                          ) {
                            setOrganization(values.organization_name);

                            setOpenAddInstructorForm({
                              isOpen: true,
                              callback: (field: any, value: any) => {
                                setFieldValue(field, value);
                                if (pathname === '/addcourse' && !state) {
                                  localStorage.setItem(
                                    'courseFormData',
                                    JSON.stringify({
                                      ...values,
                                      assessment_coordinators: value,
                                    })
                                  );
                                }
                              },
                              name: 'assessment_coordinators',
                              data: assessment_coordinators,
                            });
                          } else if (
                            assessment_coordinators[
                              assessment_coordinators?.length - 1
                            ]?.name === 'SEARCH BY EMAIL'
                          ) {
                            setOpenLookupForm(true);
                            setOpenAddInstructorForm({
                              isOpen: false,
                              callback: (field: any, value: any) => {
                                setFieldValue(field, value);
                                if (pathname === '/addcourse' && !state) {
                                  localStorage.setItem(
                                    'courseFormData',
                                    JSON.stringify({
                                      ...values,
                                      assessment_coordinators: value,
                                    })
                                  );
                                }
                              },
                              name: 'assessment_coordinators',
                              data: assessment_coordinators,
                            });
                          } else {
                            setFieldValue(
                              'assessment_coordinators',
                              filteredCoordinators
                            );
                            if (pathname === '/addcourse' && !state) {
                              localStorage.setItem(
                                'courseFormData',
                                JSON.stringify({
                                  ...values,
                                  assessment_coordinators: filteredCoordinators,
                                })
                              );
                            }
                          }
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            placeholder='Select'
                            required={
                              values?.assessment_coordinators?.length === 0
                            }
                          />
                        )}
                      />
                    )}
                    {getUserDataAndType?.data?.type === 'faculty' && (
                      <Field
                        name='assessment_coordinators'
                        multiple
                        as={Autocomplete}
                        disableCloseOnSelect
                        filterSelectedOptions
                        clearOnEscape
                        openOnFocus
                        className='max-w-xs'
                        value={values.assessment_coordinators}
                        size='small'
                        options={
                          instructorsData?.coordinator
                            ? instructorsData?.coordinator
                            : []
                        }
                        getOptionLabel={(option: any) => option?.name}
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                        onChange={(
                          event: any,
                          assessment_coordinators: any
                        ) => {
                          const filteredCoordinators =
                            assessment_coordinators.filter(
                              (coordinator: any) =>
                                coordinator.name !== 'SEARCH BY EMAIL'
                            );

                          if (
                            assessment_coordinators[
                              assessment_coordinators?.length - 1
                            ]?.name === 'INVITE NEW USER'
                          ) {
                            setOpenAddInstructorForm({
                              isOpen: true,
                              callback: (field: any, value: any) => {
                                setFieldValue(field, value);
                                if (pathname === '/addcourse' && !state) {
                                  localStorage.setItem(
                                    'courseFormData',
                                    JSON.stringify({
                                      ...values,
                                      assessment_coordinators: value,
                                    })
                                  );
                                }
                              },
                              name: 'assessment_coordinators',
                              data: assessment_coordinators,
                            });
                          } else if (
                            assessment_coordinators[
                              assessment_coordinators?.length - 1
                            ]?.name === 'SEARCH BY EMAIL'
                          ) {
                            setOpenLookupForm(true);
                            setOpenAddInstructorForm({
                              isOpen: false,
                              callback: (field: any, value: any) => {
                                setFieldValue(field, value);
                                if (pathname === '/addcourse' && !state) {
                                  localStorage.setItem(
                                    'courseFormData',
                                    JSON.stringify({
                                      ...values,
                                      assessment_coordinators: value,
                                    })
                                  );
                                }
                              },
                              name: 'assessment_coordinators',
                              data: assessment_coordinators,
                            });
                          } else {
                            setFieldValue(
                              'assessment_coordinators',
                              filteredCoordinators
                            );
                            if (pathname === '/addcourse' && !state) {
                              localStorage.setItem(
                                'courseFormData',
                                JSON.stringify({
                                  ...values,
                                  assessment_coordinators: filteredCoordinators,
                                })
                              );
                            }
                          }
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            placeholder='Select'
                            required={
                              values?.assessment_coordinators?.length === 0
                            }
                          />
                        )}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <label className={`${questionStyle} right-aligning-labels`}>
                      Is the course
                    </label>
                    {errors?.learning_type && touched?.learning_type ? (
                      <p className='text-red-400 mt-1 font-bold text-base italic'>
                        Required Field
                      </p>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid
                    container
                    rowGap={2}
                    item
                    xs={12}
                    sm={6}
                    flexDirection={'column'}
                  >
                    <Grid>
                      <Field
                        as={Select}
                        className='max-w-xs max-h-10'
                        name='learning_type'
                        value={values?.learning_type[0] || ''}
                        onChange={(event: any) => {
                          const value = event.target.value;
                          const newLearningType = value ? [value] : [];
                          setFieldValue('learning_type', newLearningType);
                          if (pathname === '/addcourse' && !state) {
                            localStorage.setItem(
                              'courseFormData',
                              JSON.stringify({
                                ...values,
                                learning_type: newLearningType,
                              })
                            );
                          }
                        }}
                        displayEmpty
                        fullWidth
                      >
                        <MenuItem value='' disabled>
                          Select Learning Type
                        </MenuItem>
                        <MenuItem value='1'>In Person</MenuItem>
                        <MenuItem value='2'>Online</MenuItem>
                        <MenuItem value='3'>Hybrid</MenuItem>
                      </Field>
                    </Grid>
                    <Grid>
                      <span className='mx-1 font-[450] mr-3 mt-2 inline-block'>
                        Other
                      </span>
                      <Field
                        name='learning_type_other'
                        as={TextField}
                        placeholder='Other'
                        size='small'
                        onBlur={() => {
                          if (pathname === '/addcourse' && !state) {
                            localStorage.setItem(
                              'courseFormData',
                              JSON.stringify({
                                ...values,
                                learning_type_other: values.learning_type_other,
                              })
                            );
                          }
                        }}
                      />
                    </Grid>
                  </Grid>

                  {/* {orgDemographics?.type == 3 ? ( */}
                  <>
                    <Grid item xs={12} sm={3}>
                      <label
                        className={`${questionStyle} right-aligning-labels`}
                      >
                        Course Enrollment: Majors in your discipline
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name='course_enrollment_major'
                        as={TextField}
                        className='no-spin'
                        variant='outlined'
                        size='small'
                        placeholder='Percent'
                        type='number'
                        inputProps={{
                          min: 0,
                          max: 100,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>%</InputAdornment>
                          ),
                        }}
                        onChange={(e: any) => {
                          const value = parseInt(e.target.value);
                          if (value > 100) {
                            e.target.value = '100';
                            setFieldValue('course_enrollment_major', 100);
                            setFieldValue('course_enrollment_other', 0);
                          } else {
                            setFieldValue('course_enrollment_major', value);
                            setFieldValue(
                              'course_enrollment_other',
                              100 - value
                            );
                          }
                          if (pathname === '/addcourse' && !state) {
                            localStorage.setItem(
                              'courseFormData',
                              JSON.stringify({
                                ...values,
                                course_enrollment_major:
                                  value > 100 ? 100 : value,
                                course_enrollment_other:
                                  value > 100 ? 0 : 100 - value,
                              })
                            );
                          }
                        }}
                        error={
                          errors.course_enrollment_major &&
                          touched.course_enrollment_major
                        }
                        required
                      />
                      {errors.course_enrollment_major &&
                        touched.course_enrollment_major && (
                          <p style={{ color: 'red' }}>
                            Please enter a value less than or equal to 100%
                          </p>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <label
                        className={`${questionStyle} right-aligning-labels`}
                      >
                        Course Enrollment: Majors in other disciplines
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name='course_enrollment_other'
                        as={TextField}
                        className='no-spin'
                        variant='outlined'
                        type='number'
                        inputProps={{
                          min: 0,
                          max: 100,
                          step: 1, // Restrict to integer values only
                        }}
                        size='small'
                        placeholder='Percent'
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>%</InputAdornment>
                          ),
                        }}
                        onChange={(e: any) => {
                          const value = parseInt(e.target.value);
                          if (value > 100) {
                            e.target.value = '100';
                            handleChange(e);
                            handleChange({
                              target: {
                                name: 'course_enrollment_major',
                                value: '0',
                              },
                            });
                          } else {
                            handleChange(e);
                            handleChange({
                              target: {
                                name: 'course_enrollment_major',
                                value: `${100 - value}`,
                              },
                            });
                          }
                        }}
                        error={
                          errors.course_enrollment_other &&
                          touched.course_enrollment_other
                        }
                        required
                      />
                      <ErrorMessage
                        name='course_enrollment_other'
                        component='div'
                      />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <label
                        className={`${questionStyle} right-aligning-labels`}
                      >
                        Weekly contact hours for students in the course
                      </label>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      sm={6}
                      rowGap={2}
                      columnGap={10}
                      sx={{ alignItems: 'center' }}
                    >
                      <Grid item xs={12} sm={4}>
                        <label>a. Lecture</label>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          name='weekly_contact_hours.1'
                          as={TextField}
                          className='no-spin'
                          variant='outlined'
                          size='small'
                          type='number'
                          inputProps={{
                            min: 0,
                            max: 100,
                            step: '0.01', // Allows decimal numbers
                            'aria-label': 'Input field for Lecture',
                            // onKeyPress: (event: any) => {
                            //   if (!/[0-9]/.test(event.key) && event.key !== '.') {
                            //     event.preventDefault();
                            //   }
                            // },
                          }}
                          onChange={(e: any) => {
                            // console.log('Entered v: ', e.target.value);
                            // console.log('parsed v:', e.target.value);

                            const value = parseFloat(e.target.value);
                            setFieldValue('weekly_contact_hours.1', value);
                            if (pathname === '/addcourse' && !state) {
                              localStorage.setItem(
                                'courseFormData',
                                JSON.stringify({
                                  ...values,
                                  weekly_contact_hours: {
                                    ...values.weekly_contact_hours,
                                    1: value,
                                  },
                                })
                              );
                            }
                          }}
                          error={
                            errors.weekly_contact_hours &&
                            touched.weekly_contact_hours
                              ? true
                              : false
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <label>b. Lab</label>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          name='weekly_contact_hours.2'
                          as={TextField}
                          className='no-spin'
                          variant='outlined'
                          size='small'
                          type='number'
                          inputProps={{
                            min: 0,
                            max: 100,
                            step: '0.01',
                            'aria-label': 'Input field for Lab',
                          }}
                          onChange={(e: any) => {
                            const value = parseFloat(e.target.value);
                            setFieldValue('weekly_contact_hours.2', value);
                            if (pathname === '/addcourse' && !state) {
                              localStorage.setItem(
                                'courseFormData',
                                JSON.stringify({
                                  ...values,
                                  weekly_contact_hours: {
                                    ...values.weekly_contact_hours,
                                    2: value,
                                  },
                                })
                              );
                            }
                          }}
                          error={
                            errors.weekly_contact_hours &&
                            touched.weekly_contact_hours
                              ? true
                              : false
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <div style={{ width: '13rem' }} className='flex gap-1'>
                          <span>c.</span>
                          <label>Combined Lecture / Lab</label>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          name='weekly_contact_hours.3'
                          as={TextField}
                          className='no-spin'
                          variant='outlined'
                          size='small'
                          type='number'
                          inputProps={{
                            min: 0,
                            max: 100,
                            step: '0.01',
                            'aria-label':
                              'Input field for Combined Lecture / Lab',
                          }}
                          onChange={(e: any) => {
                            const value = parseFloat(e.target.value);
                            setFieldValue('weekly_contact_hours.3', value);
                            if (pathname === '/addcourse' && !state) {
                              localStorage.setItem(
                                'courseFormData',
                                JSON.stringify({
                                  ...values,
                                  weekly_contact_hours: {
                                    ...values.weekly_contact_hours,
                                    3: value,
                                  },
                                })
                              );
                            }
                          }}
                          error={
                            errors.weekly_contact_hours &&
                            touched.weekly_contact_hours
                              ? true
                              : false
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <div style={{ width: '13rem' }} className='flex gap-1'>
                          <span>d.</span>
                          <label>Discussion / Recitation</label>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          name='weekly_contact_hours.4'
                          as={TextField}
                          className='no-spin'
                          variant='outlined'
                          size='small'
                          type='number'
                          inputProps={{
                            min: 0,
                            max: 100,
                            step: '0.01',
                            'aria-label':
                              'Input field for Discussion / Recitation',
                          }}
                          onChange={(e: any) => {
                            const value = parseFloat(e.target.value);
                            setFieldValue('weekly_contact_hours.4', value);
                            if (pathname === '/addcourse' && !state) {
                              localStorage.setItem(
                                'courseFormData',
                                JSON.stringify({
                                  ...values,
                                  weekly_contact_hours: {
                                    ...values.weekly_contact_hours,
                                    4: value,
                                  },
                                })
                              );
                            }
                          }}
                          error={
                            errors.weekly_contact_hours &&
                            touched.weekly_contact_hours
                              ? true
                              : false
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <label>e. Other</label>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          name='weekly_contact_hours.5'
                          as={TextField}
                          className='no-spin'
                          variant='outlined'
                          size='small'
                          type='number'
                          inputProps={{
                            min: 0,
                            max: 100,
                            step: '0.01',
                            'aria-label': 'Input field for Other',
                          }}
                          onChange={(e: any) => {
                            const value = parseFloat(e.target.value);
                            setFieldValue('weekly_contact_hours.5', value);
                            if (pathname === '/addcourse' && !state) {
                              localStorage.setItem(
                                'courseFormData',
                                JSON.stringify({
                                  ...values,
                                  weekly_contact_hours: {
                                    ...values.weekly_contact_hours,
                                    5: value,
                                  },
                                })
                              );
                            }
                          }}
                          error={
                            errors.weekly_contact_hours &&
                            touched.weekly_contact_hours
                              ? true
                              : false
                          }
                        />
                      </Grid>
                    </Grid>
                  </>
                  {/* ) : (
                    ''
                  )} */}

                  <Grid item xs={12} sm={3}>
                    <label className={`${questionStyle} right-aligning-labels`}>
                      Do you plan on having students take the assessment
                    </label>
                    {errors?.student_take_plan && touched?.student_take_plan ? (
                      <p className='text-red-400 mt-1 font-bold text-base italic'>
                        Required Field
                      </p>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid>
                      <label className='cursor-pointer'>
                        <Field
                          as={Checkbox}
                          sx={{ marginLeft: '-10px' }}
                          name='student_take_plan'
                          checked={values?.student_take_plan?.includes('1')}
                          value={'1'}
                          onChange={(e: any) => {
                            const value = e.target.value;
                            const newStudentTakePlan =
                              values?.student_take_plan.includes(value)
                                ? values?.student_take_plan.filter(
                                    (plan: string) => plan !== value
                                  )
                                : [...values?.student_take_plan, value];
                            setFieldValue(
                              'student_take_plan',
                              newStudentTakePlan
                            );
                            if (pathname === '/addcourse' && !state) {
                              localStorage.setItem(
                                'courseFormData',
                                JSON.stringify({
                                  ...values,
                                  student_take_plan: newStudentTakePlan,
                                })
                              );
                            }
                          }}
                        />
                        <span className='ml-1'>At home</span>
                      </label>
                    </Grid>
                    <Grid>
                      <label className='cursor-pointer'>
                        <Field
                          as={Checkbox}
                          sx={{ marginLeft: '-10px' }}
                          name='student_take_plan'
                          checked={values?.student_take_plan?.includes('2')}
                          value={'2'}
                          onChange={(e: any) => {
                            const value = e.target.value;
                            const newStudentTakePlan =
                              values?.student_take_plan.includes(value)
                                ? values?.student_take_plan.filter(
                                    (plan: string) => plan !== value
                                  )
                                : [...values?.student_take_plan, value];
                            setFieldValue(
                              'student_take_plan',
                              newStudentTakePlan
                            );
                            if (pathname === '/addcourse' && !state) {
                              localStorage.setItem(
                                'courseFormData',
                                JSON.stringify({
                                  ...values,
                                  student_take_plan: newStudentTakePlan,
                                })
                              );
                            }
                          }}
                        />
                        <span className='ml-1'>In class</span>
                      </label>
                    </Grid>
                  </Grid>

                  {/* {orgDemographics?.type == 3 ? ( */}
                  <>
                    <Grid item xs={12} sm={3}>
                      <label
                        className={`${questionStyle} right-aligning-labels`}
                      >
                        Does your course use near-peer instructors?
                      </label>
                      {errors?.use_near_peer && touched?.use_near_peer ? (
                        <p className='text-red-400 mt-1 font-bold text-base italic'>
                          Required Field
                        </p>
                      ) : (
                        ''
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid>
                        <label className='cursor-pointer'>
                          <Field
                            as={Checkbox}
                            sx={{ marginLeft: '-10px' }}
                            name='use_near_peer'
                            checked={
                              values?.use_near_peer?.includes('1') &&
                              !values?.use_near_peer?.includes('5')
                            }
                            value={'1'}
                            onChange={(event: any) => {
                              const isChecked = event.target.checked;
                              let updatedValues = values?.use_near_peer || [];

                              if (isChecked) {
                                updatedValues = updatedValues.filter(
                                  (value: any) => value !== '5'
                                );
                                updatedValues.push('1');
                              } else {
                                updatedValues = updatedValues.filter(
                                  (value: any) => value !== '1'
                                );
                              }

                              setFieldValue('use_near_peer', updatedValues);
                              if (pathname === '/addcourse' && !state) {
                                localStorage.setItem(
                                  'courseFormData',
                                  JSON.stringify({
                                    ...values,
                                    use_near_peer: updatedValues,
                                  })
                                );
                              }
                            }}
                          />
                          <span className='ml-1'>Learning assistants</span>
                        </label>
                      </Grid>
                      <Grid>
                        <label className='cursor-pointer'>
                          <Field
                            as={Checkbox}
                            sx={{ marginLeft: '-10px' }}
                            name='use_near_peer'
                            checked={
                              values?.use_near_peer?.includes('2') &&
                              !values?.use_near_peer?.includes('5')
                            }
                            value={'2'}
                            onChange={(event: any) => {
                              const isChecked = event.target.checked;
                              let updatedValues = values?.use_near_peer || [];

                              if (isChecked) {
                                updatedValues = updatedValues.filter(
                                  (value: any) => value !== '5'
                                );
                                updatedValues.push('2');
                              } else {
                                updatedValues = updatedValues.filter(
                                  (value: any) => value !== '2'
                                );
                              }

                              setFieldValue('use_near_peer', updatedValues);
                              if (pathname === '/addcourse' && !state) {
                                localStorage.setItem(
                                  'courseFormData',
                                  JSON.stringify({
                                    ...values,
                                    use_near_peer: updatedValues,
                                  })
                                );
                              }
                            }}
                          />
                          <span className='ml-1'>Supplemental instruction</span>
                        </label>
                      </Grid>
                      <Grid>
                        <label className='cursor-pointer'>
                          <Field
                            as={Checkbox}
                            sx={{ marginLeft: '-10px' }}
                            name='use_near_peer'
                            checked={
                              values?.use_near_peer?.includes('3') &&
                              !values?.use_near_peer?.includes('5')
                            }
                            value={'3'}
                            onChange={(event: any) => {
                              const isChecked = event.target.checked;
                              let updatedValues = values?.use_near_peer || [];

                              if (isChecked) {
                                updatedValues = updatedValues.filter(
                                  (value: any) => value !== '5'
                                );
                                updatedValues.push('3');
                              } else {
                                updatedValues = updatedValues.filter(
                                  (value: any) => value !== '3'
                                );
                              }

                              setFieldValue('use_near_peer', updatedValues);
                              if (pathname === '/addcourse' && !state) {
                                localStorage.setItem(
                                  'courseFormData',
                                  JSON.stringify({
                                    ...values,
                                    use_near_peer: updatedValues,
                                  })
                                );
                              }
                            }}
                          />
                          <span className='ml-1'>Peer led team learning</span>
                        </label>
                      </Grid>
                      <Grid>
                        <label className='cursor-pointer'>
                          <Field
                            as={Checkbox}
                            sx={{ marginLeft: '-10px' }}
                            name='use_near_peer'
                            checked={
                              values?.use_near_peer?.includes('4') &&
                              !values?.use_near_peer?.includes('5')
                            }
                            value={'4'}
                            onChange={(event: any) => {
                              const isChecked = event.target.checked;
                              let updatedValues = values?.use_near_peer || [];

                              if (isChecked) {
                                updatedValues = updatedValues.filter(
                                  (value: any) => value !== '5'
                                );
                                updatedValues.push('4');
                              } else {
                                updatedValues = updatedValues.filter(
                                  (value: any) => value !== '4'
                                );
                              }

                              setFieldValue('use_near_peer', updatedValues);
                              if (pathname === '/addcourse' && !state) {
                                localStorage.setItem(
                                  'courseFormData',
                                  JSON.stringify({
                                    ...values,
                                    use_near_peer: updatedValues,
                                  })
                                );
                              }
                            }}
                          />
                          <span className='ml-1'>Other</span>
                        </label>
                      </Grid>
                      <Grid>
                        <label className='cursor-pointer'>
                          <Field
                            as={Checkbox}
                            sx={{ marginLeft: '-10px' }}
                            name='use_near_peer'
                            checked={values?.use_near_peer?.includes('5')}
                            value={'5'}
                            onChange={(event: any) => {
                              const isChecked = event.target.checked;
                              let updatedValues = values?.use_near_peer || [];

                              if (isChecked) {
                                updatedValues = ['5'];
                              } else {
                                updatedValues = updatedValues.filter(
                                  (value: any) => value !== '5'
                                );
                              }

                              setFieldValue('use_near_peer', updatedValues);
                              if (pathname === '/addcourse' && !state) {
                                localStorage.setItem(
                                  'courseFormData',
                                  JSON.stringify({
                                    ...values,
                                    use_near_peer: updatedValues,
                                  })
                                );
                              }
                            }}
                          />
                          <span className='ml-1'>No</span>
                        </label>
                      </Grid>
                    </Grid>
                    {values?.use_near_peer.length != 0 &&
                      !values?.use_near_peer?.includes('5') && (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            //style={values.use_near_peer == "" || values.use_near_peer == "5"? { display: "none" } : {}}
                          >
                            <label
                              className={`${questionStyle} right-aligning-labels`}
                            >
                              Which best describes your primary use of near-peer
                              instructors?
                            </label>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            //style={values.use_near_peer == "" || values.use_near_peer == "5"? { display: "none" } : {}}
                          >
                            <Stack rowGap={1}>
                              <RadioGroup name='primary_use_of_near_peer'>
                                <div className='flex gap-1 items-center'>
                                  <label>
                                    <Field
                                      as={Radio}
                                      style={{ marginLeft: '-9px' }}
                                      name='primary_use_of_near_peer'
                                      checked={
                                        values?.primary_use_of_near_peer == '1'
                                      }
                                      onChange={(event: any) => {
                                        const value = event.target.value;
                                        setFieldValue(
                                          'primary_use_of_near_peer',
                                          value
                                        );
                                        if (
                                          pathname === '/addcourse' &&
                                          !state
                                        ) {
                                          localStorage.setItem(
                                            'courseFormData',
                                            JSON.stringify({
                                              ...values,
                                              primary_use_of_near_peer: value,
                                            })
                                          );
                                        }
                                      }}
                                      value='1'
                                    />
                                  </label>
                                  <span>
                                    Less than 50% of class/lecture time will be
                                    dedicated facilitating small group
                                    discussions/activities
                                  </span>
                                </div>

                                <div className='flex gap-1 items-center'>
                                  <label>
                                    <Field
                                      as={Radio}
                                      style={{ marginLeft: '-9px' }}
                                      name='primary_use_of_near_peer'
                                      checked={
                                        values?.primary_use_of_near_peer == '2'
                                      }
                                      onChange={(event: any) => {
                                        const value = event.target.value;
                                        setFieldValue(
                                          'primary_use_of_near_peer',
                                          value
                                        );
                                        if (
                                          pathname === '/addcourse' &&
                                          !state
                                        ) {
                                          localStorage.setItem(
                                            'courseFormData',
                                            JSON.stringify({
                                              ...values,
                                              primary_use_of_near_peer: value,
                                            })
                                          );
                                        }
                                      }}
                                      value='2'
                                    />
                                  </label>
                                  <span>
                                    More than 50% of class/lecture time will be
                                    dedicated facilitating small group
                                    discussions/activities
                                  </span>
                                </div>

                                <div className='flex gap-1 items-center'>
                                  <label>
                                    <Field
                                      as={Radio}
                                      style={{ marginLeft: '-9px' }}
                                      name='primary_use_of_near_peer'
                                      checked={
                                        values?.primary_use_of_near_peer == '3'
                                      }
                                      onChange={(event: any) => {
                                        const value = event.target.value;
                                        setFieldValue(
                                          'primary_use_of_near_peer',
                                          value
                                        );
                                        if (
                                          pathname === '/addcourse' &&
                                          !state
                                        ) {
                                          localStorage.setItem(
                                            'courseFormData',
                                            JSON.stringify({
                                              ...values,
                                              primary_use_of_near_peer: value,
                                            })
                                          );
                                        }
                                      }}
                                      value='3'
                                    />
                                    <span>
                                      Facilitate mandatory group sessions
                                      outside of lecture
                                    </span>
                                  </label>
                                </div>

                                <div className='flex gap-1 items-center'>
                                  {/* <label>
                          <Field
                            as={Radio}
                            sx={{ paddingLeft: '0' }}
                            name="primary_use_of_near_peer"
                            checked={values.primary_use_of_near_peer == '4'}
                            value="4"
                          />
                        </label> */}
                                  <span
                                    style={{
                                      paddingLeft: '36px',
                                    }}
                                  >
                                    <p>
                                      An example of a mandatory group sessions
                                      would be a weekly recitation session that
                                      meets outside of lecture where attendance
                                      is required.
                                    </p>
                                  </span>
                                </div>

                                <div className='flex gap-1 items-center'>
                                  <label>
                                    <Field
                                      as={Radio}
                                      style={{ marginLeft: '-9px' }}
                                      name='primary_use_of_near_peer'
                                      checked={
                                        values?.primary_use_of_near_peer == '4'
                                      }
                                      onChange={(event: any) => {
                                        const value = event.target.value;
                                        setFieldValue(
                                          'primary_use_of_near_peer',
                                          value
                                        );
                                        if (
                                          pathname === '/addcourse' &&
                                          !state
                                        ) {
                                          localStorage.setItem(
                                            'courseFormData',
                                            JSON.stringify({
                                              ...values,
                                              primary_use_of_near_peer: value,
                                            })
                                          );
                                        }
                                      }}
                                      value='4'
                                    />
                                  </label>
                                  <span>
                                    Facilitate optional group sessions outside
                                    of lecture
                                  </span>
                                </div>

                                <div className='flex gap-1 items-center'>
                                  {/* <label>
                          <Field
                            as={Radio}
                            sx={{ paddingLeft: '0' }}
                            name="primary_use_of_near_peer"
                            checked={values.primary_use_of_near_peer == '6'}
                            value="6"
                          />
                        </label> */}
                                  <span
                                    style={{
                                      paddingLeft: '36px',
                                    }}
                                  >
                                    <p>
                                      An example of an optional group session
                                      would be a weekly study section that meets
                                      outside of lecture where attendance is not
                                      required.
                                    </p>
                                  </span>
                                </div>

                                <div className='flex gap-1 items-center'>
                                  <label>
                                    <Field
                                      as={Radio}
                                      style={{ marginLeft: '-9px' }}
                                      name='primary_use_of_near_peer'
                                      checked={
                                        values?.primary_use_of_near_peer == '5'
                                      }
                                      onChange={(event: any) => {
                                        const value = event.target.value;
                                        setFieldValue(
                                          'primary_use_of_near_peer',
                                          value
                                        );
                                        if (
                                          pathname === '/addcourse' &&
                                          !state
                                        ) {
                                          localStorage.setItem(
                                            'courseFormData',
                                            JSON.stringify({
                                              ...values,
                                              primary_use_of_near_peer: value,
                                            })
                                          );
                                        }
                                      }}
                                      value='5'
                                    />
                                  </label>{' '}
                                  <span>Facilitate laboratory sessions</span>
                                </div>

                                <div className='flex gap-1 items-center'>
                                  <label>
                                    <Field
                                      as={Radio}
                                      style={{ marginLeft: '-9px' }}
                                      name='primary_use_of_near_peer'
                                      checked={
                                        values?.primary_use_of_near_peer === '6'
                                      }
                                      value='6'
                                      onChange={(event: any) => {
                                        const value = event.target.value;
                                        setFieldValue(
                                          'primary_use_of_near_peer',
                                          value
                                        );
                                        if (
                                          pathname === '/addcourse' &&
                                          !state
                                        ) {
                                          localStorage.setItem(
                                            'courseFormData',
                                            JSON.stringify({
                                              ...values,
                                              primary_use_of_near_peer: value,
                                            })
                                          );
                                        }
                                      }}
                                    />
                                  </label>
                                  <span>Other</span>
                                  <Field
                                    name='primary_use_of_near_peer_other'
                                    as={TextField}
                                    {...(values?.primary_use_of_near_peer !==
                                    '6'
                                      ? { value: '' }
                                      : {})}
                                    disabled={
                                      values?.primary_use_of_near_peer !== '6'
                                    }
                                    style={{ marginLeft: '20px' }}
                                    placeholder='Other description'
                                    size='small'
                                    onChange={(event: any) => {
                                      const value = event.target.value;
                                      setFieldValue(
                                        'primary_use_of_near_peer_other',
                                        value
                                      );
                                      if (pathname === '/addcourse' && !state) {
                                        localStorage.setItem(
                                          'courseFormData',
                                          JSON.stringify({
                                            ...values,
                                            primary_use_of_near_peer_other:
                                              value,
                                          })
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </RadioGroup>
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            //style={values.use_near_peer == "" || values.use_near_peer == "5"? { display: "none" } : {}}
                          >
                            <label
                              className={`${questionStyle} right-aligning-labels`}
                            >
                              Which best describes your secondary use of
                              near-peer instructors?
                            </label>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            //style={values.use_near_peer == "" || values.use_near_peer == "5"? { display: "none" } : {}}
                          >
                            <Stack rowGap={1}>
                              <RadioGroup name='secondary_use_of_near_peer'>
                                <div className='flex gap-1 items-center'>
                                  <label>
                                    <Field
                                      as={Radio}
                                      style={{ marginLeft: '-9px' }}
                                      name='secondary_use_of_near_peer'
                                      checked={
                                        values?.secondary_use_of_near_peer ==
                                        '1'
                                      }
                                      onChange={(event: any) => {
                                        const value = event.target.value;
                                        setFieldValue(
                                          'secondary_use_of_near_peer',
                                          value
                                        );
                                        if (
                                          pathname === '/addcourse' &&
                                          !state
                                        ) {
                                          localStorage.setItem(
                                            'courseFormData',
                                            JSON.stringify({
                                              ...values,
                                              secondary_use_of_near_peer: value,
                                            })
                                          );
                                        }
                                      }}
                                      value='1'
                                    />
                                  </label>
                                  <span>
                                    Less than 50% of class/lecture time will be
                                    dedicated facilitating small group
                                    discussions/activities
                                  </span>
                                </div>

                                <div className='flex gap-1 items-center'>
                                  <label>
                                    <Field
                                      as={Radio}
                                      style={{ marginLeft: '-9px' }}
                                      name='secondary_use_of_near_peer'
                                      checked={
                                        values?.secondary_use_of_near_peer ==
                                        '2'
                                      }
                                      onChange={(event: any) => {
                                        const value = event.target.value;
                                        setFieldValue(
                                          'secondary_use_of_near_peer',
                                          value
                                        );
                                        if (
                                          pathname === '/addcourse' &&
                                          !state
                                        ) {
                                          localStorage.setItem(
                                            'courseFormData',
                                            JSON.stringify({
                                              ...values,
                                              secondary_use_of_near_peer: value,
                                            })
                                          );
                                        }
                                      }}
                                      value='2'
                                    />
                                  </label>
                                  <span>
                                    More than 50% of class/lecture time will be
                                    dedicated facilitating small group
                                    discussions/activities
                                  </span>
                                </div>

                                <div className='flex gap-1 items-center'>
                                  <label>
                                    <Field
                                      as={Radio}
                                      style={{ marginLeft: '-9px' }}
                                      name='secondary_use_of_near_peer'
                                      checked={
                                        values?.secondary_use_of_near_peer ==
                                        '3'
                                      }
                                      onChange={(event: any) => {
                                        const value = event.target.value;
                                        setFieldValue(
                                          'secondary_use_of_near_peer',
                                          value
                                        );
                                        if (
                                          pathname === '/addcourse' &&
                                          !state
                                        ) {
                                          localStorage.setItem(
                                            'courseFormData',
                                            JSON.stringify({
                                              ...values,
                                              secondary_use_of_near_peer: value,
                                            })
                                          );
                                        }
                                      }}
                                      value='3'
                                    />
                                  </label>
                                  <span>
                                    Facilitate mandatory group sessions outside
                                    of lecture
                                  </span>
                                </div>

                                <div className='flex gap-1 items-center'>
                                  {/* <label>
                          <Field
                            as={Radio}
                            sx={{ paddingLeft: '0' }}
                            name="secondary_use_of_near_peer"
                            checked={values.secondary_use_of_near_peer == '4'}
                            value="4"
                          />
                        </label> */}
                                  <span
                                    style={{
                                      paddingLeft: '36px',
                                    }}
                                  >
                                    <p>
                                      An example of a mandatory group sessions
                                      would be a weekly recitation session that
                                      meets outside of lecture where attendance
                                      is required.
                                    </p>
                                  </span>
                                </div>

                                <div className='flex gap-1 items-center'>
                                  <label>
                                    <Field
                                      as={Radio}
                                      style={{ marginLeft: '-9px' }}
                                      name='secondary_use_of_near_peer'
                                      checked={
                                        values?.secondary_use_of_near_peer ==
                                        '4'
                                      }
                                      onChange={(event: any) => {
                                        const value = event.target.value;
                                        setFieldValue(
                                          'secondary_use_of_near_peer',
                                          value
                                        );
                                        if (
                                          pathname === '/addcourse' &&
                                          !state
                                        ) {
                                          localStorage.setItem(
                                            'courseFormData',
                                            JSON.stringify({
                                              ...values,
                                              secondary_use_of_near_peer: value,
                                            })
                                          );
                                        }
                                      }}
                                      value='4'
                                    />
                                  </label>
                                  <span>
                                    Facilitate optional group sessions outside
                                    of lecture
                                  </span>
                                </div>

                                <div className='flex gap-1 items-center'>
                                  {/* <label>
                          <Field
                            as={Radio}
                            sx={{ paddingLeft: '0' }}
                            name="secondary_use_of_near_peer"
                            checked={values.secondary_use_of_near_peer == '6'}
                            value="6"
                          />
                        </label> */}
                                  <span
                                    style={{
                                      paddingLeft: '36px',
                                    }}
                                  >
                                    <p>
                                      An example of an optional group session
                                      would be a weekly study section that meets
                                      outside of lecture where attendance is not
                                      required.
                                    </p>
                                  </span>
                                </div>

                                <div className='flex gap-1 items-center'>
                                  <label>
                                    <Field
                                      as={Radio}
                                      style={{ marginLeft: '-9px' }}
                                      name='secondary_use_of_near_peer'
                                      checked={
                                        values?.secondary_use_of_near_peer ==
                                        '5'
                                      }
                                      onChange={(event: any) => {
                                        const value = event.target.value;
                                        setFieldValue(
                                          'secondary_use_of_near_peer',
                                          value
                                        );
                                        if (
                                          pathname === '/addcourse' &&
                                          !state
                                        ) {
                                          localStorage.setItem(
                                            'courseFormData',
                                            JSON.stringify({
                                              ...values,
                                              secondary_use_of_near_peer: value,
                                            })
                                          );
                                        }
                                      }}
                                      value='5'
                                    />
                                  </label>
                                  <span>Facilitate laboratory sessions</span>
                                </div>

                                <div className='flex gap-1 items-center'>
                                  <label>
                                    <Field
                                      as={Radio}
                                      style={{ marginLeft: '-9px' }}
                                      name='secondary_use_of_near_peer'
                                      checked={
                                        values?.secondary_use_of_near_peer ===
                                        '6'
                                      }
                                      value='6'
                                      onChange={(event: any) => {
                                        const value = event.target.value;
                                        setFieldValue(
                                          'secondary_use_of_near_peer',
                                          value
                                        );
                                        if (
                                          pathname === '/addcourse' &&
                                          !state
                                        ) {
                                          localStorage.setItem(
                                            'courseFormData',
                                            JSON.stringify({
                                              ...values,
                                              secondary_use_of_near_peer: value,
                                            })
                                          );
                                        }
                                      }}
                                    />
                                  </label>
                                  <span>Other</span>
                                  <Field
                                    name='secondary_use_of_near_peer_other'
                                    as={TextField}
                                    style={{ marginLeft: '20px' }}
                                    {...(values?.secondary_use_of_near_peer !==
                                    '6'
                                      ? { value: '' }
                                      : {})}
                                    disabled={
                                      values?.secondary_use_of_near_peer !== '6'
                                    }
                                    placeholder='Other description'
                                    size='small'
                                    onChange={(event: any) => {
                                      const value = event.target.value;
                                      setFieldValue(
                                        'secondary_use_of_near_peer_other',
                                        value
                                      );
                                      if (pathname === '/addcourse' && !state) {
                                        localStorage.setItem(
                                          'courseFormData',
                                          JSON.stringify({
                                            ...values,
                                            secondary_use_of_near_peer_other:
                                              value,
                                          })
                                        );
                                      }
                                    }}
                                  />
                                </div>

                                <label>
                                  <Field
                                    as={Radio}
                                    style={{ marginLeft: '-9px' }}
                                    name='secondary_use_of_near_peer'
                                    checked={
                                      values?.secondary_use_of_near_peer == '7'
                                    }
                                    onChange={(event: any) => {
                                      const value = event.target.value;
                                      setFieldValue(
                                        'secondary_use_of_near_peer',
                                        value
                                      );
                                      if (pathname === '/addcourse' && !state) {
                                        localStorage.setItem(
                                          'courseFormData',
                                          JSON.stringify({
                                            ...values,
                                            secondary_use_of_near_peer: value,
                                          })
                                        );
                                      }
                                    }}
                                    value='7'
                                  />
                                  <span>None</span>
                                </label>
                              </RadioGroup>
                            </Stack>
                          </Grid>
                        </>
                      )}
                  </>
                  {/* ) : (
                    ''
                  )} */}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion className='border border-[#CBD5E1] rounded-md  p-3 pl-2 mb-5'>
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls='panel2-content'
                id='panel2-header'
              >
                {typeof values?.is_credit === 'boolean' &&
                typeof values?.email_reminder === 'boolean' &&
                typeof values?.class_reminder === 'boolean' ? (
                  <CheckCircle fontSize='large' style={{ color: '#0E660A' }} />
                ) : (
                  <ErrorIcon fontSize='large' style={{ color: '#F9765C' }} />
                )}
                <Typography
                  component='h2'
                  variant='h6'
                  sx={{ marginLeft: '12px' }}
                >
                  {' '}
                  Participation Practices (Required){' '}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {' '}
                <header className='text-[#485A63]'>
                  <Typography component='h2' variant='h6'>
                    Please indicate if you plan on using the following methods
                    to improve participation.
                  </Typography>
                </header>
                <Grid
                  container
                  rowGap={2}
                  columnGap={10}
                  className='p-3 pl-6 items-center'
                >
                  <Grid item xs={12} sm={3}>
                    <label className={`${questionStyle} right-aligning-labels`}>
                      Will credit be offered to students who participate?
                    </label>
                  </Grid>
                  <Grid item container xs={12} sm={6}>
                    <RadioGroup
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                      }}
                      name='is_credit'
                      aria-label='Credit'
                    >
                      <Item>
                        <Field
                          as={Radio}
                          sx={{ marginLeft: '-10px' }}
                          name='is_credit'
                          onChange={(e: any) => {
                            setFieldValue('is_credit', true);
                            if (pathname === '/addcourse' && !state) {
                              localStorage.setItem(
                                'courseFormData',
                                JSON.stringify({
                                  ...values,
                                  is_credit: true,
                                })
                              );
                            }
                          }}
                          checked={Boolean(values?.is_credit)}
                          required
                        />
                        <label
                          className={questionStyle}
                          style={{ marginRight: '10px' }}
                        >
                          Yes
                        </label>
                      </Item>
                      <Item>
                        <Field
                          as={Radio}
                          sx={{ marginLeft: '-10px' }}
                          name='is_credit'
                          onChange={(e: any) => {
                            setFieldValue('is_credit', false);
                            if (pathname === '/addcourse' && !state) {
                              localStorage.setItem(
                                'courseFormData',
                                JSON.stringify({
                                  ...values,
                                  is_credit: false,
                                })
                              );
                            }
                          }}
                          checked={values?.is_credit === false}
                          required
                        />
                        <label
                          className={questionStyle}
                          style={{ marginRight: '10px' }}
                        >
                          No
                        </label>
                      </Item>
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <label className={`${questionStyle} right-aligning-labels`}>
                      Will email reminders be used to motivate students to
                      participate?
                    </label>
                  </Grid>
                  <Grid item container xs={12} sm={6}>
                    <RadioGroup
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                      }}
                      name='email_reminder'
                    >
                      <Item>
                        <Field
                          as={Radio}
                          sx={{ marginLeft: '-10px' }}
                          name='email_reminder'
                          onChange={(e: any) => {
                            setFieldValue('email_reminder', true);
                            if (pathname === '/addcourse' && !state) {
                              localStorage.setItem(
                                'courseFormData',
                                JSON.stringify({
                                  ...values,
                                  email_reminder: true,
                                })
                              );
                            }
                          }}
                          checked={Boolean(values?.email_reminder)}
                          required
                        />
                        <label
                          className={questionStyle}
                          style={{ marginRight: '10px' }}
                        >
                          Yes
                        </label>
                      </Item>
                      <Item>
                        <Field
                          as={Radio}
                          sx={{ marginLeft: '-10px' }}
                          name='email_reminder'
                          onChange={(e: any) => {
                            setFieldValue('email_reminder', false);
                            if (pathname === '/addcourse' && !state) {
                              localStorage.setItem(
                                'courseFormData',
                                JSON.stringify({
                                  ...values,
                                  email_reminder: false,
                                })
                              );
                            }
                          }}
                          checked={values?.email_reminder === false}
                          required
                        />
                        <label
                          className={questionStyle}
                          style={{ marginRight: '10px' }}
                        >
                          No
                        </label>
                      </Item>
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <label className={`${questionStyle} right-aligning-labels`}>
                      Will in-class reminders be used to motivate students to
                      participate?
                    </label>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={6}
                    style={{
                      display: 'flex',
                      gap: '10px',
                    }}
                  >
                    <RadioGroup
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                      }}
                      name='class_reminder'
                    >
                      <Item>
                        <Field
                          as={Radio}
                          sx={{ marginLeft: '-10px' }}
                          name='class_reminder'
                          onChange={(e: any) => {
                            setFieldValue('class_reminder', true);
                            if (pathname === '/addcourse' && !state) {
                              localStorage.setItem(
                                'courseFormData',
                                JSON.stringify({
                                  ...values,
                                  class_reminder: true,
                                })
                              );
                            }
                          }}
                          checked={Boolean(values?.class_reminder)}
                          required
                        />
                        <label
                          className={questionStyle}
                          style={{ marginRight: '10px' }}
                        >
                          Yes
                        </label>
                      </Item>
                      <Item>
                        <Field
                          as={Radio}
                          sx={{ marginLeft: '-10px' }}
                          name='class_reminder'
                          onChange={(e: any) => {
                            setFieldValue('class_reminder', false);
                            if (pathname === '/addcourse' && !state) {
                              localStorage.setItem(
                                'courseFormData',
                                JSON.stringify({
                                  ...values,
                                  class_reminder: false,
                                })
                              );
                            }
                          }}
                          checked={values?.class_reminder === false}
                          required
                        />
                        <label
                          className={questionStyle}
                          style={{ marginRight: '10px' }}
                        >
                          No
                        </label>
                      </Item>
                    </RadioGroup>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion className='border border-[#CBD5E1] rounded-md p-3 pl-2 mb-5'>
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls='panel1-content'
                id='panel1-header'
              >
                {['14', '24', '25', '27'].every(
                  (key) => values?.likert_questions?.[key]
                ) ? (
                  <CheckCircle fontSize='large' style={{ color: '#0E660A' }} />
                ) : (
                  <ErrorIcon fontSize='large' style={{ color: '#F9765C' }} />
                )}
                <Typography
                  component='h2'
                  variant='h6'
                  sx={{ marginLeft: '12px' }}
                >
                  Teaching Practices (Required)
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                {/* <div
                 className='bg-slate-100 flex flex-col gap-y-2'
                > */}

                {requiredQuestions.map((no, index) => {
                  const question = likertScaleQuestions.find(
                    (q) => q.no === no
                  );
                  if (!question) return null;
                  return (
                    <div
                      className={` flex flex-row items-center mx-8 p-2 gap-x-4 mt-3 ${
                        index % 2 === 0 ? 'bg-[#f3f3f3]' : ''
                      }`}
                      key={question.no}
                    >
                      <label
                        className={`${questionStyle}  basis-1/2 grow-0 right-aligning-labels`}
                      >
                        {question.question}
                      </label>

                      {likertScaleAnswers.map((ans) => (
                        <div
                          key={ans.val}
                          className=' min-w-fit grow flex justify-center items-center text-sm'
                        >
                          <label className='cursor-pointer'>
                            <Field
                              as={Radio}
                              name={`likert_questions.${question.no}`}
                              checked={
                                String(
                                  values?.likert_questions[question.no]
                                ) === String(ans.val)
                              }
                              value={ans.val}
                              onChange={(event: any) => {
                                const newValues = {
                                  ...values,
                                  likert_questions: {
                                    ...values.likert_questions,
                                    [question.no]: event.target.value,
                                  },
                                };
                                setFieldValue(
                                  `likert_questions.${question.no}`,
                                  event.target.value
                                );
                                if (pathname === '/addcourse' && !state) {
                                  localStorage.setItem(
                                    'courseFormData',
                                    JSON.stringify(newValues)
                                  );
                                }
                              }}
                              required
                            />
                            <span>{ans.label}</span>
                          </label>
                        </div>
                      ))}
                    </div>
                  );
                })}

                {/* </div> */}
              </AccordionDetails>
            </Accordion>
            <Accordion className='border-2 border-[#4638da] rounded-md p-3 pl-2 mb-5'>
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls='panel1-content'
                id='panel1-header'
              >
                {[
                  '15',
                  '16',
                  '17',
                  '18',
                  '19',
                  '20',
                  '21',
                  '22',
                  '23',
                  '26',
                  '28',
                  '29',
                  '30',
                  '31',
                  '32',
                  '33',
                  '34',
                  '35',
                  '36',
                  '37',
                ].every((key) => values?.likert_questions?.[key]) ? (
                  <CheckCircle fontSize='large' style={{ color: '#0E660A' }} />
                ) : (
                  <ErrorIcon fontSize='large' style={{ color: '#F9765C' }} />
                )}
                <Typography
                  component='h2'
                  variant='h6'
                  sx={{ marginLeft: '12px' }}
                >
                  Teaching Practices (Optional)
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                {optionalQuestions.map((question, index) => (
                  <div
                    className={` flex flex-row items-center mx-8 p-2 gap-x-4 mt-3 ${
                      index % 2 === 0 ? 'bg-[#f3f3f3]' : ''
                    }`}
                    key={question.no}
                  >
                    <label
                      className={`${questionStyle}  basis-1/2 grow-0 right-aligning-labels`}
                    >
                      {question.question}
                    </label>

                    {likertScaleAnswers.map((ans) => (
                      <div
                        key={ans.val}
                        className=' min-w-fit grow flex justify-center items-center text-sm'
                      >
                        <label className='cursor-pointer'>
                          <Field
                            as={Radio}
                            name={`likert_questions.${question.no}`}
                            checked={
                              String(values?.likert_questions[question.no]) ===
                              String(ans.val)
                            }
                            value={ans.val}
                            onChange={(event: any) => {
                              const newValues = {
                                ...values,
                                likert_questions: {
                                  ...values.likert_questions,
                                  [question.no]: event.target.value,
                                },
                              };
                              setFieldValue(
                                `likert_questions.${question.no}`,
                                event.target.value
                              );
                              if (pathname === '/addcourse' && !state) {
                                localStorage.setItem(
                                  'courseFormData',
                                  JSON.stringify(newValues)
                                );
                              }
                            }}
                          />
                          <span>{ans.label}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
            <Accordion className='border border-[#CBD5E1] rounded-md  p-3 pl-2 '>
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls='panel2-content'
                id='panel2-header'
              >
                {values?.description ? (
                  <CheckCircle fontSize='large' style={{ color: '#0E660A' }} />
                ) : (
                  <ErrorIcon fontSize='large' style={{ color: '#F9765C' }} />
                )}
                <Typography
                  component='h2'
                  variant='h6'
                  sx={{ marginLeft: '12px' }}
                >
                  {' '}
                  Consent to Share Data (Required){' '}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container rowGap={2} columnGap={19} className='p-3 pl-6'>
                  <Grid item xs={12} sm={3}>
                    <label className={`${questionStyle} right-aligning-labels`}>
                      If you think we need more information about your class,
                      please explain
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Field
                      as={TextField}
                      name='description'
                      multiline
                      rows={3}
                      fullWidth
                      className='border border-neutral-400 rounded-sm'
                      value={values?.description}
                      error={errors.description && touched.description}
                      helpertext={
                        errors.description && touched.description
                          ? errors.description
                          : ''
                      }
                      onChange={(e: any) => {
                        handleChange(e);
                        setFieldValue('description', e.target.value, true); // force validation
                        if (pathname === '/addcourse' && !state) {
                          localStorage.setItem(
                            'courseFormData',
                            JSON.stringify({
                              ...values,
                              description: e.target.value,
                            })
                          );
                        }
                      }}
                      required
                    />
                  </Grid>
                </Grid>

                <Box className='border border-[#CBD5E1] rounded-md mt-5 p-3 pl-2'>
                  <Grid container alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <label
                        className={`${questionStyle} right-aligning-labels`}
                      >
                        Would you like to include a specific message in the
                        emails from LASSO to your students?
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className='ml-8'>
                        <Field
                          as={Radio}
                          name='student_specific_message'
                          size='small'
                          checked={values.student_specific_message}
                          onChange={(e: any) => {
                            setFieldValue('student_specific_message', true);
                            setOpenTerm(false);
                            if (pathname === '/addcourse' && !state) {
                              localStorage.setItem(
                                'courseFormData',
                                JSON.stringify({
                                  ...values,
                                  student_specific_message: true,
                                })
                              );
                            }
                          }}
                        />{' '}
                        <span>Yes</span>
                        <Field
                          as={Radio}
                          name='student_specific_message'
                          size='small'
                          checked={!values.student_specific_message}
                          onChange={(e: any) => {
                            setFieldValue('student_specific_message', false);
                            setOpenTerm(false);
                            setFieldValue('student_message_box', '');
                            if (pathname === '/addcourse' && !state) {
                              localStorage.setItem(
                                'courseFormData',
                                JSON.stringify({
                                  ...values,
                                  student_specific_message: false,
                                  student_message_box: '',
                                })
                              );
                            }
                          }}
                        />{' '}
                        <span>No</span>
                      </div>
                    </Grid>
                    {values.student_specific_message && (
                      <Grid item xs={12} sm={12}>
                        <Field
                          as={TextField}
                          name='student_message_box'
                          multiline
                          rows={3}
                          fullWidth
                          className='border border-neutral-400 rounded-sm'
                          value={values?.student_message_box}
                          onChange={(e: any) => {
                            setFieldValue(
                              'student_message_box',
                              e.target.value
                            );
                            if (pathname === '/addcourse' && !state) {
                              localStorage.setItem(
                                'courseFormData',
                                JSON.stringify({
                                  ...values,
                                  student_message_box: e.target.value,
                                })
                              );
                            }
                          }}
                          error={
                            errors.student_message_box &&
                            touched.student_message_box
                          }
                          helperText={
                            errors.student_message_box &&
                            touched.student_message_box
                              ? errors.student_message_box
                              : ''
                          }
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>

                <Stack spacing={2} className='ml-3 mt-4'>
                  <div id='Your-Consent' className='pb-3'>
                    <h4 className='pb-2 text-[#026DC8] font-normal text-lg mt-4'>
                      Your Consent
                    </h4>
                    <p className='pl-6'>
                      Please click below to indicate whether you are willing to
                      allow your course’s data to be used for research purposes
                      under our
                      <span
                        className='text-blue-600 ml-1 underline cursor-pointer'
                        onClick={() => {
                          setOpenTerm(true);
                        }}
                      >
                        terms
                      </span>{' '}
                      for data sharing.
                    </p>

                    <div className='pt-4 ml-8'>
                      <Field
                        as={Radio}
                        name='is_agree'
                        size='small'
                        checked={values.is_agree === true}
                        onChange={(e: any) => {
                          setFieldValue('is_agree', true);
                          setOpenTerm(false);
                          if (pathname === '/addcourse' && !state) {
                            localStorage.setItem(
                              'courseFormData',
                              JSON.stringify({
                                ...values,
                                is_agree: true,
                              })
                            );
                          }
                        }}
                      />{' '}
                      <span>
                        YES – I give permission for my course’s data to be
                        retained indefinitely and used for research purposes.
                      </span>
                    </div>

                    <div className='pt-2 ml-8'>
                      <Field
                        as={Radio}
                        name='is_agree'
                        size='small'
                        checked={values.is_agree === false}
                        onChange={(e: any) => {
                          setFieldValue('is_agree', false);
                          setOpenTerm(false);
                          if (pathname === '/addcourse' && !state) {
                            localStorage.setItem(
                              'courseFormData',
                              JSON.stringify({
                                ...values,
                                is_agree: false,
                              })
                            );
                          }
                        }}
                      />{' '}
                      <span>
                        NO – I do not give permission for research use of my
                        course’s data.
                      </span>
                    </div>
                  </div>

                  <Dialog open={openTerm} maxWidth={'xl'} scroll={'paper'}>
                    <DialogTitle className='bg-[#1663ab] text-white'>
                      <div className='flex justify-between items-center'>
                        <p>Informed Consent Form</p>

                        <Fab size='small'>
                          <CloseIcon
                            className='cursor-pointer'
                            onClick={() => setOpenTerm(false)}
                          />
                        </Fab>
                      </div>
                    </DialogTitle>
                    <DialogContent>
                      <div id='introduction'>
                        <h4 className='text-[#026DC8] font-normal text-lg mt-4'>
                          Introduction
                        </h4>
                        <p className='p-2 text-sm text-justify'>
                          The assessment you select will be administered through
                          an online platform called Learning About STEM Student
                          Outcomes (LASSO). LASSO is an online educational tool
                          to aid instructors and their institutions in tracking
                          and evaluating student progress. Instructors and
                          students from all over the United States use the LASSO
                          platform in their courses. Researchers may be
                          interested in using the stored assessment responses
                          and course information for research projects on issues
                          related to teaching and learning.
                          <br />
                          <br />
                          Therefore, Dr. Ben Van Dusen, Assistant Professor at
                          Iowa State University’s School of Education, is asking
                          for your permission to include your course’s
                          assessment responses and course information in a large
                          dataset of responses/information from other courses
                          across the country that can be used for research
                          purposes. Please carefully read through the
                          information in this document before deciding whether
                          you want to participate.
                        </p>
                        <div className='p-2 text-sm text-justify'>
                          Participating in this research involves two steps:
                          <p className='p-2'>
                            Step 1: Give permission to have your course data
                            used for research purposes. Course data includes the
                            information you provided about your course (e.g.,
                            delivery format, number of hours, how you structure
                            your class, etc. but does not include any
                            identifiable information for the instructor, course,
                            or your institution except in rare instances as
                            described below.
                          </p>
                          <p className='p-2'>
                            Step 2: Each student in the course can consent (or
                            not) to have their responses to the assessment used
                            for research purposes. The student consent process
                            is managed by Dr. Van Dusen and his team. You will
                            not need to obtain students' consent; nor will you
                            be informed of the students' choice.
                          </p>
                        </div>

                        <div className='p-2 text-sm text-justify'>
                          If you give permission:
                          <ul className='p-2 ml-5 list-disc'>
                            <li>
                              The data (your responses about the course) will be
                              kept indefinitely and used for a wide variety of
                              studies related to teaching and learning.
                            </li>
                            <br></br>
                            <li>
                              Some studies will be conducted by researchers at
                              Iowa State. In other cases, the data will be
                              shared with researchers at other institutions.
                              Researchers must get first permission from Dr. Van
                              Dusen (or his designee) and agree to use the data
                              only for research and protect your
                              confidentiality.
                            </li>{' '}
                            <br></br>
                            <li>
                              Any data used for research will be
                              de-identified—that is, you and your students’
                              names, email addresses, college/school, and course
                              name and number will be removed before the data
                              are shared with researchers. <br></br>
                              <ul className='p-2 ml-5 list-[circle] text-justify'>
                                <li>
                                  In rare instances, a researcher may request
                                  access to identifiable information. For
                                  example, they may want to invite students with
                                  certain assessment scores to participate in
                                  future research, or conduct research on a
                                  specific course. Identifiable information will
                                  be made available only if there is no other
                                  feasible way to carry out the research and the
                                  research has been approved by an institutional
                                  review board (IRB).
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div id='Benefits-and-Risks'>
                        <h4 className='text-[#026DC8] font-normal text-lg mt-4'>
                          Benefits and Risks
                        </h4>
                        <p className='p-2 text-sm text-justify'>
                          There are no benefits to your course from allowing
                          research use of your information. However, future
                          courses, instructors, and students may benefit from
                          the knowledge gained through research studies using
                          the data.
                          <br></br>
                          <br></br>
                          Dr. Van Dusen’s team will do their best to protect the
                          data during storage and when they are shared. However,
                          it is possible that confidential information could be
                          accidentally revealed. To minimize this risk, several
                          confidentiality protections will be employed; these
                          are described below.
                        </p>
                      </div>

                      <div id='Your-Rights'>
                        <h4 className='text-[#026DC8] font-normal text-lg mt-4'>
                          Your Rights
                        </h4>
                        <p className='p-2 text-sm text-justify'>
                          <b>
                            Allowing research use of your course information is
                            completely voluntary.
                          </b>{' '}
                          You can agree or decline – it is completely up to you.
                          Your choice will have no impact on your use of LASSO.
                          There are no penalties or negative consequences should
                          you decline.
                          <br /> <br />
                          You can also agree now but change your mind – you can
                          email{' '}
                          <a
                            className=' text-link'
                            href='mailto:contact@lasso.org'
                          >
                            contact@lasso.org
                          </a>{' '}
                          if you no longer wish to allow your course’s
                          information to be used for research purposes. If you
                          change your mind, your information will be excluded
                          from any future datasets that are shared. However,
                          information that has already been shared for research
                          use will continue to be used.
                        </p>
                      </div>

                      <div id='Confidentiality-Protections'>
                        <h4 className='text-[#026DC8] font-normal text-lg mt-4'>
                          Confidentiality Protections
                        </h4>
                        <p className='p-2 text-sm text-justify'>
                          To protect your confidentiality, the research data
                          will be stored in secure, university-supported
                          systems, only authorized people have access to
                          information that could directly identify you, and data
                          files will be shared using secure methods.
                          <br />
                          <br />
                          Recipient researchers must sign agreements promising
                          to keep the data confidential. Researchers who receive
                          deidentified data must also promise not to attempt to
                          reidentify you. Researchers who receive identifiable
                          data must have an institutional review board
                          (IRB)-approved research protocol.
                          <br />
                          <br />
                          You should know, however, that it is possible that
                          other people and offices responsible for making sure
                          research is done safely and responsibly will see your
                          information. Federal government regulatory agencies,
                          auditing departments of Iowa State University, and the
                          Institutional Review Board (a committee that reviews
                          and approves human subject research studies) may
                          inspect and/or copy study records to fulfill their
                          oversight responsibilities.
                          <br /> <br />
                          Results based on the LASSO data will be shared with
                          the scientific community, typically through
                          publication in journal articles or books or academic
                          presentations. These results will not include any
                          information that could identify you.
                        </p>
                      </div>

                      <div id='Questions'>
                        <h4 className='text-[#026DC8] font-normal text-lg mt-4'>
                          Questions
                        </h4>
                        <p className='p-2 text-sm text-justify'>
                          For questions about the LASSO research project, you
                          may contact Dr. Ben Van Dusen via email at
                          <a
                            className=' text-link'
                            href='mailto:contact@lassoeducation.org'
                          >
                            {' '}
                            contact@lassoeducation.org
                          </a>
                          , or by phone at 515-294-0317.
                          <br /> <br />
                          If you have any questions about the rights of research
                          subjects or research-related injury, please contact
                          the IRB Administrator, (515) 294-4566,{' '}
                          <a
                            className=' text-link'
                            href='mailto:IRB@iastate.edu'
                          >
                            {' '}
                            IRB@iastate.edu{' '}
                          </a>
                          , or Director, (515) 294-3115, Office of Research
                          Ethics, Iowa State University, Ames, Iowa 50011.
                        </p>
                      </div>
                    </DialogContent>
                  </Dialog>
                </Stack>
              </AccordionDetails>
            </Accordion>
            <div className='flex gap-5 justify-center my-5'>
              <Button
                variant='contained'
                style={{ textTransform: 'capitalize', width: '100px' }}
                onClick={() => navigateToCourse()}
              >
                Cancel
              </Button>

              <Button
                variant='contained'
                style={{ textTransform: 'capitalize', width: '100px' }}
                type='submit'
                // fullWidth
                disabled={button}
              >
                {pathname == '/addcourse' ? 'Save' : 'Update'}
              </Button>
            </div>
            {/* <br></br>
            <br></br>
            values
            <pre>{JSON.stringify(values, null, 2)}</pre>
            errors
            <pre>{JSON.stringify(errors, null, 2)}</pre>
            touched
            <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          </Form>
        )}
      </Formik>
      <AddCoursePrefix
        openDialog={openForm}
        setOpenForm={setOpenForm}
        initialValuesPrefix={initialValuesPrefix}
        setInitialValuePrefix={setInitialValuePrefix}
        setInitialValues={setInitialValues}
        initialValues={initialValues}
        organization={organization}
      />

      <AddCourseDetails
        openDialog={openFormDetails}
        setOpenFormDetails={setOpenFormDetails}
        initialValuesDetails={initialValuesDetails}
        setInitialValueDetails={setInitialValueDetails}
        organization={organization}
        coursePrefix={coursePrefix}
        mCoursePrefixName={mCoursePrefixName}
      />

      <LookupUsers
        openDialog={openLookupForm}
        setOpenLookupForm={setOpenLookupForm}
        setOpenAddInstructorForm={setOpenAddInstructorForm}
        openAddInstructorForm={openAddInstructorForm}
      />

      <Dialog open={openAddInstructorForm.isOpen} style={{ zIndex: +1 }}>
        <DialogTitle sx={{ backgroundColor: '#1663ab', color: 'white' }}>
          <div className='flex justify-between items-center'>
            <div>Add User</div>

            <IconButton>
              <AiOutlineClose
                onClick={handleCloseAddUserForm}
                style={{ color: 'white' }}
              />
            </IconButton>
          </div>
        </DialogTitle>

        <Formik
          initialValues={(() => {
            if (typeof openAddInstructorForm?.data === 'string') {
              return {
                first_name: '',
                last_name: '',
                middle_name: '',
                email: openAddInstructorForm?.addnew,
                organization: parsedStoredData?.organization_name || '',
              };
            } else {
              return {
                first_name: '',
                last_name: '',
                middle_name: '',
                email: '',
                organization: parsedStoredData?.organization_name || '',
              };
            }
          })()}
          validationSchema={userValidation}
          onSubmit={(values: any) => {
            // Check if organization details are missing
            if (
              !values.organization ||
              !values.organization.id ||
              !values.organization.name
            ) {
              if (parsedStoredData && parsedStoredData.organization_name) {
                values.organization = {
                  id: parsedStoredData.organization_name.id,
                  name: parsedStoredData.organization_name.name,
                };
              } else if (organization) {
                // Fallback to state if parsedStoredData is not available
                values.organization = organization;
              }
            }

            dispatch(
              inviteUser(
                values,
                openAddInstructorForm,
                setOpenAddInstructorForm,
                instructorsData
              )
            );
            setIsTyping(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            isValid,
            isSubmitting,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => (
            <DialogContent
              sx={{ width: '600px', height: 'fitContent', overflowX: 'hidden' }}
            >
              <Form>
                <Field
                  as={TextField}
                  label='First Name'
                  variant='standard'
                  required
                  name='first_name'
                  value={values.first_name}
                  helperText={
                    errors.first_name && touched.first_name
                      ? errors.first_name
                      : ''
                  }
                  fullWidth
                  error={errors.first_name && touched.first_name}
                  sx={{ marginBottom: '15px' }}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('first_name', e.target.value, true); // force validation
                    setIsTyping(true);
                    // if (pathname === '/addcourse') {
                    //   localStorage.setItem(
                    //     'unitrolesFormData',
                    //     JSON.stringify({
                    //       ...values,
                    //       first_name: e.target.value,
                    //     })
                    //   );
                    // }
                  }}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    setIsTyping(false);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {isTyping &&
                          (errors.first_name ? (
                            <span style={{ color: 'red' }}>✖</span>
                          ) : (
                            values.first_name && (
                              <span style={{ color: 'green' }}>✔</span>
                            )
                          ))}
                      </InputAdornment>
                    ),
                  }}
                />
                <Field
                  variant='standard'
                  as={TextField}
                  label='Middle Name'
                  name='middle_name'
                  value={values.middle_name}
                  helperText={
                    errors.middle_name && touched.middle_name
                      ? errors.middle_name
                      : ''
                  }
                  fullWidth
                  error={errors.middle_name && touched.middle_name}
                  sx={{ marginBottom: '15px' }}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('middle_name', e.target.value, true); // force validation
                    setIsTyping(true);
                    // if (pathname === '/addcourse') {
                    //   localStorage.setItem(
                    //     'unitrolesFormData',
                    //     JSON.stringify({
                    //       ...values,
                    //       middle_name: e.target.value,
                    //     })
                    //   );
                    // }
                  }}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    setIsTyping(false);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {isTyping &&
                          (errors.middle_name ? (
                            <span style={{ color: 'red' }}>✖</span>
                          ) : (
                            values.middle_name && (
                              <span style={{ color: 'green' }}>✔</span>
                            )
                          ))}
                      </InputAdornment>
                    ),
                  }}
                />
                <Field
                  variant='standard'
                  as={TextField}
                  label='Last Name'
                  required
                  name='last_name'
                  value={values.last_name}
                  helperText={
                    errors.last_name && touched.last_name
                      ? errors.last_name
                      : ''
                  }
                  fullWidth
                  error={errors.last_name && touched.last_name}
                  sx={{ marginBottom: '15px' }}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('last_name', e.target.value, true); // force validation
                    setIsTyping(true);
                    // if (pathname === '/addcourse') {
                    //   localStorage.setItem(
                    //     'unitrolesFormData',
                    //     JSON.stringify({ ...values, last_name: e.target.value })
                    //   );
                    // }
                  }}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    setIsTyping(false);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {isTyping &&
                          (errors.last_name ? (
                            <span style={{ color: 'red' }}>✖</span>
                          ) : (
                            values.last_name && (
                              <span style={{ color: 'green' }}>✔</span>
                            )
                          ))}
                      </InputAdornment>
                    ),
                  }}
                />
                <Field
                  variant='standard'
                  as={TextField}
                  label='Organization Email'
                  required
                  name='email'
                  value={values.email}
                  helperText={errors.email && touched.email ? errors.email : ''}
                  fullWidth
                  error={errors.email && touched.email}
                  sx={{ marginBottom: '15px' }}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('email', e.target.value, true); // force validation
                    setIsTyping(true);
                    // if (pathname === '/addcourse') {
                    //   localStorage.setItem(
                    //     'unitrolesFormData',
                    //     JSON.stringify({ ...values, email: e.target.value })
                    //   );
                    // }
                  }}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    setIsTyping(false);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {isTyping &&
                          (errors.email ? (
                            <span style={{ color: 'red' }}>✖</span>
                          ) : (
                            values.email && (
                              <span style={{ color: 'green' }}>✔</span>
                            )
                          ))}
                      </InputAdornment>
                    ),
                  }}
                />
                <div className='flex gap-3 justify-end'>
                  <Button
                    variant='contained'
                    // fullWidth
                    onClick={handleCloseAddUserForm}
                    style={{ textTransform: 'capitalize' }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant='contained'
                    type='submit'
                    // fullWidth
                    disabled={!isValid}
                    style={{ textTransform: 'capitalize' }}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </DialogContent>
          )}
        </Formik>
      </Dialog>
    </main>
  );
}

export default AddEditCourse;

interface SearchItem {
  id: string;
  name: string;
}

type Instructors = {
  id: string;
  name: string;
  organization_name: string;
  assessment_coordinators: AssessmentCoordinators[];
};

type AssessmentCoordinators = {
  id: string;
  name: string;
  organization_name: string;
};

const LookupUsers = ({
  openDialog,
  setOpenLookupForm,
  setOpenAddInstructorForm,
  openAddInstructorForm,
}: any) => {
  const handleClickClose = () => {
    Alert.confirm(() => {
      setOpenLookupForm(false);
      setSearchResults([]);
      setSelectedItems([]);
    });
  };
  const handleSearchClickClose = () => {
    setOpenLookupForm(false);
    setSearchResults([]);
    setSelectedItems([]);
  };

  const dispatch = useDispatch();
  const instructorsData: any[] = useSelector(
    (state: any) => state.users.instructorsData
  );

  const [searchResults, setSearchResults] = useState<SearchItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<SearchItem[]>([]);

  const handleSearch = async (searchTerm: string | any) => {
    // Send search request to server and set search results
    searchFromOtherOrg(
      { email: searchTerm?.name },
      setSearchResults,
      setSelectedItems,
      searchResults,
      setOpenAddInstructorForm,
      setOpenLookupForm,
      openAddInstructorForm
    );
  };

  const handleRemove = (item: SearchItem) => {
    setSelectedItems(
      selectedItems.filter((selectedItem) => selectedItem !== item)
    );
  };

  const handleAddInstructors = () => {
    const instructors = instructorsData;

    const updatedInstructors = [...instructors, ...selectedItems].map((x) =>
      JSON.stringify(x)
    );
    const newSet = [...new Set(updatedInstructors)].map((x) => JSON.parse(x));

    dispatch(handleGetInstructors(newSet));

    const fieldName = openAddInstructorForm.name;
    const filteredData = openAddInstructorForm.data.filter(
      (d: any) => d.name !== 'SEARCH BY EMAIL'
    );

    const newData = [...filteredData, ...selectedItems];
    const finalFieldName =
      fieldName === 'instructor' ? 'instructors' : fieldName;

    openAddInstructorForm.callback(finalFieldName, newData);

    handleSearchClickClose();
  };

  const validation = Yup.object({
    search: Yup.object({
      id: Yup.string(),
      name: Yup.string().email('invalid email').required('Required Field'),
    }),
  });

  return (
    <Dialog open={openDialog}>
      <DialogTitle sx={{ backgroundColor: '#1663ab', color: 'white' }}>
        <div className='flex justify-between items-center'>
          <div>Add Users</div>
          <IconButton>
            <AiOutlineClose
              onClick={handleClickClose}
              style={{ color: 'white' }}
            />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent
        sx={{
          width: '600px',
          marginTop: '20px',
          height: 'fitContent',
          overflowX: 'hidden',
        }}
      >
        <Formik
          initialValues={{ search: { id: '', name: '' } }}
          validationSchema={validation}
          onSubmit={(values) => {
            handleSearch(values?.search);
          }}
        >
          {({ values, setFieldValue, errors, touched, handleBlur }) => (
            <Form>
              <Field
                name='search'
                as={Autocomplete}
                freeSolo
                value={values?.search}
                options={searchResults?.length > 0 ? searchResults : []}
                // fullWidth
                isOptionEqualToValue={(option: any, value: any) =>
                  option?.id === value
                }
                disableClearable
                // renderOption={(option: SearchItem) => option.name}
                getOptionLabel={(option: SearchItem) => option?.name}
                // onChange={(_: any, name: any) => {
                //
                //   setFieldValue("search", name);
                // }}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField
                    {...params}
                    name='search'
                    placeholder='Enter Email Id'
                    variant='outlined'
                    size='small'
                    sx={{ paddingRight: '6px' }}
                    onChange={(e) => {
                      setFieldValue('search', {
                        id: '',
                        name: e.target.value,
                      });
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fab
                          color='primary'
                          type='submit'
                          size='small'
                          aria-label='search'
                        >
                          <SearchIcon />
                        </Fab>
                      ),
                    }}
                    error={
                      errors?.search?.name && touched?.search ? true : false
                    }
                    helperText={
                      errors?.search?.name && touched?.search
                        ? errors?.search?.name
                        : ''
                    }
                    // value={values.search}
                  />
                )}
              />
            </Form>
          )}
        </Formik>
        <List>
          {selectedItems.map((item: SearchItem) => (
            <ListItem key={item.id}>
              <ListItemText primary={item.name} />
              <ListItemSecondaryAction>
                <IconButton edge='end' onClick={() => handleRemove(item)}>
                  <RemoveIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          style={{ textTransform: 'capitalize' }}
          // fullWidth
          onClick={handleClickClose}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          type='submit'
          onClick={handleAddInstructors}
          // fullWidth
          disabled={selectedItems?.length <= 0}
          style={{ textTransform: 'capitalize' }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import React from 'react';
import { TextField } from '@mui/material';

export function MaterialUIPickers({
  index,
  values,
  setValues,
  minDateTime,
  admBtn,
  setAdmBtn,
}: any) {
  const handleChange = (e: any) => {
    const datetimeLocal = e.target.value;
    const newValues = [...values];
    newValues[index] = datetimeLocal;
    setValues(newValues);

    const timestamp = Date.parse(e.target.value); // convert to UTC timestamp
    const date = new Date(timestamp); // create a Date object in UTC time
    const utcString = date.toISOString(); // convert to UTC string

    // postDateTime(utcString, id);
    if (admBtn && admBtn.length && setAdmBtn) {
      const newAdmBtnValues = [...admBtn];
      newAdmBtnValues[index] = true;
      setAdmBtn(newAdmBtnValues);
    }
  };
  return (
    <TextField
      type='datetime-local'
      value={values[index]}
      size='small'
      onChange={handleChange}
      fullWidth
      onKeyDown={(e) => e.preventDefault()}
      inputProps={{
        min: minDateTime ? minDateTime : null,
      }}
    />
  );
}

import React from 'react';
import Grid from '@mui/material/Grid';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../reduxStore/Store';
import {
  updateTime,
  getResultAnalysisAdm,
  getTestItemsResultAnalysisAdm,
} from '../../reduxStore/reducer/administrationReducer';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import * as readxlsx from 'xlsx';
import Alert from '../../utils/Alert/Alert';
import { administrationBox, studentData } from './Administration';
import ContentModulesLearningObjectivesDialog from './ContentModulesLearningObjectivesDialog';
import ContentMLearOTable from './ContentMLearOTable';
import CopyLink from './Snackbar';
import { MaterialUIPickers } from './MaterialUIPickers';

export const AdministrationBoxes = ({
  rosterCount,
  sendMail,
  courseAssessmentId,
  contentMLearningO,
}: any) => {
  const isAdaptive =
    contentMLearningO?.contentModules?.length ||
    contentMLearningO?.learningObjectives?.length;
  const obj1: any = administrationBox.map((d: any) =>
    structuredClone(contentMLearningO)
  );
  const obj2: any = obj1.map((d: any, i: any) => {
    return {
      contentModules: d.contentModules.map((f: any, j: any) => {
        const temp = administrationBox[
          i
        ]?.contentMLearningO?.contentModules?.find(
          (o: any) => o.value === f.value
        );
        console.log(temp, 222);
        return { ...f, bool: temp?.bool || false };
      }),
      learningObjectives: d.learningObjectives.map((f: any, j: any) => {
        const temp = administrationBox[
          i
        ]?.contentMLearningO?.learningObjectives?.find(
          (o: any) => o.value === f.value
        );

        return { ...f, bool: temp?.bool || false };
      }),
    };
  });

  console.log('obj1:', obj1, '\n', 'obj2:', obj2, '\n', 30);
  const dispatch = useDispatch<AppDispatch>();
  const [contentMLearningOValue, setContentMLearningOValue] =
    React.useState(obj2);
  // const estimateLengthArr =[]
  const estimateLengthArr: any = contentMLearningOValue.map(
    (d: any, i: number) => {
      let estimatedTime = 0;
      d.contentModules.forEach((f: any) => {
        if (f?.bool && !isNaN(+f?.time)) {
          estimatedTime += +f?.time;
        }
      });
      d.learningObjectives.map((f: any) => {
        if (f?.bool && !isNaN(+f?.time)) {
          estimatedTime += +f?.time;
        }
      });
      return estimatedTime;
    }
  );
  console.log(estimateLengthArr, 72);

  //pickerValues is [], if administrationBox is [] or undefined or null
  const [pickerValues, setPickerValues] = React.useState(
    administrationBox.map((e: any) => {
      if (e.start_date_time) {
        const utcDatetime = new Date(e.start_date_time); // create a Date object in UTC

        const localTimezoneOffset = new Date().getTimezoneOffset() * 60000; // get local timezone offset in milliseconds
        const localDatetime = new Date(
          utcDatetime.getTime() - localTimezoneOffset
        ); // create a Date object in local timezone

        const localDatetimeString = localDatetime.toISOString().slice(0, 16); // convert to local datetime string compatible with datetime-local input element

        return localDatetimeString;
      } else return null;
    })
  );
  const [lastReminder, setLastReminder] = React.useState(
    administrationBox.map((e: any) => {
      if (e?.last_reminder_sent) {
        const utcDatetime = new Date(e?.last_reminder_sent); // create a Date object in UTC

        const localTimezoneOffset = new Date().getTimezoneOffset() * 60000; // get local timezone offset in milliseconds
        const localDatetime = new Date(
          utcDatetime.getTime() - localTimezoneOffset
        ); // create a Date object in local timezone

        const localDatetimeString = localDatetime.toISOString().slice(0, 16); // convert to local datetime string compatible with datetime-local input element

        return localDatetimeString;
      } else return null;
    })
  );

  //pickerValuesEnd is [], if administrationBox is [] or undefined or null
  const [pickerValuesEnd, setPickerValuesEnd] = React.useState(
    administrationBox.map((e: any) => {
      if (e.end_date_time) {
        const utcDatetime = new Date(e.end_date_time); // create a Date object in UTC

        const localTimezoneOffset = new Date().getTimezoneOffset() * 60000; // get local timezone offset in milliseconds
        const localDatetime = new Date(
          utcDatetime.getTime() - localTimezoneOffset
        ); // create a Date object in local timezone

        const localDatetimeString = localDatetime.toISOString().slice(0, 16); // convert to local datetime string compatible with datetime-local input element

        return localDatetimeString;
      } else {
        return null;
      }
    })
  );

  const [isDeadlineChanged, setIsDeadlineChanged] = React.useState(
    Array(administrationBox.length).fill(false)
  );
  const [isStartDateChanged, setIsStartDateChanged] = React.useState(
    Array(administrationBox.length).fill(false)
  );

  //this return undefined if undefined or null
  return administrationBox?.map((data: any, index: number) => {
    const isDefaultExpanded = index <= 1;

    const {
      id,
      responses, //may be null or ''
      avg_score,
    } = data;

    const isDownloadBtnEnabled = data.start_date_time && data.end_date_time;

    const responsesText = `${responses ? responses : 0} / ${
      rosterCount ? rosterCount : 0
    }`;

    const saveNewDeadline = (
      id: any,
      index: number,
      administration_no: any,
      contentMLearningOValue: any
    ) => {
      if (rosterCount) {
        //true, if deadline not having date
        if (!pickerValuesEnd[index] || !pickerValues[index]) {
          Alert.info({
            title: 'Set both start and end date before starting an assessment',
            text: '',
          });
          throw new Error('execution stopped');
        } else {
          const timestamp1 = Date.parse(pickerValues[index]); // convert to UTC timestamp
          const date1 = new Date(timestamp1); // create a Date object in UTC time
          const utcString1 = date1.toISOString(); // convert to UTC string

          const timestamp2 = Date.parse(pickerValuesEnd[index]); // convert to UTC timestamp
          const date2 = new Date(timestamp2); // create a Date object in UTC time
          const utcString2 = date2.toISOString(); // convert to UTC string

          // console.log(utcString1, utcString2);
          if (moment.utc().isAfter(moment.utc(utcString2))) {
            Alert.info({
              title: 'Deadline must be greater than current date & time',
              text: '',
            });
            throw new Error('execution stopped');
          }
          if (moment.utc(utcString1).isSameOrAfter(moment.utc(utcString2))) {
            Alert.info({
              title: 'Start date & time must be less than deadline',
              text: '',
            });
            throw new Error('execution stopped');
          }

          // postStartDateTime(utcString1, utcString2, id, administration_no);
          let arrData: any = [];
          const mailData = studentData.map((d: any) => {
            const found = d.test_statuses[data.administration_no - 1];
            if (found?.test_status === false && d.is_active === true) {
              arrData.push({
                id: d.course_student_id,
                courseAssessmentId: courseAssessmentId,
                courseAdministrationId: data.administration_no,
                email: d.email_id,
                first_name: d.first_name,
                last_name: d.last_name,
              });
              return {
                id: d.id,
                courseAssessmentId: courseAssessmentId,
                courseAdministrationId: data.id,
                email: d.email_id,
                first_name: d.first_name,
                last_name: d.last_name,
              };
            }
          });

          // if (moment.utc(utcString1).isBefore(moment.utc()))
          updateTime(
            {
              start_date_time: utcString1,
              end_date_time: utcString2,
              id: id,
              administration_no: administration_no,
              contentMLearningOValue: contentMLearningOValue,
            },
            sendMail,
            arrData,
            false,
            setLastReminder,
            index
          );
        }
      } else {
        Alert.info({
          title: 'Add students before starting an assessment',
          text: '',
        });
        throw new Error('execution stopped');
      }
    };

    function handleDownloadData(
      courseAssessmentId: string,
      administration_id: string
    ) {
      dispatch(
        getResultAnalysisAdm(
          courseAssessmentId,
          administration_id,
          downloadReportAnalysis
        )
      );
    }

    function handleTestItemsDownloadData(
      courseAssessmentId: string,
      administration_id: string
    ) {
      dispatch(
        getTestItemsResultAnalysisAdm(
          courseAssessmentId,
          administration_id,
          downloadReportAnalysis
        )
      );
    }

    function downloadReportAnalysis(
      courseInfoData: any,
      studentResultsData: any
    ) {
      const fileNameProperties = courseInfoData.pop();

      const workbook = readxlsx.utils.book_new();

      // studentResultsData = studentResultsData.map((d: any) => ({
      //   ...d,
      //   Submitted: d.Submitted? moment.utc(d.Submitted).format('MM-DD-YYYY hh:mm a') : ''
      // }))
      // create first sheet with course info data
      //@ts-ignore
      const courseInfoSheet = readxlsx.utils.json_to_sheet(courseInfoData);
      readxlsx.utils.book_append_sheet(
        workbook,
        courseInfoSheet,
        'Course Info'
      );

      // create second sheet with student results data
      //@ts-ignore
      const studentResultsSheet =
        readxlsx.utils.aoa_to_sheet(studentResultsData);
      readxlsx.utils.book_append_sheet(
        workbook,
        studentResultsSheet,
        'Student Results'
      );

      // write workbook data to file and download
      const excelBuffer = readxlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });

      const now = new Date();
      const UTCTime = `${(now.getUTCMonth() + 1)
        .toString()
        .padStart(2, '0')}-${now
        .getUTCDate()
        .toString()
        .padStart(2, '0')}-${now.getUTCFullYear()} ${now
        .getUTCHours()
        .toString()
        .padStart(2, '0')}:${now.getUTCMinutes().toString().padStart(2, '0')} ${
        now.getUTCHours() >= 12 ? 'PM' : 'AM'
      }`;

      //@ts-ignore
      const fileName = `LASSO_${fileNameProperties.code}_${fileNameProperties.term}_${fileNameProperties.year}_${fileNameProperties.instructor}_admin${fileNameProperties.admNo}_${fileNameProperties.courseName}_${UTCTime}.xlsx`;
      const downloadLink = document.createElement('a');
      document.body.appendChild(downloadLink);
      downloadLink.style.display = 'none';
      const blob = new Blob([excelBuffer], {
        type: 'application/octet-stream',
      });
      const url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = fileName;
      downloadLink.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(downloadLink);
    }

    return (
      <Grid item xs={12} md={5.8} key={index}>
        <Accordion defaultExpanded={isDefaultExpanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            sx={{ backgroundColor: '#e6e6e6' }}
          >
            <Typography component='h2'>Administration {index + 1}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container item rowGap={{ xs: 0.8, sm: 2 }}>
              {isAdaptive ? (
                <>
                  {' '}
                  <Grid item xs={12}>
                    <ContentMLearOTable
                      contentMLearningO={contentMLearningOValue[index]}
                      setContentMLearningO={setContentMLearningOValue}
                      courseAssessmentId={courseAssessmentId}
                      index={index}
                    />
                  </Grid>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={6} sm={6}>
                      <label className='right-aligning-labels'>
                        Estimated length:
                      </label>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      {estimateLengthArr[index]} Minutes
                    </Grid>
                  </Grid>
                </>
              ) : (
                ''
              )}

              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={6} sm={6}>
                  <label className='right-aligning-labels'>Responses:</label>
                </Grid>
                <Grid item xs={6} sm={6}>
                  {responsesText}
                </Grid>
                <Grid item xs={6} sm={6}>
                  <label className='right-aligning-labels'>
                    Average Score:
                  </label>
                </Grid>
                <Grid item xs={6} sm={6}>
                  {avg_score ? `${parseFloat(avg_score.toFixed(1))}%` : 0}
                </Grid>
                <Grid item xs={6} sm={6}>
                  <label className='right-aligning-labels'>
                    Available from:
                  </label>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <MaterialUIPickers
                    id={data.id} //used to update time on particular administration
                    index={index} //used to update particular index in pickerValues array
                    values={pickerValues} //array to store datetime values
                    setValues={setPickerValues} //hook to modify the array
                    minDateTime={moment().format('YYYY-MM-DDTHH:mm')}
                    admBtn={isStartDateChanged}
                    setAdmBtn={setIsStartDateChanged}
                  />
                </Grid>

                <Grid item xs={6} sm={6}>
                  <label className='right-aligning-labels'> Deadline:</label>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <MaterialUIPickers
                    id={data.id} //used to update time on particular administration
                    index={index}
                    values={pickerValuesEnd}
                    setValues={setPickerValuesEnd}
                    minDateTime={
                      pickerValues[index]
                        ? moment(pickerValues[index])
                            .add(1, 'minutes')
                            .format('YYYY-MM-DDTHH:mm')
                        : moment().format('YYYY-MM-DDTHH:mm')
                    }
                    admBtn={isDeadlineChanged}
                    setAdmBtn={setIsDeadlineChanged}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <label className='right-aligning-labels'>
                    Last reminder:
                  </label>
                </Grid>
                <Grid item xs={6} sm={6}>
                  {lastReminder[index]
                    ? moment(moment(lastReminder[index]).utc())
                        .local()
                        .format('MM-DD-YYYY hh:mm a')
                    : ''}
                  {/* {data?.last_reminder_sent
                  ? moment(data?.last_reminder_sent)
                      .local()
                      .format('MM/DD/YYYY hh:mm A')
                  : ''} */}{' '}
                </Grid>
              </Grid>

              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                alignItems='center'
              >
                <Grid item xs={4}>
                  {' '}
                  <Link
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      textDecoration: 'none',
                      color: 'primary.main',
                      cursor: 'pointer',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                    fontSize={12}
                    component='button'
                    variant='body2'
                    disabled={!isDownloadBtnEnabled}
                    onClick={() => {
                      handleDownloadData(
                        courseAssessmentId,
                        data.administration_no
                      );
                    }}
                  >
                    Download Data
                  </Link>
                  <CopyLink />
                </Grid>

                <Grid item xs={8}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button
                        sx={{ textTransform: 'initial' }}
                        variant='outlined'
                        size='small'
                        onClick={() => {
                          let arrData: any = [];
                          const mailData = studentData.map((d: any) => {
                            const found =
                              d.test_statuses[data.administration_no - 1];
                            if (
                              found?.test_status === false &&
                              d.is_active === true
                            ) {
                              arrData.push({
                                id: d.course_student_id,
                                courseAssessmentId: courseAssessmentId,
                                courseAdministrationId: data.administration_no,
                                email: d.email_id,
                                first_name: d.first_name,
                                last_name: d.last_name,
                              });
                              return {
                                id: d.course_student_id,
                                courseAdministrationId: data.administration_no,
                                email: d.email_id,
                                first_name: d.first_name,
                                last_name: d.last_name,
                              };
                            }
                          });

                          if (
                            pickerValues?.[index]
                              ? moment
                                  .utc()
                                  .isSameOrAfter(
                                    new Date(
                                      Date.parse(pickerValues?.[index])
                                    ).toISOString()
                                  ) &&
                                moment
                                  .utc()
                                  .isSameOrBefore(
                                    new Date(
                                      Date.parse(pickerValuesEnd[index])
                                    ).toISOString()
                                  )
                              : false
                          ) {
                            if (arrData?.length != 0) {
                              sendMail(
                                arrData,
                                false,
                                setLastReminder,
                                index,
                                pickerValues,
                                pickerValuesEnd
                              );
                            } else if (studentData?.length === 0) {
                              Alert.info({
                                text: '',
                                title: 'Please add student(s) to send reminder',
                              });
                            } else {
                              Alert.info({
                                text: '',
                                title: 'All responses are captured',
                              });
                            }
                          } else if (
                            pickerValues?.[index]
                              ? moment()
                                  .utc()
                                  .isBefore(
                                    new Date(
                                      Date.parse(pickerValues[index])
                                    ).toISOString()
                                  )
                              : false
                          ) {
                            Alert.info({
                              title: 'Start date & time is not reached yet',
                              text: '',
                            });
                          } else if (
                            pickerValuesEnd?.[index]
                              ? moment()
                                  .utc()
                                  .isAfter(
                                    new Date(
                                      Date.parse(pickerValuesEnd[index])
                                    ).toISOString()
                                  )
                              : false
                          ) {
                            Alert.info({
                              title:
                                'Reminders emails cannot be sent out after the due date has passed',
                              text: '',
                            });
                          } else if (!pickerValuesEnd?.[index]) {
                            Alert.info({
                              title:
                                'Set deadline before starting an assessment',
                              text: '',
                            });
                          }
                        }}
                      >
                        Send Reminders
                      </Button>{' '}
                    </Grid>
                    <Grid item>
                      <Button
                        sx={{ textTransform: 'initial' }}
                        variant='contained'
                        size='small'
                        onClick={() => {
                          // if (
                          //   isStartDateChanged[index] && isDeadlineChanged[index]
                          // )
                          saveNewDeadline(
                            courseAssessmentId,
                            index,
                            data.administration_no,
                            contentMLearningOValue[index]
                          );

                          const newStartDateValues = [...isStartDateChanged];
                          newStartDateValues[index] = false;
                          setIsStartDateChanged(newStartDateValues);

                          const newDeadlineDateValues = [...isDeadlineChanged];
                          newDeadlineDateValues[index] = false;
                          setIsDeadlineChanged(newDeadlineDateValues);
                        }}
                        disabled={
                          !(
                            isStartDateChanged[index] ||
                            isDeadlineChanged[index]
                          )
                          // || (isAdaptive && areAllBoolFalse(contentMLearningOValue[index]))
                        }
                      >
                        {/* {isStartDateChanged[index] && isDeadlineChanged[index]
                      ? 'Save New Dates'
                      : 'Save Dates'} */}
                        Save
                      </Button>{' '}
                    </Grid>
                    <Grid item>
                      <Button
                        sx={{ textTransform: 'initial' }}
                        variant='contained'
                        size='small'
                        onClick={() => {
                          // Reset the date pickers to their initial states
                          const newPickerValues = [...pickerValues];
                          const newPickerValuesEnd = [...pickerValuesEnd];

                          // Clear the values for this particular administration
                          newPickerValues[index] = '';
                          newPickerValuesEnd[index] = '';

                          // Update the state with the cleared values
                          setPickerValues(newPickerValues);
                          setPickerValuesEnd(newPickerValuesEnd);

                          // Reset the change indicators
                          const newStartDateValues = [...isStartDateChanged];
                          newStartDateValues[index] = false;
                          setIsStartDateChanged(newStartDateValues);

                          const newDeadlineDateValues = [...isDeadlineChanged];
                          newDeadlineDateValues[index] = false;
                          setIsDeadlineChanged(newDeadlineDateValues);
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                {/* {isAdaptive ? (
                  <ContentModulesLearningObjectivesDialog
                    contentMLearningO={contentMLearningOValue[index]}
                    setContentMLearningO={setContentMLearningOValue}
                    index={index}
                  />
                ) : (
                  ''
                )} */}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  });
};

// function areAllBoolFalse(data: any) {
//   // Check contentModules
//   for (let module of data.contentModules) {
//     if (module.bool) {
//       return false;
//     }
//   }

//   // Check learningObjectives
//   for (let objective of data.learningObjectives) {
//     if (objective.bool) {
//       return false;
//     }
//   }

//   // If no object has bool set to true, return true
//   return true;
// }

import React, { useRef, useMemo, useState, useEffect } from 'react';
import plugins from 'suneditor/src/plugins';
import { useLocation, useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { ICellRendererParams } from 'ag-grid-community';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Button,
  Link,
  TextField,
  Tooltip,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  FormHelperText,
  CircularProgress,
  Radio,
  Fab,
} from '@mui/material';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Paper,
  DialogProps,
  Input,
  InputAdornment,
} from '@mui/material';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { Formik, Form, Field, ErrorMessage, FormikErrors } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import EditIcon from '@mui/icons-material/Edit';
import SunEditor from 'suneditor-react';
import katex from 'katex';
// import AdaptiveAssessmentQuestion from './AdaptiveAssessmentQuestion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Swal from 'sweetalert2';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '../../utils/Alert/Alert';
import { utils, read } from 'xlsx';
import {
  addAdaptiveItem,
  deleteAdaptiveItem,
  editAdaptiveAssessment,
  fileUpload,
  getAdaptiveAssessment,
  getAssessmentDiscipline,
  getRFiles,
} from '../../reduxStore/reducer/assessmentReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../reduxStore/Store';
import * as readxlsx from 'xlsx';
import { AssessmentDiscipline } from './Dialogs/AssessmentDiscipline';
import AssessSpecificQuestion from './AssessSpecificQuestion';
import AddCustomForm from './AddCustomForm';
import { ContentMLearningOList } from './ContentMLearningOList';
import { getAdaptiveAssessmentQuestionById } from '../../reduxStore/reducer/assessmentReducer';
import createMergeTag from './Plugin/merge_tag_plugin';
import { CancelRounded } from '@mui/icons-material';
import ViewAdaptiveAssessment from './Dialogs/ViewAdaptiveAssessment';

const generateValidationSchema = (fields: any) => {
  const schemaFields: any = {};

  fields.forEach((field: any) => {
    if (field.type === 'string') {
      schemaFields[field.name] = Yup.string().required('Required Field');
    } else {
      schemaFields[field.name] = Yup.number().required('Required Field');
    }
  });

  return Yup.object(schemaFields);
};

function validateData(data: any[]): string[] {
  let errorIds: string[] = [];

  for (let item of data) {
    let hasError = false;

    if (
      !item.question.questionLabel ||
      !item.question.questionContent ||
      !item.question.responseType ||
      !item.question.optionOrientation ||
      item.question.defaultAnswer === undefined ||
      item.question.defaultAnswer === null
    ) {
      hasError = true;
    }

    for (let option of item.question.responseOptions) {
      if (!option.optionLabel || !option.optionContent) {
        if (!option.optionLabel) {
          continue;
        }
        hasError = true;
      }
    }

    if (hasError) {
      errorIds.push(item.item_id);
    }
  }

  return errorIds;
}

const AddEditAdaptiveAssessment = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [gridApi, setGridApi] = useState<any>(null);
  const [data, setData] = useState<any>([]);
  // const [formData, setFormData] = useState<any>({});
  const [columnDefs, setColumnDefs] = useState<any[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [file, setFile]: any = useState<any>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const formikRef: any = React.useRef(null);
  const formikRef2: any = React.useRef(null);
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [question, setQuestion]: any = useState<any>({
    questionLabel: '',
    questionContent: '',
    responseType: 'Single',
    //state?.value?.key == true ? 'Yes' : '
    optionOrientation: 'Vertical',
    answer: '',
    defaultAnswer: '',
    responseOptions: [],
  });

  //
  const [saveAndPublish, setSaveAndPublish] = useState(false);
  const [columnName, setColumnName]: any = useState([]);
  const [action, setAction] = useState('Create');
  const [adaptiveValueError, setAdaptiveValueError] = useState<any>({
    disciplineError: '',
    officialAssessmentNameError: '',
    publicAssessmentNameError: '',
    assessmentCodeError: '',
    publicCodeError: '',
    irtError: '',
    contentModulesError: '',
    learningObjectivesError: '',
    rFilesError: '',
  });
  const [editingContentLearning, setEditingContentLearning] = useState({
    contentModule: '',
    learningObject: '',
  });
  const [updateAdaptiveLoading, setUpdateAdaptiveLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [instruction, setInstruction] = useState(
    state?.value?.instruction
      ?.replace(/<\/?p>/g, '')
      ?.replace(/<br\s?\/?>/g, '') == ''
      ? false
      : true
  );

  state.value.contentModule = state?.value?.contentModules.map(
    (e: any, i: any) => {
      return {
        value: e,
        time:
          state?.value?.contentModulesTime &&
          state?.value?.contentModulesTime[i]
            ? state?.value?.contentModulesTime[i]
            : '',
        descript:
          state?.value?.contentModulesDescript &&
          state?.value?.contentModulesDescript[i]
            ? state?.value?.contentModulesDescript[i]
            : '',
      };
    }
  );
  state.value.learningObjective = state?.value?.learningObjectives.map(
    (e: any, i: any) => {
      return {
        value: e,
        time:
          state?.value?.learningObjectivesTime &&
          state?.value?.learningObjectivesTime[i]
            ? state?.value?.learningObjectivesTime[i]
            : '',
        descript:
          state?.value?.learningObjectivesDescript &&
          state?.value?.learningObjectivesDescript[i]
            ? state?.value?.learningObjectivesDescript[i]
            : '',
      };
    }
  );
  const [formData, setFormData] = useState<any>({
    ...state?.value,
    contentModuleValue: '',
    contentModuleTime: '',
    contentModuleDescript: '',
    learningObjectiveValue: '',
    learningObjectiveTime: '',
    learningObjectiveDescript: '',
  });
  const [assessSpecQuestion, setAssessSpecQuestion] = useState(
    state?.value?.assessSpecQuestion ? state?.value?.assessSpecQuestion : []
  );
  const [button, setButton] = useState(false);
  const handleSaveAssessSpecQue = (question: any) => {
    setAssessSpecQuestion(question);
  };

  const onGridReady = (e: any) => {
    setGridApi(e.api);
  };

  const disciplineData: { id: string; name: string }[] = useSelector(
    (state: RootState) => {
      const data = state.assessment.assessmentDiscipline;

      const alterData: any = [...data];
      alterData.unshift({
        id: '',
        name: 'ADD NEW DISCIPLINE',
      });
      return alterData;
    }
  );

  const rFilesData: any = useSelector(
    (state: RootState) => state.assessment.rFiles
  );

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      event.returnValue = ''; // This is required for Chrome to show the warning dialog
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (formikRef.current) {
      const updatedValues = {
        ...formikRef.current.values,
        ...formData,
      };

      formikRef.current.resetForm({
        values: updatedValues,
      });
    }
  }, [formData]);

  useEffect(() => {
    // if (state?.action === 'Edit') {
    // dispatch(getAdaptiveAssessment(state.value.id, setData));

    dispatch(getAssessmentDiscipline());
    dispatch(getAdaptiveAssessment(state.value.id, setData, setFormData));
    dispatch(getRFiles());
    // }

    const initial: any = {
      content_modules: {},
      learning_objectives: {},
    };
    const column: any = ['question'];
    const validation: any = [];

    const contentModuleColumns: any = [];
    const learningObjectiveColumns: any = [];

    const dataColumnDef: any = [
      // {
      //   headerName: 'Item Code',
      //   field: 'item_code',
      //   // sort: 'asc',
      //   minWidth: 160,
      //   cellRenderer: (row: ICellRendererParams) => {
      //     return Boolean(row.data.item_code)
      //       ? row.data.item_code
      //       : state?.value.assessmentCode;
      //   },
      // },
      {
        headerName: 'Item Id',
        field: 'item_id',
        // sort: 'asc',

        // cellRenderer: (row: ICellRendererParams) => {
        //   return generateSequentialValue(row.data.item_code)
        // },
        // comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isInverted: any) => {
        //   // const itemCodeA = nodeA.data.item_code;
        //   // const itemCodeB = nodeB.data.item_code;

        //   // if (itemCodeA !== itemCodeB) {
        //   //   // If item codes are different, compare based on 'item_code'
        //   //   return itemCodeA.localeCompare(itemCodeB) * (isInverted ? -1 : 1);
        //   // }

        //   // If item codes are the same, compare based on 'item_id'
        //   return parseFloat(valueA) < parseFloat(valueB);
        // },
      },
      {
        headerName: 'Question Label',
        field: 'question_label',
        minWidth: 180,
      },
      {
        headerName: 'Status',
        field: 'status',
        sortable: false,
      },
      {
        headerName: 'Action',
        field: 'action',
        minWidth: 180,
        maxWidth: 180,
        filter: false,
        sortable: false,
        cellRenderer: (row: ICellRendererParams) => {
          return (
            <>
              <IconButton aria-label='edit' size='small'>
                <EditIcon
                  fontSize='inherit'
                  color='primary'
                  onClick={() => {
                    setAction('Edit');
                    dispatch(
                      getAdaptiveAssessmentQuestionById(
                        row.data,
                        setQuestion,
                        setInitialValue,
                        setOpen
                      )
                    );
                  }}
                />
              </IconButton>
              <IconButton aria-label='view' size='small'>
                <RemoveRedEyeIcon
                  fontSize='inherit'
                  color='primary'
                  onClick={() => {
                    setAction('View');
                    dispatch(
                      getAdaptiveAssessmentQuestionById(
                        row.data,
                        setQuestion,
                        setInitialValue,
                        setOpen
                      )
                    );
                  }}
                />
              </IconButton>
              <IconButton aria-label='copy' size='small'>
                <ContentCopyIcon
                  fontSize='inherit'
                  color='primary'
                  onClick={() => {
                    setAction('Copy');
                    dispatch(
                      getAdaptiveAssessmentQuestionById(
                        row.data,
                        setQuestion,
                        setInitialValue,
                        setOpen
                      )
                    );
                  }}
                />
              </IconButton>
              <IconButton aria-label='delete' size='small'>
                <DeleteIcon
                  fontSize='inherit'
                  color='error'
                  onClick={() => {
                    Alert.confirm(() => {
                      dispatch(
                        deleteAdaptiveItem(row?.data?.id, navigate, state)
                      );
                    });
                  }}
                />
              </IconButton>
            </>
          );
        },
      },
      // {
      //   headerName: 'DINA Slip',
      //   field: 'slip',
      // },
      // {
      //   headerName: 'DINA Guess',
      //   field: 'guess',
      // },
    ];
    if (state.value.slip) {
      initial['slip'] = '';
      column.push('slip');
      validation.push({ name: 'slip', type: 'number' });
      dataColumnDef.push({
        headerName: 'DINA Slip',
        field: 'slip',
        minWidth: 140,
      });
    }
    if (state.value.guess) {
      initial['guess'] = '';
      column.push('guess');
      validation.push({ name: 'guess', type: 'number' });
      dataColumnDef.push({
        headerName: 'DINA Guess',
        field: 'guess',
        minWidth: 140,
      });
    }
    // Loop through content_modules and populate initial object and columnDefs
    state?.value?.contentModules?.forEach((module: any) => {
      initial.content_modules[module] = ''; // Set module value in initial object
      column.push(`ContentModules-${module}`);

      // Add column definition for content_modules
      contentModuleColumns.push({
        headerName: module,
        field: `content_modules_obj.${module}`, // Use `content_modules.${moduleName}` as the field
      });

      validation.push({ name: module, type: 'string' });
    });

    // Loop through learning_objectives and populate initial object and columnDefs
    state?.value?.learningObjectives?.forEach((objective: any) => {
      initial.learning_objectives[objective] = ''; // Set objective value in initial object
      column.push(`LearningObjective-${objective}`);

      // Add column definition for learning_objectives
      learningObjectiveColumns.push({
        headerName: objective,
        field: `learning_objectives_obj.${objective}`, // Use `learning_objectives.${objectiveName}` as the field
      });

      validation.push({ name: objective, type: 'string' });
    });

    if (state.value.discrimination) {
      initial['discrimination'] = '';
      column.push('discrimination');
      validation.push({ name: 'discrimination', type: 'number' });
      dataColumnDef.push({
        headerName: 'Discrimination',
        field: 'discrimination',
        minWidth: 150,
      });
    }

    if (state.value.difficulty) {
      initial['difficulty'] = '';
      column.push('difficulty');
      validation.push({ name: 'difficulty', type: 'number' });
      dataColumnDef.push({
        headerName: 'Difficulty',
        field: 'difficulty',
        minWidth: 120,
      });
    }
    if (state.value.guessing) {
      initial['guessing'] = '';
      column.push('guessing');
      validation.push({ name: 'guessing', type: 'number' });
      dataColumnDef.push({
        headerName: 'Guessing',
        field: 'guessing',
        minWidth: 110,
      });
    }
    if (state.value.slipping) {
      initial['slipping'] = '';
      column.push('slipping');
      validation.push({ name: 'slipping', type: 'number' });
      dataColumnDef.push({
        headerName: 'Slipping',
        field: 'slipping',
        minWidth: 140,
      });
    }

    // Create column group objects
    const contentModuleGroup: any = {
      headerName: 'Content Modules', // Name for the group
      children: contentModuleColumns, // Columns in the group
    };
    dataColumnDef.push(contentModuleGroup);

    const learningObjectiveGroup: any = {
      headerName: 'Learning Objectives', // Name for the group
      children: learningObjectiveColumns, // Columns in the group
    };
    dataColumnDef.push(learningObjectiveGroup);

    setColumnName((prev: any) => [...prev, column]);
    setColumnDefs(dataColumnDef);
  }, [state]);

  const [initialValues, setInitialValue] = useState<any>({
    status: '',
    content_modules: {},
    learning_objectives: {},
    content_modules_structure: state?.value?.contentModules,
    learning_objectives_structure: state?.value?.learningObjectives,
    content_modules_obj: {},
    learning_objectives_obj: {},
    questionLabel: '',
    questionContent: '',
  });

  //

  const validation = Yup.object({
    status: Yup.string().required('Required Field'),
    questionLabel: Yup.string()
      .trim('space not allowed on start or end')
      .strict(true)
      .required('Required')
      .notOneOf([''], 'Cannot be empty'),
    questionContent: Yup.string().required('Required Field'),
    // responseOptions: Yup.array().of(
    //   Yup.object().shape({
    //     optionLabel: Yup.string().required('Required'),
    //     optionContent: Yup.string().required('Required Field'),
    //   })
    // ),
    // slip: Yup.string().required('Required Field'),
    // guess: Yup.string().required('Required Field'),
    // discrimination: Yup.number()
    //   .typeError('Must be a number') // Display this error if the value is not a number
    //   .required('Required Field'),
    // .min(-0.9, 'Enter a number in the range from -0.9 to +0.9')
    // .max(0.9, 'Enter a number in the range from -0.9 to +0.9'),
    // .required(),

    // guessing: Yup.number()
    //   .typeError('Must be a number')
    //   .required('Required Field'),
    // .min(0, 'Enter a decimal number starting from 0 to 0.9')
    // .max(0.9, 'Enter a decimal number starting from 0 to 0.9'),
    // difficulty: Yup.number()
    //   .typeError('Must be a number')
    //   .required('Required Field'),
    // .min(0.1, 'Enter a number in the range from 0.1 to 1.4')
    // .max(1.4, 'Enter a number in the range from 0.1 to 1.4')
    // .positive('Enter a positive number')
    // .required(),

    // defaultAnswer: Yup.number()
    //   .typeError('Must be a number')
    //   .test(
    //     'isRequired',
    //     'Please add at least 1 option to select a key value',
    //     function (value) {
    //       const { responseOptions } = this.parent;
    //       return !!value || (responseOptions && responseOptions.length > 0);
    //     }
    //   )
    //   .required('Required Field'),
    contentModules: Yup.object().nullable().shape({}),
    // content_modules_obj: Yup.object().shape(
    //   Object.fromEntries(
    //     state?.value?.contentModules?.map((key: any) => [
    //       key,
    //       Yup.number().required('Required Field'),
    //     ]) || []
    //   )
    // ),
    learningObjectives: Yup.object().nullable().shape({}),
    // learning_objectives_obj: Yup.object().shape(
    //   Object.fromEntries(
    //     state?.value?.learningObjectives?.map((key: any) => [
    //       key,
    //       Yup.number().required('Required Field'),
    //     ]) || []
    //   )
    // ),
  });

  const handleClickClose = () => {
    // Alert.confirm(() => {
    const isSomethingChanged = () => {
      if (
        JSON.stringify(initialValues) !==
        JSON.stringify(formikRef2.current.values)
      ) {
        return true;
      }

      return false;
    };

    if (isSomethingChanged()) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Changes you made may not be saved',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then(async (result: any) => {
        if (result.isConfirmed) {
          setOpen(false);
          setInitialValue({
            status: '',
            slip: '',
            key: '',
            guess: '',
            discrimination: '',
            difficulty: '',
            guessing: '',
            content_modules: {},
            learning_objectives: {},
            content_modules_structure: state?.value?.contentModules,
            learning_objectives_structure: state?.value?.learningObjectives,
            content_modules_obj: {},
            learning_objectives_obj: {},
          });
        }
      });
    } else {
      setOpen(false);
      setInitialValue({
        status: '',
        slip: '',
        key: '',
        guess: '',
        discrimination: '',
        difficulty: '',
        guessing: '',
        content_modules: {},
        learning_objectives: {},
        content_modules_structure: state?.value?.contentModules,
        learning_objectives_structure: state?.value?.learningObjectives,
        content_modules_obj: {},
        learning_objectives_obj: {},
      });
    }
    // });
  };

  const columnChange = (e: any) => {
    const { name, value } = e.target;

    setQuestion((prevVal: any) => {
      const inputdata = { ...prevVal };

      if (
        (name === 'responseType' && value === 'Open') ||
        value === 'Number' ||
        value === 'Text'
      ) {
        if (value === 'Number') {
          inputdata.defaultAnswer = '0';
          inputdata.responseOptions = [
            { optionLabel: '0', optionContent: '0' },
          ];
        }
        if (value === 'Single') {
          inputdata.defaultAnswer = '';
          inputdata.responseOptions = [{ optionLabel: '', optionContent: '' }];
        }
        if (value === 'Multiple') {
          inputdata.defaultAnswer = '';
          inputdata.responseOptions = [{ optionLabel: '', optionContent: '' }];
        } else {
          inputdata.defaultAnswer = '';
          inputdata.responseOptions = [{ optionLabel: '', optionContent: '' }];
        }
      }

      if (
        (name === 'responseType' && value === 'Single') ||
        (name === 'responseType' && value === 'Multiple')
      ) {
        // Clear the default answer for single/multiple choice questions
        inputdata.defaultAnswer = '';

        const arr1 = inputdata.responseOptions.map(
          (option: any, index: number) => ({
            optionLabel: option.optionLabel,
            optionContent: option.optionContent,
            optionType:
              name === 'responseType' &&
              value === 'Single' &&
              option.optionType === 'none'
                ? undefined
                : option.optionType,
          })
        );

        const arr2 = [
          {
            optionLabel: '',
            optionContent: '',
          },
        ];

        // Renumber the options for single/multiple choice questions
        inputdata.responseOptions = arr1.length == 0 ? arr2 : arr1;
      }

      inputdata[name] = value;
      return inputdata;
    });
  };

  const columnOptionChange = (e: any, i: any) => {
    const { name, value } = e.target;

    setQuestion((prevVal: any) => {
      const que = { ...prevVal };
      que.responseOptions[i][name] = value;
      if (que.responseType === 'Number' && name == 'optionLabel') {
        que.defaultAnswer = value;
      } else if (que.responseType === 'Open' || que.responseType === 'Text') {
        que.defaultAnswer = value;
      }
      return que;
    });
  };

  const onCheckboxChange = (e: any) => {
    setQuestion((prevVal: any) => {
      const value: any = { ...prevVal };
      const { name, checked } = e.target;
      //----
      const currentCheckbox = name;
      const notaObj = value?.responseOptions?.filter(
        (option: any) => option?.optionType === 'none'
      )?.[0];
      let notaObjOptionLabel = value?.responseOptions?.findIndex(
        (option: any) => option?.optionType === 'none'
      );
      if (notaObjOptionLabel >= 0) {
        notaObjOptionLabel++;
      }

      if (currentCheckbox == notaObjOptionLabel) {
        const isOtherCheckboxFalse = value?.responseOptions.every(
          (option: any, optionLabel: any) => {
            if (
              optionLabel + 1 !== notaObjOptionLabel &&
              Object.hasOwn(value?.defaultAnswer, optionLabel + 1)
            ) {
              return value?.defaultAnswer[optionLabel + 1] === false;
            } else {
              return true;
            }
          }
        );
        if (isOtherCheckboxFalse) {
          const data = {
            ...value?.defaultAnswer,
            [name]: checked,
          };
          value.defaultAnswer = data;
          return value;
        } else {
          /* current checkbox is "none of the above" & some of others are checked */
          const data = {
            ...value?.defaultAnswer,
          };

          //so make other checkbox as false
          value?.responseOptions.forEach((option: any, optionLabel: any) => {
            if (
              optionLabel + 1 !== notaObjOptionLabel &&
              Object.hasOwn(value?.defaultAnswer, optionLabel + 1)
            ) {
              if (value?.defaultAnswer[optionLabel + 1] === true) {
                data[optionLabel + 1] = false;
              }
            } else {
              /* option is false so logic never came here */
              //return true;
            }
          });

          //make current(none) as true
          data[name] = checked;
          value.defaultAnswer = data;
          return value;

          // Alert.info({
          //   title:
          //     'Either None of the above or other checkboxes will be selected',
          //   text: '',
          // });
          // return prevVal;
        }
      } else {
        if (notaObj) {
          if (
            Object.hasOwn(value?.defaultAnswer, notaObjOptionLabel) &&
            value.defaultAnswer[notaObjOptionLabel] === true
          ) {
            /* current checkbox is other than "none of the above" and "none of the above" is checked */

            //so make "none of the above" checkbox as false & make current(some of other) as true
            const data = {
              ...value?.defaultAnswer,
              [notaObjOptionLabel]: false,
              [name]: checked,
            };
            value.defaultAnswer = data;
            return value;

            // Alert.info({
            //   title:
            //     'Either None of the above or other checkboxes will be selected',
            //   text: '',
            // });
            // return prevVal;
          } else {
            const data = {
              ...value?.defaultAnswer,
              [name]: checked,
            };
            value.defaultAnswer = data;
            return value;
          }
        } else {
          const data = {
            ...value?.defaultAnswer,
            [name]: checked,
          };
          value.defaultAnswer = data;
          return value;
        }
      }
      //----
    });
  };

  const handleRadioChange = (e: any) => {
    setQuestion((prevVal: any) => {
      const value: any = { ...prevVal };
      value.defaultAnswer = e.target.value;
      return value;
    });
  };

  const handleOptionAdd = (setFieldValue: any, type?: any) => {
    if (question.responseOptions) {
      const responseOptions = [...question.responseOptions];
      //---------------------
      if (type && question?.responseType === 'Multiple' && type === 'none') {
        responseOptions.push({
          optionLabel: '',
          optionContent: 'None of the above',
          optionType: 'none',
        });
      } else if (type === 'other') {
        responseOptions.push({
          optionLabel: '',
          optionContent: 'Other',
          optionType: 'other',
        });
      } else {
        //---------------
        responseOptions.push({
          optionLabel: '',
          optionContent: '',
        });
      }
      //---------------
      setFieldValue('responseOptions', responseOptions);
      //-----------------
      setQuestion((prevVal: any) => {
        const que = { ...prevVal };
        if (que?.responseOptions?.length == responseOptions?.length) {
        } else if (
          type &&
          que?.responseType === 'Multiple' &&
          type === 'none'
        ) {
          que.responseOptions.push({
            optionLabel: '',
            optionContent: 'None of the above',
            optionType: 'none',
          });
        } else if (type === 'other') {
          que.responseOptions.push({
            optionLabel: '',
            optionContent: 'Other',
            optionType: 'other',
          });
        } else {
          //---------------
          que.responseOptions.push({
            optionLabel: '',
            optionContent: '',
          });
        }

        return que;
      });
    }
  };

  const handleDeleteOption = (setFieldValue: any) => {
    if (question.responseOptions) {
      const responseOptions = [...question.responseOptions];
      responseOptions.pop();

      setFieldValue('responseOptions', responseOptions);
      setFieldValue('defaultAnswer', '');

      setQuestion((prevVal: any) => {
        const que = { ...prevVal };
        if (que.responseType == 'Single') {
          if (que.defaultAnswer == que.responseOptions.length) {
            que.defaultAnswer = '';
          }
        } else if (que.responseType == 'Multiple') {
          let indexStr = que.responseOptions.length;
          if (que.defaultAnswer.hasOwnProperty(indexStr)) {
            delete que.defaultAnswer[indexStr];
          }
        }
        que.responseOptions = responseOptions;
        return que;
      });
    }
  };

  // const handleSaveQuestion = () => {
  //   const dat = { ...initialValues };
  //   dat.question = question;
  //   // dat.item_no = data.length + 001;
  //   setData((prev: any) => [...prev, dat]);
  //   setOpen(false);
  //
  //
  // };

  const handleSaveQuestion = async () => {
    if (formikRef2.current) {
      formikRef2.current.submitForm();
    }
  };

  const handleFileChange = (e: any) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    const allowedExtensions = ['.csv'];
    const fileName = file.name;
    const fileExtension = fileName.slice(fileName.lastIndexOf('.'));

    if (!allowedExtensions.includes(fileExtension)) {
      Swal.fire('Invalid File', 'Please select a CSV file.', 'error');
      e.target.value = ''; // Clear the file input
      setIsButtonDisabled(true); // Disable the upload button
      return;
    }

    setFile(file);
    setIsButtonDisabled(false); // Enable the upload button
  };

  const handleUploadClick = () => {
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const datam = new Uint8Array(e.target?.result);
      const workbook = read(datam, { type: 'array' });

      if (!workbook || !workbook.SheetNames || !workbook.Sheets) {
        //
        return;
      }

      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];

      if (!worksheet) {
        //
        return;
      }

      const jsonData = utils.sheet_to_json(worksheet, {
        header: 1,
        raw: false,
      });

      // Add your validation logic here to check if the required fields are present

      const [headerRow, ...dataRows]: any = jsonData;

      const isEmptyFile = dataRows
        .filter((d: any) => d.length > 0)
        .some((d: any) => {
          if (Array.isArray(d) && d.length > 0) {
            return !d.some(Boolean);
          } else {
            return !d.some(Boolean);
          }
        });

      if (isEmptyFile) {
        Swal.fire({
          icon: 'info',
          title: 'Empty file',
        });

        return;
      }

      const jsonObject = dataRows
        .filter((d: any) => d.length > 0)
        .map((row: any, i: any) => {
          const obj: any = {};
          headerRow.forEach((key: any, index: any) => {
            obj[key] = Boolean(row[index]) ? row[index] : '';
          });

          // Collect learning_objectives and content_modules keys
          const learningObjectiveKeys = Object.keys(obj).filter((key) =>
            key.startsWith('learning_objectives-')
          );
          const contentModuleKeys = Object.keys(obj).filter((key) =>
            key.startsWith('content_modules-')
          );

          const learningObjectivesArr: any = [];
          learningObjectiveKeys.map((key) => {
            learningObjectivesArr.push({
              [key.replace(/^learning_objectives-/, '')]: obj[key],
            });
            delete obj[key];
          });

          const contentModulesArr: any = [];
          contentModuleKeys.map((key) => {
            contentModulesArr.push({
              [key.replace(/^content_modules-/, '')]: obj[key],
            });
            delete obj[key];
          });

          const responseOptions = [];
          const totalResponseOptions = Object.keys(obj).filter((key) =>
            key.startsWith('responseOptions-')
          ).length;

          // Loop through the data object and find responseOptions
          for (let i = 1; i <= totalResponseOptions; i++) {
            const labelKey: any = `responseOptions-${i}-label`;
            const contentKey: any = `responseOptions-${i}-content`;
            const label = obj[labelKey];
            const content = obj[contentKey];

            if (label && content) {
              responseOptions.push({
                optionLabel: label,
                optionContent: content,
              });
              delete obj[`responseOptions-${i}-label`];
              delete obj[`responseOptions-${i}-content`];
            } else {
              delete obj[`responseOptions-${i}-label`];
              delete obj[`responseOptions-${i}-content`];
            }
          }

          const objF = data.find((o: any) => o.item_id == obj.item_id);
          // Combine everything into the final object
          const finalObject = {
            ...obj,
            learning_objectives: learningObjectivesArr,
            content_modules: contentModulesArr,
            question: objF?.question
              ? objF.question
              : {
                  questionLabel: obj?.question_label?.trim(),
                  questionContent: '',
                  responseType: 'Single',
                  optionOrientation: 'Vertical',
                  answer: '',
                  defaultAnswer: '',
                  responseOptions: [],
                },
          };

          delete finalObject.question_label;
          return finalObject;
        });

      const requiredContentModuleColumns = state.value.contentModules.map(
        (d: any) => `content_modules-${d}`
      );
      const requiredLearningObjColumns = state.value.learningObjectives.map(
        (d: any) => `learning_objectives-${d}`
      );

      // Add your validation logic here to check if the required fields are present
      const requiredFieldsInSheet = [
        'item_id',
        'status',
        'question',
        'discrimination',
        'difficulty',
        'guessing',
        ...requiredContentModuleColumns,
        ...requiredLearningObjColumns,
        // Add other field names that are required here
      ];

      // if (state.value.discrimination) {
      //   requiredFields.push('discrimination');
      // }

      // if (state.value.difficulty) {
      //   requiredFields.push('difficulty');
      // }
      // if (state.value.guessing) {
      //   requiredFields.push('guessing');
      // }
      // if (state.value.slipping) {
      //   requiredFields.push('slipping');
      // }

      const missingFields = jsonObject.filter((row: any) => {
        // const row = [
        //   learning_obj: [
        //    {
        //     "check for pra": "1",
        //    }
        //   ],
        //   defaultValue: '3'
        // ]
        const columnsInSheet = Object.keys(row).map((column: any) => {
          //column = 'learning_objective / defaultValue ]'

          if (
            column === 'content_modules' ||
            column === 'learning_objectives'
          ) {
            // row[column] = [
            //   {
            //    "check for pra": "1",
            //   }
            // ]
            const contOrLearKeysForSheet = row[column].map(
              (eachContentModule: any) => {
                // eachContentModule =   {
                //   "check for pra": "1",
                // }
                const [contOrLearkey] = Object.keys(eachContentModule); // ["check for pra"]

                if (column === 'content_modules') {
                  return `content_modules-${contOrLearkey}`;
                } else {
                  return `learning_objectives-${contOrLearkey}`;
                }
              }
            );
            return contOrLearKeysForSheet; //contOrLearKeysForSheet = [learning_objectives-check for pra, content_modules-check for pra]
          } else {
            return column; //column = defaultValue
          }
        }); //columnsInSheet = [defaultValue, [learning_objectives-check for pra, content_modules-check for pra]]
        const crtColumnsInSheet = columnsInSheet.flat();

        //returns true when any of the required column is not included on columnsInSheet
        const isMissingField = requiredFieldsInSheet.some((reqField: any) => {
          if (!crtColumnsInSheet.includes(reqField)) {
            return true;
          } else {
            return false;
          }
        });

        return isMissingField;
      });

      if (missingFields.length > 0) {
        Swal.fire({
          icon: 'error',
          title: 'Missing Fields',
          text: 'some fields are missing from the sheet',
        });

        return;
      }

      const verifySheet = Yup.object({
        question: Yup.object({
          questionLabel: Yup.string().required('question_label is required'),
        }),
        status: Yup.string()
          //.required('status column is required')
          .oneOf(
            ['A', 'T', 'I', 'R'],
            'status column must be one of A, T, I, R'
          ),
        ...(state?.value?.slip && {
          slip: Yup.string()
            //.required('slip column is required')
            .test(
              'is-number',
              'slip column must be a number or "NA"',
              (value: any) => {
                // Allow "NA" as a valid value
                if (value === 'NA') {
                  return true;
                }
                return isNaN(parseFloat(value)) ? false : true;
              }
            ),
        }),

        ...(state?.value?.guess && {
          guess: Yup.string().test(
            'is-number',
            'guess column must be a number or "NA"',
            (value: any) => {
              // Allow "NA" as a valid value
              if (value === 'NA') {
                return true;
              }
              return isNaN(parseFloat(value)) ? false : true;
            }
          ),
        }),

        discrimination: Yup.string()
          //.required('discrimination column is required')
          // .matches(
          //   /^-?([0-4](\.\d*)?|5(\.0*)?)$/,
          //   'discrimination column range: -5 to +5'
          // ),
          .test(
            'is-number',
            'discrimination column must be a number or "NA"',
            (value: any) => {
              // Allow "NA" as a valid value
              if (value === 'NA') {
                return true;
              }
              return isNaN(parseFloat(value)) ? false : true;
            }
          ),

        guessing: Yup.string()
          //.required('guessing column is required')
          // .matches(
          //   /^0(\.\d+)?$|^0\.[0-9]+$/,
          //   'guessing column range: 0 to 1(exclusive)'
          // ),
          .test(
            'is-number',
            'guessing column must be a number or "NA"',
            (value: any) => {
              // Allow "NA" as a valid value
              if (value === 'NA') {
                return true;
              }
              return isNaN(parseFloat(value)) ? false : true;
            }
          ),

        difficulty: Yup.string()
          //.required('difficulty column is required')
          // .matches(/^[0-4](\.\d+)?$/, 'difficulty column range: 0 to 4'),
          .test(
            'is-number',
            'difficulty column must be a number or "NA"',
            (value: any) => {
              // Allow "NA" as a valid value
              if (value === 'NA') {
                return true;
              }
              return isNaN(parseFloat(value)) ? false : true;
            }
          ),

        content_modules: Yup.array().of(
          Yup.object().shape({
            ...state.value.contentModules.reduce((res: any, cv: any) => {
              res[cv] = Yup.string()
                //.required('content modules column is required')
                .oneOf(
                  ['0', '1', ''],
                  'content modules column must be either 0 or 1 or empty value'
                );

              return res;
            }, {}),
          })
        ),

        learning_objectives: Yup.array().of(
          Yup.object().shape({
            ...state.value.learningObjectives.reduce((res: any, cv: any) => {
              res[cv] = Yup.string()
                //.required('learning objectives column is required')
                .oneOf(
                  ['0', '1', ''],
                  'learning objectives column must be either 0 or 1 or empty value'
                );

              return res;
            }, {}),
            //value: Yup.string().oneOf(['0', '1']).required('learning objectives column'),
          })
        ),
      });

      const validationOption = {
        abortEarly: false,

        //stripUnknown: true
        // // when `false` validations will be performed shallowly
        // recursive: boolean = true;
        // // External values that can be provided to validations and conditionals
        // context?: object;
      };
      async function validateEach(obj: any) {
        const data: any = await verifySheet.validate(obj, validationOption);
        return data;
      }

      const jsonObj = jsonObject.map(validateEach);

      Promise.all(jsonObj)
        .then((value: any) => {
          // const crtValue: any = value.map((t: any) => ({
          //   ...t,
          //   //question: {},
          // }));
          setIsUploading(true);
          dispatch(
            fileUpload(
              { data: value, id: state.value.id },
              navigate,
              setData,
              setIsUploading
            )
          );
        })
        .catch((err: any) => {
          // ${err.errors.map((text: any) => `<li><b>${text}</b></li>`).join('\n')}

          const errorList = `
          <ol class='list-decimal pl-16 p-2 pb-10 border-2 border-red-500 rounded-md'>
          ${[...new Set(err.errors)]
            .map(
              (text: any, i: number) =>
                `<li class='pl-1 italic text-left text-base'>${text}</li>`
            )
            .join('\n')}
        </ol>
      `;
          Swal.fire({
            icon: 'error',
            // text:  [...new Set(err.errors)].join(', ').concat(' has some invalid data'),
            html: errorList,
          });
        });
    };

    reader.readAsArrayBuffer(file);
  };

  const downloadCSV = () => {
    const workbook = readxlsx.utils.book_new();
    let csvData: any = [],
      sheet1: any;

    if (data.length === 0) {
      const newObj: any = {
        // [`item_code`]: '',
        ['item_id']: '',
        ['status']: '',
        ['question_label']: '',
        // ['questionContent']: '',
        // ['responseType']: '',
        // ['optionOrientation']: '',
        // ['defaultAnswer']: '',
        ['slip']: '',
        ['guess']: '',
        ['difficulty']: '',
        ['discrimination']: '',
        ['guessing']: '',
        // ['responseOptions-1-label']: '',
        // ['responseOptions-1-content']: '',

        ...state.value.contentModules.reduce((result: any, current: any) => {
          result[`content_modules-${current}`] = '';
          return result;
        }, {}),
        ...state.value.learningObjectives.reduce(
          (result: any, current: any) => {
            result[`learning_objectives-${current}`] = '';
            return result;
          },
          {}
        ),

        // state.value.learningObjectives: '',
        // state.value.contentModules: ''
        // ...obj.learning_objectives_obj,
        // ...obj.content_modules_obj
      };
      csvData.push(newObj);
    } else {
      csvData = data.map((obj: any) => {
        const newObj: any = {
          // [`item_code`]: Boolean(obj.item_code)
          //   ? obj.item_code
          //   : state?.value.assessmentCode,
          ['item_id']: obj.item_id,
          ['status']: obj.status,
          ['question_label']: obj?.question_label,
          //['questionContent']: obj.question.questionContent,
          //['responseType']: obj.question.responseType,
          // ['optionOrientation']: obj.question.optionOrientation,
          // ['defaultAnswer']: obj.question.defaultAnswer,
          ['slip']: obj.slip ? obj.slip : 'NA',
          ['guess']: obj.guess ? obj.guess : 'NA',
          ['difficulty']: obj.difficulty ? obj.difficulty : 'NA',
          ['discrimination']: obj.discrimination ? obj.discrimination : 'NA',
          ['guessing']: obj.guessing ? obj.guessing : 'NA',

          // ...obj.question.responseOptions.reduce(
          //   (result: any, current: any, index: any) => {
          //     result[`responseOptions-${index + 1}-label`] =
          //       current.optionLabel;
          //     result[`responseOptions-${index + 1}-content`] =
          //       current.optionContent;
          //     return result;
          //   },
          //   {}
          // ),

          ...state.value.contentModules.reduce((result: any, current: any) => {
            result[`content_modules-${current}`] =
              obj.content_modules_obj?.[current] ?? '';
            return result;
          }, {}),
          ...state.value.learningObjectives.reduce(
            (result: any, current: any) => {
              result[`learning_objectives-${current}`] =
                obj.learning_objectives_obj?.[current] ?? '';
              return result;
            },
            {}
          ),

          // ['responseOptions-1-label']:,
          // ['responseOptions-1-content']:,

          // responseOptions-2-label
          // responseOptions-2-content
          // responseOptions-3-label
          // responseOptions-3-content
          // responseOptions-4-label
          // responseOptions-4-content
          // responseOptions-5-label
          // responseOptions-5-content

          // content_modules-Kinematics
          // content_modules-Forces
          // content_modules-Energy
          // content_modules-Momentum
          // content_modules-Rotation
          // content_modules-Mathematics
          // learning_objectives-Apply_vectors
          // learning_objectives-Conceptual_relationships
          // learning_objectives-Algebraic_manipulation
          // learning_objectives-Visualizations
        };

        return newObj;
      });
    }

    sheet1 = readxlsx.utils.json_to_sheet(csvData);
    readxlsx.utils.book_append_sheet(workbook, sheet1, 'Exported data');

    // write workbook data to file and download
    const excelBuffer = readxlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const blob = new Blob([excelBuffer], {
      type: 'application/octet-stream',
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'Exported Data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const contentModulesArray = state?.value?.contentModules || [];
  const learningObjectivesArray = state?.value?.learningObjectives || [];

  const handleSubmit = () => {
    if (formikRef.current) {
      //@ts-ignore
      formikRef.current.submitForm();
    }
  };

  const [openForm, setOpenForm] = useState<any>({
    isOpen: false,
    callback: null,
  });

  const transformData = (currentData: any) => {
    //values = transformedData
    const transformedData = { ...currentData };

    const {
      questionLabel,
      questionContent,
      responseOptions,
      defaultAnswer: originalDefaultAnswer,
      ...rest
    } = transformedData;

    transformedData.question = question;

    delete transformedData.questionLabel;
    delete transformedData.questionContent;
    delete transformedData.responseOptions;
    delete transformedData.defaultAnswer;

    return transformedData;
  };

  const currentData = {};

  const transformedData = transformData(currentData);
  //

  const fontSizeOptions = [
    8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 22, 24, 26, 28, 36, 48,
    72,
  ];

  const mathFontSizeOptions = [
    { text: '0.4', value: '0.4em' },
    { text: '0.5', value: '0.5em' },
    { text: '0.6', value: '0.6em' },
    { text: '0.7', value: '0.7em' },
    { text: '0.8', value: '0.8em' },
    { text: '0.9', value: '0.9em' },
    { text: '1', value: '1em', default: true }, // Set default font size
    { text: '1.1', value: '1.1em' },
    { text: '1.2', value: '1.2em' },
    { text: '1.3', value: '1.3em' },
    { text: '1.4', value: '1.4em' },
    { text: '1.5', value: '1.5em' },
    { text: '1.6', value: '1.6em' },
    { text: '1.7', value: '1.7em' },
    { text: '1.8', value: '1.8em' },
    { text: '1.9', value: '1.9em' },
    { text: '2', value: '2em' },
    { text: '2.1', value: '2.1em' },
    { text: '2.2', value: '2.2em' },
    { text: '2.3', value: '2.3em' },
    { text: '2.4', value: '2.4em' },
    { text: '2.5', value: '2.5em' },
    { text: '2.6', value: '2.6em' },
    { text: '2.7', value: '2.7em' },
    { text: '2.8', value: '2.8em' },
    { text: '2.9', value: '2.9em' },
    { text: '3', value: '3em' },
    { text: '3.1', value: '3.1em' },
    { text: '3.2', value: '3.2em' },
    { text: '3.3', value: '3.3em' },
    { text: '3.4', value: '3.4em' },
    { text: '3.5', value: '3.5em' },
  ];

  const handleImageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void,
    clearImage: (setFieldValue: any) => void
  ) => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      clearImage(setFieldValue);
      return;
    }

    const file = files[0];
    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setFieldValue('reference_image', base64String);
      };
      reader.readAsDataURL(file);
    } else {
      clearImage(setFieldValue);
      Swal.fire('Invalid file type', 'Please choose an image file.', 'error');
    }
  };

  const clearImage = (setFieldValue: any) => {
    setFieldValue('reference_image', ''); // Clear reference_image
  };
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };
  //--------------AdaptiveTime---------------------
  const handleEditContentModule = (
    fieldName: string,
    index: any,
    values: any,
    setFieldValue: any,
    setFieldError: any,
    setFieldTouched: any
  ) => {
    const fieldType = values[`${fieldName}`];
    const contentModule = Array.isArray(fieldType) ? fieldType.slice() : [];
    const selectedContentModule = contentModule[index];

    if (selectedContentModule) {
      const { value = '', time = '' } = selectedContentModule;
      if (values[`${fieldName}Value`]) {
        const obj = {
          value: values[`${fieldName}Value`],
          time: values[`${fieldName}Time`],
          descript: values[`${fieldName}Descript`],
        };
        contentModule.push(obj);
      }

      // Remove the selected item from the contentModule list
      contentModule.splice(index, 1);
      setFieldValue(fieldName, contentModule);
      // Populate form fields with selected item values
      setFieldValue(`${fieldName}Value`, value || ''); // Ensure value is not undefined
      setFieldValue(`${fieldName}Time`, time || ''); // Ensure time is not undefined
      setFieldValue(
        `${fieldName}Descript`,
        selectedContentModule.descript || ''
      );

      // Clear field-level validation errors
      const newErrors = { ...values.errors };
      delete newErrors.value;
      delete newErrors.time;
      setFieldError(`${fieldName}Value`, ''); // Clear validation error for 'value' field
      setFieldError(`${fieldName}Time`, ''); // Clear validation error for 'time' field

      // Reset the touched state for the fields being edited
      setFieldTouched(`${fieldName}Value`, false);
      setFieldTouched(`${fieldName}Time`, false);
      setFieldTouched(`${fieldName}Descript`, false);
    }
  };

  const handleDeleteContentModule = (
    fieldName: string,
    index: any,
    values: any,
    setFieldValue: any
  ) => {
    const fieldType = values[`${fieldName}`];
    const updatedContentModule = [...fieldType];
    updatedContentModule.splice(index, 1);
    setFieldValue(fieldName, updatedContentModule);
  };

  return (
    <main>
      <Formik
        innerRef={formikRef}
        initialValues={formData}
        // enableReinitialize
        // validationSchema={validationSchema}
        // validationSchema={adaptive_validation}
        onSubmit={(values) => {
          values.assessSpecQuestion = assessSpecQuestion;
          setUpdateAdaptiveLoading(true);

          setAdaptiveValueError({
            ...adaptiveValueError,
            disciplineError: values.discipline.id ? '' : 'Required Field',
            publicAssessmentNameError:
              values.publicAssessmentName.trim() === '' ? 'Required Field' : '',
            officialAssessmentNameError:
              values.officialAssessmentName.trim() === ''
                ? 'Required Field'
                : '',
            assessmentCodeError:
              values.assessmentCode.trim() === '' ? 'Required Field' : '',
            publicCodeError:
              values.publicCode.trim() === '' ? 'Required Field' : '',

            rFileError: values.rFile.trim() === '' ? 'Required Field' : '',

            // irtError:
            //   !values.discrimination &&
            //   !values.difficulty &&
            //   !values.guessing
            //     ? 'At least select one option in irt model'
            //     : '',
            // contentModulesError:
            //   values.contentModules.length <= 0
            //     ? 'Content Modules should not be empty'
            //     : '',
            // learningObjectivesError:
            //   values.learningObjectives.length <= 0
            //     ? 'Learning Objectives should not be empty'
            //     : '',
          });

          if (
            (values.discipline.id &&
              values.publicAssessmentName.trim() &&
              values.officialAssessmentName.trim() &&
              values.assessmentCode.trim() &&
              values.publicCode.trim(),
            values.rFile.trim())
            // &&
            // values.contentModules.length > 0 &&
            // values.contentModules.length > 0 &&
            // (values.discrimination || values.difficulty || values.guessing)
          ) {
            if (saveAndPublish) {
              values.adaptivePublished = true;
            } else {
              values.adaptivePublished = false;
            }
            dispatch(
              editAdaptiveAssessment(values, navigate, setUpdateAdaptiveLoading)
            );
            //  setUpdateAdaptiveLoading(false);
          } else {
          }
          // setUpdateAdaptiveLoading(false);
          // const [adaptiveValueError, setAdaptiveValueError] = useState({
          //   disciplineError: '',
          //   officialAssessmentNameError: '',
          //   publicAssessmentNameError: '',
          //   assessmentCodeError: '',
          //   publicCodeError: '',
          //   irtError: '',
          //   contentModulesError: '',
          //   learningObjectivesError: '',
          // });

          // if (values?.adaptive === 'true') {
          // navigate('/assessment/adaptive', {
          //   state: {
          //     value: values,
          //     action: 'Create',
          //   },
          // });
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldError,
          setFieldTouched,
        }) => (
          <Form style={{ margin: '20px' }}>
            <Paper elevation={3} style={{ margin: '16px' }}>
              <Grid container spacing={2} style={{ padding: '21px' }}>
                <Grid item xs={3} sm={4} style={{ marginTop: '3px' }}>
                  <InputLabel>Discipline:</InputLabel>
                  <Field
                    name='discipline'
                    as={Autocomplete}
                    value={values?.discipline}
                    size='small'
                    options={disciplineData}
                    getOptionLabel={(option: any) => option?.name || ''}
                    isOptionEqualToValue={(option: any, current: any) =>
                      option.id === current.id
                    }
                    fullWidth
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    onChange={(
                      event: React.SyntheticEvent,
                      discipline: any
                    ) => {
                      if (discipline?.name == 'ADD NEW DISCIPLINE') {
                        setOpenForm({
                          ...openForm,
                          ...{ isOpen: true, callback: setFieldValue },
                        });
                        setFieldValue('discipline', {
                          name: '',
                          id: '',
                        });
                      } else {
                        setFieldValue('discipline', {
                          name: discipline?.name,
                          id: discipline?.id,
                        });
                        setAdaptiveValueError({
                          ...adaptiveValueError,
                          disciplineError: '',
                        });
                      }
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        variant='standard'
                        placeholder='Select'
                        error={
                          adaptiveValueError.disciplineError ? true : false
                        }
                        helperText={
                          adaptiveValueError.disciplineError
                            ? 'Required Field'
                            : ''
                        }
                        required
                        autoComplete='off' // Add this line
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3} sm={4}>
                  <InputLabel>Official Assessment Name:</InputLabel>
                  <Field
                    as={TextField}
                    variant='standard'
                    required
                    name='officialAssessmentName'
                    value={values.officialAssessmentName}
                    helperText={
                      adaptiveValueError.officialAssessmentNameError
                        ? adaptiveValueError.officialAssessmentNameError
                        : ''
                    }
                    fullWidth
                    error={adaptiveValueError.officialAssessmentNameError}
                    sx={{ marginBottom: '15px' }}
                  />
                </Grid>

                <Grid item xs={3} sm={4}>
                  <InputLabel>Public View Assessment Name:</InputLabel>
                  <Field
                    variant='standard'
                    as={TextField}
                    required
                    name='publicAssessmentName'
                    value={values.publicAssessmentName}
                    helperText={
                      adaptiveValueError.publicAssessmentNameError
                        ? adaptiveValueError.publicAssessmentNameError
                        : ''
                    }
                    fullWidth
                    error={adaptiveValueError.publicAssessmentNameError}
                    sx={{ marginBottom: '15px' }}
                  />
                </Grid>

                <Grid item xs={3} sm={4}>
                  <InputLabel>Assessment Code:</InputLabel>
                  <Field
                    variant='standard'
                    as={TextField}
                    required
                    name='assessmentCode'
                    value={values.assessmentCode}
                    helperText={
                      adaptiveValueError.assessmentCodeError
                        ? adaptiveValueError.assessmentCodeError
                        : ''
                    }
                    fullWidth
                    error={adaptiveValueError.assessmentCodeError}
                    sx={{ marginBottom: '15px' }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <InputLabel>Public Code :</InputLabel>
                  <Field
                    as={TextField}
                    variant='standard'
                    required
                    name='publicCode'
                    value={values.publicCode}
                    helperText={
                      adaptiveValueError.publicCodeError
                        ? adaptiveValueError.publicCodeError
                        : ''
                    }
                    fullWidth
                    error={adaptiveValueError.publicCodeError}
                    sx={{ marginBottom: '15px' }}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ justifyContent: 'space-around' }}>
                <Grid
                  item
                  xs={2.5}
                  style={{ paddingTop: '30px' }}
                  justifyContent='center'
                  className='bg-slate-50'
                >
                  <div style={{ marginLeft: '20px', marginBottom: '10px' }}>
                    IRT model options
                  </div>
                  <Grid
                    item
                    style={{ marginLeft: '20px', marginBottom: '10px' }}
                  >
                    <label className='cursor-pointer'>
                      <Field
                        as={Checkbox}
                        sx={{ marginLeft: '-10px' }}
                        name='difficulty'
                        checked={values?.difficulty}
                        // onChange={(event: any) => {
                        //   const isChecked = event.target.checked;

                        //   setFieldValue('difficulty', isChecked);
                        // }}
                        value={values?.difficulty}
                        error={errors.checkboxes && touched.checkboxes}
                      />
                      <span className='ml-1'>Difficulty</span>
                    </label>
                  </Grid>
                  <Grid
                    item
                    style={{ marginLeft: '20px', marginBottom: '10px' }}
                  >
                    <label className='cursor-pointer'>
                      <Field
                        as={Checkbox}
                        sx={{ marginLeft: '-10px' }}
                        name='discrimination'
                        checked={values?.discrimination}
                        // onChange={(event: any) => {
                        //   const isChecked = event.target.checked;

                        //   setFieldValue('discrimination', isChecked);
                        // }}
                        error={errors.checkboxes && touched.checkboxes}
                      />
                      <span className='ml-1'>Discrimination</span>
                    </label>
                  </Grid>
                  <Grid
                    item
                    style={{ marginLeft: '20px', marginBottom: '10px' }}
                  >
                    <label className='cursor-pointer'>
                      <Field
                        as={Checkbox}
                        sx={{ marginLeft: '-10px' }}
                        name='guessing'
                        checked={values?.guessing}
                        onChange={(event: any) => {
                          const isChecked = event.target.checked;

                          setFieldValue('guessing', isChecked);
                        }}
                        error={errors.checkboxes && touched.checkboxes}
                      />
                      <span className='ml-1'>Guessing</span>
                    </label>
                  </Grid>

                  <div style={{ marginLeft: '20px', marginBottom: '10px' }}>
                    DINA{' '}
                  </div>
                  <Grid
                    item
                    style={{ marginLeft: '20px', marginBottom: '10px' }}
                  >
                    <label className='cursor-pointer'>
                      <Field
                        as={Checkbox}
                        sx={{ marginLeft: '-10px' }}
                        name='slip'
                        checked={values?.slip}
                        onChange={(event: any) => {
                          const isChecked = event.target.checked;

                          setFieldValue('slip', isChecked);
                        }}
                        error={errors.checkboxes && touched.checkboxes}
                      />
                      <span className='ml-1'>Slip </span>
                    </label>
                  </Grid>
                  <Grid
                    item
                    style={{ marginLeft: '20px', marginBottom: '10px' }}
                  >
                    <label className='cursor-pointer'>
                      <Field
                        as={Checkbox}
                        sx={{ marginLeft: '-10px' }}
                        name='guess'
                        checked={values?.guess}
                        onChange={(event: any) => {
                          const isChecked = event.target.checked;

                          setFieldValue('guess', isChecked);
                        }}
                        error={errors.checkboxes && touched.checkboxes}
                      />
                      <span className='ml-1'>Guess</span>
                    </label>
                  </Grid>

                  <FormControl
                    error={Boolean(errors?.status && touched?.status)}
                    fullWidth
                  >
                    <InputLabel id='demo-simple-select-standard-label'>
                      Select R file
                    </InputLabel>
                    <Field
                      name='rFile'
                      label='R File'
                      as={Select}
                      value={values.rFile}
                      // onChange={(e: any) => {
                      //   setFieldValue('rFile', e.target.value);
                      // }}
                      fullWidth
                    >
                      {rFilesData?.files?.map((option: any, index: any) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Field>
                    <ErrorMessage
                      name='status'
                      component='div'
                      className='text-red-600 ml-2 mt-2 text-xs'
                    />
                  </FormControl>
                  <Grid
                    item
                    style={{ marginLeft: '20px', marginBottom: '10px' }}
                  >
                    <label className='cursor-pointer'>
                      <Field
                        as={Checkbox}
                        sx={{ marginLeft: '-10px' }}
                        name='under_development'
                        checked={values?.under_development}
                        onChange={(event: any) => {
                          const isChecked = event.target.checked;

                          setFieldValue('under_development', isChecked);
                        }}
                        error={errors.checkboxes && touched.checkboxes}
                      />
                      <span className='ml-1'>Under Development</span>
                    </label>
                  </Grid>

                  <Box
                    style={{
                      padding: '20px',
                      maxWidth: '400px',
                      margin: '0 auto',
                      backgroundColor: '#f9f9f9',
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    }}
                  >
                    <input
                      type='file'
                      accept='image/*'
                      style={{ display: 'none' }}
                      ref={fileInputRef}
                      onChange={(e) =>
                        handleImageChange(e, setFieldValue, clearImage)
                      }
                      onClick={(e) =>
                        ((e.target as HTMLInputElement).value = '')
                      }
                    />
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={handleButtonClick}
                      style={{ marginBottom: '20px', width: '100%' }}
                    >
                      Choose Image
                    </Button>
                    {values.reference_image && (
                      <Box
                        style={{
                          position: 'relative',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          marginBottom: '20px',
                          backgroundColor: '#fff',
                          padding: '10px',
                          borderRadius: '8px',
                        }}
                      >
                        <img
                          style={{
                            width: '100px',
                            height: '100px',
                            borderRadius: '8px',
                            marginBottom: '10px',
                            objectFit: 'cover',
                          }}
                          src={values.reference_image}
                          alt='Uploaded'
                        />
                        <IconButton
                          style={{
                            position: 'absolute',
                            top: 5,
                            right: 5,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                          }}
                          onClick={() => clearImage(setFieldValue)}
                        >
                          <CancelRounded />
                        </IconButton>
                        <Field
                          as={TextField}
                          value={values.image_description}
                          variant='outlined'
                          size='small'
                          label='Image Description'
                          name='image_description'
                          fullWidth
                          style={{ marginTop: '10px' }}
                        />
                      </Box>
                    )}
                  </Box>

                  {/* <Grid
                        item
                        style={{ marginLeft: '20px', marginBottom: '10px' }}
                      >
                        <Field
                          as={Checkbox}
                          sx={{ paddingLeft: '0px' }}
                          name="slipping"
                          checked={values?.slipping}
                          onChange={(event: any) => {
                            const isChecked = event.target.checked;

                            setFieldValue('slipping', isChecked);
                          }}
                          error={errors.checkboxes && touched.checkboxes}
                        />
                        <span className="ml-1">Slipping</span>
                     
                      </Grid> */}
                  <Grid
                    item
                    // xs={5}
                    // style={{
                    //   display: 'flex',
                    //   justifyContent: 'center',
                    // }}
                  >
                    {adaptiveValueError.irtError && (
                      <FormHelperText
                        error={adaptiveValueError.irtError ? true : false}
                      >
                        <>At least select one IRT</>
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
                <>
                  {/* <Grid item xs={3} sm={3}>
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">
                      {!values.contentModule && <>Content Modules</>}
                    </InputLabel>
                    <Field
                      as={Input}
                      name="contentModule"
                      placeholder="Content Modules"
                      id="standard-adornment-password"
                      type="text"
                      helperText={
                        adaptiveValueError.contentModulesError
                          ? adaptiveValueError.contentModulesError
                          : ''
                      }
                      fullWidth
                      error={adaptiveValueError.contentModulesError}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              var inValid = /\s/;
                              var k = inValid.test(values.contentModule);
                              if (k) {
                                return Alert.info({
                                  title:
                                    'Content modules should not be allowed space.',
                                  text: '',
                                });
                              }
                              if (
                                !values.contentModule ||
                                !String(values.contentModule).trim()
                              ) {
                                return Alert.info({
                                  title: 'Content modules should not be empty',
                                  text: '',
                                });
                              }

                              if (values?.contentModules?.length > 0) {
                                if (
                                  values?.contentModules.find(
                                    (e: any) => e === values.contentModule
                                  )
                                ) {
                                  return Alert.info({
                                    title: 'Content Module already exists',
                                    text: '',
                                  });
                                }
                                setFieldValue('contentModules', [
                                  ...values.contentModules,
                                  values.contentModule,
                                ]);
                              } else {
                                values.contentModules = [];
                                setFieldValue('contentModules', [
                                  values.contentModule,
                                ]);
                              }
                              setEditingContentLearning({
                                ...editingContentLearning,
                                contentModule: '',
                              });
                              setFieldValue('contentModule', '');
                              setAdaptiveValueError({
                                ...adaptiveValueError,
                                contentModulesError: '',
                              });
                            }}
                          >
                            <AddCircleOutlineRoundedIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />

                    {adaptiveValueError.contentModulesError && (
                      <FormHelperText
                        error={
                          adaptiveValueError.contentModulesError ? true : false
                        }
                      >
                        <>{adaptiveValueError.contentModulesError}</>
                      </FormHelperText>
                    )}
                  </FormControl>
                  <List>
                    {values?.contentModules
                      ? values.contentModules.map((option: any, index: any) => (
                          <ListItem key={index}>
                            <ListItemText
                              primary={option}
                              //sx={{}}
                              primaryTypographyProps={{
                                style: {
                                  whiteSpace: 'normal',
                                  wordWrap: 'break-word',
                                },
                              }}
                            />
                            <div>
                              <IconButton>
                                <EditIcon
                                  fontSize="small"
                                  color="primary"
                                  onClick={() => {
                                    let editContentModules =
                                      values.contentModules.find(
                                        (item: string) =>
                                          item === values.contentModules[index]
                                      );

                                    setFieldValue(
                                      'contentModule',
                                      editContentModules
                                    );
                                    let removeContentModules =
                                      values.contentModules.filter(
                                        (item: string) =>
                                          item !== values.contentModules[index]
                                      );
                                    if (editingContentLearning.contentModule) {
                                      removeContentModules.push(
                                        editingContentLearning.contentModule
                                      );
                                    }
                                    setEditingContentLearning({
                                      ...editingContentLearning,
                                      contentModule: editContentModules,
                                    });
                                    setFieldValue(
                                      'contentModules',
                                      removeContentModules
                                    );
                                  }}
                                />
                              </IconButton>
                              <IconButton>
                                <DeleteIcon
                                  fontSize="small"
                                  color="error"
                                  onClick={() => {
                                    let removeContentModules =
                                      values.contentModules.filter(
                                        (item: string) =>
                                          item !== values.contentModules[index]
                                      );

                                    setFieldValue(
                                      'contentModules',
                                      removeContentModules
                                    );
                                  }}
                                />
                              </IconButton>
                            </div>
                          </ListItem>
                        ))
                      : ''}
                  </List>
                  <ErrorMessage
                    name="newPassword"
                    component="span"
                    className="text-red-600 ml-2 text-sm"
                  />
                </Grid> */}

                  {/* <Grid item xs={3} sm={3}>
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <InputLabel htmlFor="label-learningObjective">
                      {!values.learningObjective && 'Learning Objectives'}
                    </InputLabel>
                    <Field
                      as={Input}
                      name="learningObjective"
                      placeholder="Learning Objective"
                      id="label-learningObjective"
                      labelId="label-learningObjective"
                      type="text"
                      helperText={
                        adaptiveValueError.learningObjectivesError
                          ? adaptiveValueError.learningObjectivesError
                          : ''
                      }
                      fullWidth
                      error={adaptiveValueError.learningObjectivesError}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              var inValid = /\s/;
                              var k = inValid.test(values.learningObjective);
                              if (k) {
                                return Alert.info({
                                  title:
                                    'Learning Objective should not be allowed space.',
                                  text: '',
                                });
                              }
                              if (
                                !values.learningObjective ||
                                !String(values.learningObjective).trim()
                              ) {
                                return Alert.info({
                                  title:
                                    'Learning objectives should not be empty',
                                  text: '',
                                });
                              }
                              if (values?.learningObjectives?.length > 0) {
                                if (
                                  values?.learningObjectives.find(
                                    (e: any) => e === values.learningObjective
                                  )
                                ) {
                                  return Alert.info({
                                    title: 'Learning Objective already exists',
                                    text: '',
                                  });
                                }

                                setFieldValue('learningObjectives', [
                                  ...values.learningObjectives,
                                  values.learningObjective,
                                ]);
                              } else {
                                values.learningObjectives = [];
                                setFieldValue('learningObjectives', [
                                  values.learningObjective,
                                ]);
                              }
                              setFieldValue('learningObjective', '');
                              setAdaptiveValueError({
                                ...adaptiveValueError,
                                learningObjectivesError: '',
                              });
                              setEditingContentLearning({
                                ...editingContentLearning,
                                learningObject: '',
                              });
                            }}
                          >
                            <AddCircleOutlineRoundedIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {adaptiveValueError.learningObjectivesError && (
                      <FormHelperText
                        error={
                          adaptiveValueError.learningObjectivesError
                            ? true
                            : false
                        }
                      >
                        <>{adaptiveValueError.learningObjectivesError}</>
                      </FormHelperText>
                    )}
                  </FormControl>
                  <List>
                    {values?.learningObjectives
                      ? values.learningObjectives.map(
                          (option: any, index: any) => (
                            <ListItem key={index}>
                              <ListItemText
                                primary={option}
                                primaryTypographyProps={{
                                  style: {
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                  },
                                }}
                              />
                              <div>
                                <IconButton>
                                  <EditIcon
                                    fontSize="small"
                                    color="primary"
                                    onClick={() => {
                                      let editLearningObjective =
                                        values.learningObjectives.find(
                                          (item: string) =>
                                            item ===
                                            values?.learningObjectives[index]
                                        );

                                      setFieldValue(
                                        'learningObjective',
                                        editLearningObjective
                                      );
                                      let removeLearningObjectives =
                                        values.learningObjectives.filter(
                                          (item: string) =>
                                            item !==
                                            values.learningObjectives[index]
                                        );
                                      if (
                                        editingContentLearning.learningObject
                                      ) {
                                        removeLearningObjectives.push(
                                          editingContentLearning.learningObject
                                        );
                                      }
                                      setFieldValue(
                                        'learningObjectives',
                                        removeLearningObjectives
                                      );
                                      setEditingContentLearning({
                                        ...editingContentLearning,
                                        learningObject: editLearningObjective,
                                      });
                                    }}
                                  />
                                </IconButton>
                                <IconButton>
                                  <DeleteIcon
                                    fontSize="small"
                                    color="error"
                                    onClick={() => {
                                      let removeLearningObjectives =
                                        values.learningObjectives.filter(
                                          (item: string) =>
                                            item !==
                                            values.learningObjectives[index]
                                        );

                                      setFieldValue(
                                        'learningObjectives',
                                        removeLearningObjectives
                                      );
                                    }}
                                  />
                                </IconButton>
                              </div>
                            </ListItem>
                          )
                        )
                      : ''}
                  </List>
                  <ErrorMessage
                    name="newPassword"
                    component="span"
                    className="text-red-600 ml-2 text-sm"
                  />
                </Grid> */}
                </>

                <Grid item xs={4.5} className='bg-slate-50'>
                  <AddCustomForm fieldName='contentModule' />
                  <ContentMLearningOList
                    fieldName='contentModule'
                    handleEdit={handleEditContentModule}
                    handleDelete={handleDeleteContentModule}
                  />
                </Grid>

                <Grid item xs={4.5} className='bg-slate-50'>
                  <AddCustomForm fieldName='learningObjective' />
                  <ContentMLearningOList
                    fieldName='learningObjective'
                    handleEdit={handleEditContentModule}
                    handleDelete={handleDeleteContentModule}
                  />
                </Grid>
              </Grid>
              <div className='flex justify-end items-center gap-4 mb-4 mt-4 ml-4'>
                <div
                  className={`${!instruction ? 'hidden' : 'h-full col-span-6'}`}
                >
                  <SunEditor
                    setContents={formikRef.current?.values?.instruction}
                    height='100%'
                    width='100%'
                    name='instruction'
                    autoFocus={false}
                    placeholder='Instruction'
                    onChange={(e: any) => {
                      formikRef.current.setFieldValue('instruction', e);
                    }}
                    setDefaultStyle='white-space: pre-wrap;'
                    setOptions={{
                      katex: katex,
                      fontSize: fontSizeOptions,
                      mathFontSize: mathFontSizeOptions,
                      mode: 'classic',
                      buttonList: [
                        [
                          'undo',
                          'redo',
                          'font',
                          'fontSize',
                          'formatBlock',
                          'blockquote',
                          'bold',
                          'underline',
                          'italic',
                          'strike',
                          'subscript',
                          'superscript',
                          'fontColor',
                          'hiliteColor',
                          'textStyle',
                          'removeFormat',
                          'outdent',
                          'indent',
                          'align',
                          'horizontalRule',
                          'list',
                          'lineHeight',
                          'fullScreen',
                          'showBlocks',
                          'codeView',
                          'preview',
                          'print',
                          'link',
                          'horizontalRule',
                          'math',
                          'table',
                          'image',
                        ],
                      ],
                    }}
                  />
                </div>
                <Button
                  variant='contained'
                  className='col-span-2'
                  style={{ backgroundColor: '#335D88', width: 'auto' }}
                  onClick={() => {
                    if (instruction) {
                      formikRef.current.setFieldValue('instruction', '');
                      setInstruction(false);
                    } else {
                      setInstruction(true);
                    }
                  }}
                >
                  {instruction ? 'Remove Instruction' : 'Add Instruction'}
                </Button>
              </div>
              <div
                className='flex gap-3 justify-end'
                style={{ padding: '10px' }}
              >
                <Button
                  variant='contained'
                  // fullWidth
                  onClick={() => {
                    Alert.confirm(() => {
                      navigate('/assessment');
                    });
                  }}
                  style={{ textTransform: 'capitalize' }}
                >
                  Close
                </Button>
                <AssessSpecificQuestion
                  handleSaveAssessSpecQue={handleSaveAssessSpecQue}
                  question={assessSpecQuestion}
                />
                <Button
                  variant='contained'
                  type='button'
                  // fullWidth
                  disabled={
                    !values?.discipline?.id ||
                    !values?.officialAssessmentName ||
                    !values?.publicAssessmentName ||
                    !values?.assessmentCode ||
                    !values?.publicCode ||
                    !values?.rFile ||
                    updateAdaptiveLoading
                  }
                  onClick={handleSubmit}
                  style={{ textTransform: 'capitalize' }}
                >
                  Update
                </Button>
                <Button
                  variant='contained'
                  type='button'
                  // fullWidth
                  disabled={
                    !values?.discipline?.id ||
                    !values?.officialAssessmentName ||
                    !values?.publicAssessmentName ||
                    !values?.assessmentCode ||
                    !values?.publicCode ||
                    !values?.rFile ||
                    updateAdaptiveLoading
                  }
                  onClick={() => {
                    // if (data.length < 50) {
                    //   Alert.info({
                    //     title:
                    //       'Atleast 50 items needed to publish a adaptive assessment',
                    //     text: '',
                    //   });
                    //   return;
                    // }

                    // const valuesValidation = data.some(
                    //   (item: any) =>
                    //     item.difficulty >= 0.1 &&
                    //     item.difficulty <= 1.4 &&
                    //     item.discrimination >= -0.9 &&
                    //     item.discrimination <= 0.9 &&
                    //     item.guessing >= 0 &&
                    //     item.guessing <= 0.9
                    // );

                    // if (!valuesValidation) {
                    //   Alert.info({
                    //     title: `For any one item Difficulty should be between 0.1 and 1.4, Discrimination should be between -0.9 and 0.9, Guessing should be between 0 and 0.9`,
                    //     text: '',
                    //   });
                    //   return;
                    // }

                    // let invalidItemIds = validateData(data); // Call the function with your data
                    // if (invalidItemIds.length > 0) {
                    //

                    //   Alert.info({
                    //     title: `Items with Item ids ${invalidItemIds.join(
                    //       ', '
                    //     )} have errors.`,
                    //     text: '',
                    //   });
                    //   return;
                    // } else {
                    setSaveAndPublish(true);
                    handleSubmit();
                    // }
                  }}
                  style={{ textTransform: 'capitalize' }}
                >
                  Save and Publish
                </Button>
              </div>
            </Paper>
            {/* <div>
              values
              <pre>{JSON.stringify(values, null, 2)}</pre>
              touched
              <pre>{JSON.stringify(touched, null, 2)}</pre>
              errors
              <pre>{JSON.stringify(errors, null, 2)}</pre>
              <div className="mb-[20%]"></div>
            </div> */}
          </Form>
        )}
      </Formik>
      <div
        className='ag-theme-alpine'
        style={{
          marginLeft: '0px',
          marginTop: '5px',
        }}
      >
        <div className='flex justify-end gap-4 mb-4'>
          <div className='flex items-center gap-4 justify-between'>
            <div className='button-wrap flex my-1.5 items-center gap-5'>
              <div>
                <Button
                  sx={{ textTransform: 'capitalize' }}
                  variant='contained'
                  // style={{ marginTop: "5px" }}
                  size='small'
                >
                  <label className='new-button' htmlFor='upload'>
                    File Upload
                    <input
                      style={{
                        display: 'none',
                      }}
                      className='choose-file'
                      id='upload'
                      type='file'
                      ref={inputRef}
                      onChange={(e) => handleFileChange(e)}
                    />
                  </label>
                </Button>

                {/* <div>hii</div> */}
              </div>
              {file?.name}

              <Button
                sx={{ textTransform: 'capitalize' }}
                variant='contained'
                size='small'
                onClick={handleUploadClick}
                disabled={isButtonDisabled || isUploading} // Disable the button if no file is selected
              >
                {isUploading ? (
                  <CircularProgress color='inherit' size={23} />
                ) : (
                  'upload'
                )}
              </Button>

              <div className='flex'>
                <Link
                  component='button'
                  variant='body2'
                  onClick={() => {
                    downloadCSV();
                  }}
                >
                  Download Template Format
                </Link>
              </div>
            </div>
            <div className='flex gap-4'>
              <div>
                <Button
                  variant='contained'
                  size='small'
                  sx={{ textTransform: 'initial' }}
                  onClick={() => {
                    setAction('Create');
                    setInitialValue({
                      status: '',
                      slip: '',
                      defaultAnswer: '',
                      guess: '',
                      discrimination: '',
                      difficulty: '',
                      guessing: '',
                      content_modules: {},
                      learning_objectives: {},
                      content_modules_structure: state?.value?.contentModules,
                      learning_objectives_structure:
                        state?.value?.learningObjectives,
                      content_modules_obj: Object.fromEntries(
                        (state?.value?.contentModules || []).map(
                          (module: any) => [module, '']
                        )
                      ),
                      learning_objectives_obj: Object.fromEntries(
                        (state?.value?.learningObjectives || []).map(
                          (obj: any) => [obj, '']
                        )
                      ),
                      questionLabel: '',
                      questionContent: '',
                      responseOptions: [],
                    });
                    setQuestion({
                      questionLabel: '',
                      questionContent: '',
                      responseType: 'Single',
                      optionOrientation: 'Vertical',
                      answer: '',
                      defaultAnswer: '',
                      responseOptions: [],
                    });
                    setIsEdit(false);
                    setOpen(true);
                  }}
                >
                  Add Item
                </Button>
              </div>
            </div>
          </div>
        </div>
        <AgGridReact
          ref={gridRef}
          paginationPageSize={25}
          overlayNoRowsTemplate='No rows to show'
          suppressServerSideInfiniteScroll={false}
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            filter: true,
            resizable: true,
            sortable: true,
            filterParams: {
              suppressAndOrCondition: true,
              trimInput: true,
              buttons: ['reset', 'clear'],
            },
          }}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          pagination={true}
          cacheQuickFilter={true}
          domLayout={'autoHeight'}
          rowData={data}
        />
      </div>
      {/* here */}

      {action === 'View' ? (
        <ViewAdaptiveAssessment
          open={open}
          setOpen={setOpen}
          question={question}
        />
      ) : (
        <Dialog open={open} maxWidth='lg' scroll={'paper'}>
          <DialogTitle sx={{ backgroundColor: '#1663ab', color: 'white' }}>
            <div className='flex justify-between items-center'>
              <div>{action} Adaptive Assessment</div>
              <Fab size='small'>
                <CloseIcon
                  className='cursor-pointer'
                  onClick={handleClickClose}
                />
              </Fab>
            </div>
          </DialogTitle>

          <DialogContent dividers={true}>
            <Formik
              innerRef={formikRef2}
              initialValues={initialValues}
              validationSchema={validation}
              onSubmit={(values, { resetForm }) => {
                setButton(true);
                const transformedData = transformData(values);

                transformedData.assessment_id = state.value.id;
                transformedData.action = action;

                dispatch(
                  addAdaptiveItem(
                    transformedData,
                    setOpen,
                    navigate,
                    setData,
                    setButton
                  )
                );

                // Reset the form if needed
                // resetForm();
              }}
            >
              {({ values, errors, setFieldValue, touched, isValid }) => (
                <Form>
                  {/* Metadata */}
                  <div className=' p-5 my-12 max-w-5xl border border-[#D5D5D5] rounded-md'>
                    {/* <Typography>Metadata </Typography> */}

                    <div className='grid grid-cols-3 gap-x-16 gap-y-8 mb-6'>
                      <FormControl
                        error={Boolean(errors?.status && touched?.status)}
                      >
                        <InputLabel id='demo-simple-select-standard-label'>
                          Status
                        </InputLabel>
                        <Field
                          name='status'
                          label='Status'
                          as={Select}
                          value={values.status}
                          disabled={action === 'View'}
                          onChange={(e: any) => {
                            setFieldValue('status', e.target.value);
                          }}
                          fullWidth
                        >
                          <MenuItem value='A'>Active</MenuItem>
                          <MenuItem value='T'>Test</MenuItem>
                          <MenuItem value='I'>Inactive</MenuItem>
                          <MenuItem value='R'>Retired</MenuItem>
                        </Field>
                        <ErrorMessage
                          name='status'
                          component='div'
                          className='text-red-600 ml-2 mt-2 text-xs'
                        />
                      </FormControl>

                      {/* <FormControl
                      error={Boolean(
                        errors?.defaultAnswer && touched?.defaultAnswer
                      )}
                      variant="outlined"
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Key
                      </InputLabel>
                      <Field
                        label="Key"
                        name="key"
                        as={Select}
                        native={false}
                        value={values.defaultAnswer}
                        disabled={action === 'View'}
                        onChange={(e: any) => {
                          setFieldValue('defaultAnswer', e.target.value);
                        }}
                        fullWidth
                      >
                        {values?.responseOptions.map((d: any, i: any) => (
                          <MenuItem key={i} value={i + 1}>
                            {i + 1}
                          </MenuItem>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="defaultAnswer"
                        component="div"
                        className="text-red-600 ml-2 text-xs"
                      />
                    </FormControl> */}
                      {state.value.slip && (
                        <Field
                          variant='outlined'
                          as={TextField}
                          label='DINA Slip'
                          name='slip'
                          type='number'
                          value={values.slip}
                          disabled={action === 'View'}
                          onChange={(e: any) => {
                            setFieldValue('slip', e.target.value);
                          }}
                          helperText={
                            errors.slip && touched.slip ? errors.slip : ''
                          }
                          error={errors.slip && touched.slip}
                          fullWidth
                        />
                      )}
                      {state.value.guess && (
                        <Field
                          variant='outlined'
                          as={TextField}
                          label='DINA Guess'
                          name='guess'
                          type='number'
                          value={values.guess}
                          disabled={action === 'View'}
                          onChange={(e: any) => {
                            setFieldValue('guess', e.target.value);
                          }}
                          helperText={
                            errors.guess && touched.guess ? errors.guess : ''
                          }
                          error={errors.guess && touched.guess}
                          fullWidth
                        />
                      )}
                      {state.value.discrimination && (
                        <Field
                          variant='outlined'
                          as={TextField}
                          label='Discrimination'
                          name='discrimination'
                          type='number'
                          value={values.discrimination}
                          disabled={action === 'View'}
                          onChange={(e: any) => {
                            setFieldValue('discrimination', e.target.value);
                          }}
                          helperText={
                            errors.discrimination && touched.discrimination
                              ? errors.discrimination
                              : ''
                          }
                          error={
                            errors.discrimination && touched.discrimination
                          }
                          fullWidth
                        />
                      )}
                      {state.value.difficulty && (
                        <Field
                          variant='outlined'
                          as={TextField}
                          label='Difficulty'
                          name='difficulty'
                          type='number'
                          value={values.difficulty}
                          disabled={action === 'View'}
                          onChange={(e: any) => {
                            setFieldValue('difficulty', e.target.value);
                          }}
                          helperText={
                            errors.difficulty && touched.difficulty
                              ? errors.difficulty
                              : ''
                          }
                          error={errors.difficulty && touched.difficulty}
                          fullWidth
                        />
                      )}
                      {state.value.guessing && (
                        <Field
                          variant='outlined'
                          as={TextField}
                          label='Guessing'
                          name='guessing'
                          type='number'
                          value={values.guessing}
                          disabled={action === 'View'}
                          onChange={(e: any) => {
                            setFieldValue('guessing', e.target.value);
                          }}
                          helperText={
                            errors.guessing && touched.guessing
                              ? errors.guessing
                              : ''
                          }
                          error={errors.guessing && touched.guessing}
                          fullWidth
                        />
                      )}

                      {state?.value?.contentModules?.map((d: any) => (
                        <FormControl variant='outlined'>
                          <InputLabel id={`demo-simple-select-${d}-label`}>
                            {d}
                          </InputLabel>
                          <Field
                            name={`content_modules_obj.${d}`}
                            label={d}
                            id={`demo-simple-select-${d}`}
                            as={Select}
                            value={values?.content_modules_obj?.[d]}
                            disabled={action === 'View'}
                            onChange={(e: any) => {
                              setFieldValue(
                                `content_modules_obj.${d}`,
                                e.target.value
                              );
                            }}
                            fullWidth
                          >
                            <MenuItem value='1'>Yes</MenuItem>
                            <MenuItem value='0'>No</MenuItem>
                          </Field>
                          <ErrorMessage
                            name={`content_modules_obj.${d}`}
                            component='div'
                            className='text-red-600 ml-2 text-xs'
                          />
                        </FormControl>
                      ))}

                      {state?.value?.learningObjectives?.map((d: any) => (
                        <FormControl variant='outlined'>
                          <InputLabel id='demo-simple-select-standard-label'>
                            {d}
                          </InputLabel>
                          <Field
                            name={`learning_objectives_obj.${d}`}
                            label={d}
                            id='demo-simple-select-standard'
                            as={Select}
                            value={values?.learning_objectives_obj?.[d]}
                            disabled={action === 'View'}
                            onChange={(e: any) => {
                              setFieldValue(
                                `learning_objectives_obj.${d}`,
                                e.target.value
                              );
                            }}
                            fullWidth
                          >
                            <MenuItem value='1'>Yes</MenuItem>
                            <MenuItem value='0'>No</MenuItem>
                          </Field>
                          <ErrorMessage
                            name={`learning_objectives_obj.${d}`}
                            component='div'
                            className='text-red-600 ml-2 text-xs'
                          />
                        </FormControl>
                      ))}
                    </div>
                  </div>
                  {/* Question */}
                  <div className={`max-w-5xl flex flex-row`}>
                    <Accordion
                      sx={{
                        borderRadius: '5px',
                        marginBottom: '10px',
                        // backgroundColor: 'rgb(248, 250, 252)',
                      }}
                      className='p-3 w-full border border-[#D5D5D5]'
                      defaultExpanded={true}
                      elevation={4}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant='h6' color='primary'>
                          Question
                        </Typography>
                        <Field
                          as={TextField}
                          disabled={action === 'View'}
                          size='small'
                          // className='col-span-1'
                          sx={{ marginLeft: '21px', width: '250px' }}
                          variant='outlined'
                          // label='Question Label'
                          name='questionLabel'
                          onChange={(e: any) => {
                            setFieldValue('questionLabel', e.target.value);
                            columnChange(e);
                          }}
                          value={question?.questionLabel}
                          type='text'
                          // inputProps={{ maxLength: 3 }}
                          error={Boolean(
                            errors.questionLabel && touched.questionLabel
                          )}
                          helperText={
                            touched.questionLabel ? errors.questionLabel : ''
                          }
                          onClick={(e: any) => {
                            e.stopPropagation();
                          }}
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className='grid grid-cols-12 gap-x-3 gap-y-8'>
                          {/* 1 */}
                          {/* <Tooltip title='Question Label:' placement='top'>
                          <Field
                            as={TextField}
                            disabled={action === 'View'}
                            size='small'
                            className='col-span-1'
                            sx={{ marginTop: '21px', width: '96px' }}
                            variant='outlined'
                            label='Question Label'
                            name='questionLabel'
                            onChange={(e: any) => {
                              setFieldValue('questionLabel', e.target.value);
                              columnChange(e);
                            }}
                            value={question?.questionLabel}
                            type='text'
                            inputProps={{ maxLength: 3 }}
                            error={Boolean(
                              errors.questionLabel && touched.questionLabel
                            )}
                            helperText={
                              touched.questionLabel ? errors.questionLabel : ''
                            }
                          />
                        </Tooltip> */}

                          {/* 2 */}
                          <div className='col-span-12 max-h-max pl-10'>
                            <label style={{ fontSize: '12px' }}>
                              Question Content:
                            </label>
                            <SunEditor
                              setContents={question?.questionContent}
                              name='questionContent'
                              onChange={(content) => {
                                // Remove HTML tags and trim whitespace
                                const textContent = content
                                  .replace(/<[^>]*>/g, '')
                                  .trim();
                                const isEmpty = textContent === '';

                                setFieldValue(
                                  'questionContent',
                                  isEmpty ? '' : content
                                );
                                columnChange({
                                  target: {
                                    name: 'questionContent',
                                    value: isEmpty ? '' : content,
                                  },
                                });
                              }}
                              setOptions={{
                                katex: katex,
                                fontSize: fontSizeOptions,
                                mathFontSize: mathFontSizeOptions,
                                mode: 'classic',
                                plugins: [
                                  plugins.image,
                                  plugins.table,
                                  plugins.math, // for math
                                  plugins.horizontalRule,
                                  plugins.lineHeight,
                                  plugins.list,
                                  plugins.align,
                                  plugins.textStyle,
                                  plugins.fontColor,
                                  plugins.hiliteColor,
                                  plugins.blockquote,
                                  plugins.font,
                                  plugins.fontSize,
                                  plugins.formatBlock,
                                  createMergeTag(
                                    'Item',
                                    data.filter(
                                      (_: any, index: any) =>
                                        _.item_id < initialValues.item_id
                                    )
                                    // data
                                  ),
                                ],
                                buttonList: [
                                  [
                                    'undo',
                                    'redo',
                                    'font',
                                    'fontSize',
                                    'formatBlock',
                                    'blockquote',
                                    'bold',
                                    'underline',
                                    'italic',
                                    'strike',
                                    'subscript',
                                    'superscript',
                                    'fontColor',
                                    'hiliteColor',
                                    'textStyle',
                                    'removeFormat',
                                    'outdent',
                                    'indent',
                                    'align',
                                    'horizontalRule',
                                    'list',
                                    'lineHeight',
                                    'fullScreen',
                                    'showBlocks',
                                    'codeView',
                                    'preview',
                                    'print',
                                    'link',
                                    'horizontalRule',
                                    'math',
                                    'table',
                                    'image',
                                    'Attach Question',
                                  ],
                                ],
                              }}
                              setDefaultStyle='height: auto; white-space: pre-wrap;'
                              disable={action === 'View'}
                              autoFocus={false}
                            />
                            <ErrorMessage
                              name={`questionContent`}
                              component='div'
                              className='text-red-600 ml-2 text-xs'
                            />
                          </div>

                          {/* 3 */}
                          <div className=' col-span-9'>
                            {/* option parts */}
                            <div className='grid gap-y-8'>
                              {/* repsonse options */}
                              {question?.responseOptions?.map(
                                (d: any, index: any) => {
                                  if (
                                    question?.responseType == 'Single' ||
                                    question?.responseType == 'Multiple'
                                  ) {
                                    return (
                                      // single option divide into four part (2 + 10)
                                      <div className='grid grid-cols-12 gap-1'>
                                        {/* checkbox or radio button into 12 (2 + 10) */}
                                        <div className='mt-6 col-span-2 grid grid-cols-4 items-center'>
                                          {question?.responseType ==
                                          'Multiple' ? (
                                            <Checkbox
                                              size='small'
                                              name={JSON.stringify(index + 1)}
                                              checked={Boolean(
                                                question?.defaultAnswer[
                                                  index + 1
                                                ]
                                              )}
                                              onChange={(e) => {
                                                onCheckboxChange(e);
                                              }}
                                              disabled={action === 'View'}
                                            />
                                          ) : (
                                            ''
                                          )}
                                          {question?.responseType ==
                                          'Single' ? (
                                            <Radio
                                              checked={
                                                JSON.stringify(index + 1) ==
                                                question?.defaultAnswer
                                              }
                                              onChange={(e: any) => {
                                                handleRadioChange(e);
                                              }}
                                              value={JSON.stringify(index + 1)}
                                              name={`radio-${index + 1}`}
                                              disabled={action === 'View'}
                                            />
                                          ) : (
                                            ''
                                          )}

                                          {/* text field displays option label */}
                                          <Tooltip
                                            title='Option Label: '
                                            placement='top'
                                          >
                                            <Field
                                              // error={
                                              //   //@ts-ignore
                                              //   errors?.responseOptions?.[index]
                                              // }

                                              as={TextField}
                                              inputProps={{ maxLength: 3 }}
                                              style={{
                                                width: '60px',
                                              }}
                                              size='small'
                                              name='optionLabel'
                                              onChange={(e: any) => {
                                                setFieldValue(
                                                  `responseOptions.${index}.optionLabel`,
                                                  e.target.value
                                                );
                                                columnOptionChange(
                                                  {
                                                    target: {
                                                      name: 'optionLabel',
                                                      value: e.target.value,
                                                    },
                                                  },
                                                  index
                                                );
                                              }}
                                              value={d?.optionLabel}
                                              type='text'
                                              disabled={action === 'View'}
                                            />
                                          </Tooltip>
                                          <ErrorMessage
                                            name={`responseOptions.${index}.optionLabel`}
                                            component='div'
                                            className='text-red-600 mt-8 text-xs'
                                          />
                                        </div>

                                        <div className='col-span-10 mr-6'>
                                          <label style={{ fontSize: '12px' }}>
                                            Option Content:
                                          </label>
                                          {d?.optionType && (
                                            <span className='text-xs italic text-sky-600 font-bold'>
                                              ({d?.optionType})
                                            </span>
                                          )}
                                          <SunEditor
                                            setContents={d?.optionContent}
                                            disable={(() => {
                                              if (
                                                d?.optionType
                                                // data?.responseType ==
                                                //   'Multiple' &&
                                                // (d?.optionContent ==
                                                //   'None of the above' ||
                                                //   d?.optionContent ==
                                                //     '<p>None of the above</p>')
                                              ) {
                                                return true;
                                              } else if (action === 'View') {
                                                return true;
                                              } else return false;
                                            })()}
                                            name='responseOptions'
                                            autoFocus={false}
                                            onChange={(content: string) => {
                                              setFieldValue(
                                                `responseOptions.${index}.optionContent`,
                                                content
                                              );

                                              columnOptionChange(
                                                {
                                                  target: {
                                                    name: 'optionContent',
                                                    value: content,
                                                  },
                                                },
                                                index
                                              );
                                            }}
                                            setDefaultStyle='height: auto; white-space: pre-wrap;'
                                            setOptions={{
                                              katex: katex,
                                              fontSize: fontSizeOptions,
                                              mathFontSize: mathFontSizeOptions,
                                              mode:
                                                state.value?.likert == 'true' ||
                                                state.value?.likert == true
                                                  ? 'classic'
                                                  : 'classic',
                                              buttonList: [
                                                [
                                                  'undo',
                                                  'redo',
                                                  'font',
                                                  'fontSize',
                                                  'formatBlock',
                                                  'blockquote',
                                                  'bold',
                                                  'underline',
                                                  'italic',
                                                  'strike',
                                                  'subscript',
                                                  'superscript',
                                                  'fontColor',
                                                  'hiliteColor',
                                                  'textStyle',
                                                  'removeFormat',
                                                  'outdent',
                                                  'indent',
                                                  'align',
                                                  'horizontalRule',
                                                  'list',
                                                  'lineHeight',
                                                  'fullScreen',
                                                  'showBlocks',
                                                  'codeView',
                                                  'preview',
                                                  'print',
                                                  'link',
                                                  'horizontalRule',
                                                  'math',
                                                  'table',
                                                  'image',
                                                ],
                                              ],
                                            }}
                                          />
                                          <ErrorMessage
                                            name={`responseOptions.${index}.optionContent`}
                                            component='div'
                                            className='text-red-600 ml-2 text-xs'
                                          />
                                        </div>
                                      </div>
                                    );
                                  }
                                  if (question?.responseType == 'Open') {
                                    return (
                                      <div className='grid grid-cols-12 mr-2'>
                                        <TextField
                                          // error={
                                          //   //@ts-ignore
                                          //   errors?.responseOptions?.[index]
                                          //     ?.optionContent
                                          // }
                                          className='col-start-3 col-span-full'
                                          fullWidth
                                          multiline
                                          name='optionContent'
                                          label='Response Input:'
                                          value={d?.optionContent}
                                          disabled={action === 'View'}
                                          onChange={(e: any) => {
                                            setFieldValue(
                                              `responseOptions.${index}.optionContent`,
                                              e.target.value
                                            );
                                            columnOptionChange(
                                              {
                                                target: {
                                                  name: 'optionContent',
                                                  value: e.target.value,
                                                },
                                              },
                                              index
                                            );
                                          }}
                                          size='small'
                                        />
                                        <ErrorMessage
                                          name={`responseOptions.${index}.optionContent`}
                                          component='div'
                                          className='text-red-600 ml-2 text-xs'
                                        />
                                      </div>
                                    );
                                  }
                                  if (question?.responseType == 'Number') {
                                    return (
                                      <Grid
                                        xs={12}
                                        md={12}
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          // marginBottom: '20px',
                                          marginLeft: '120px',
                                        }}
                                      >
                                        <TextField
                                          // error={ //@ts-ignore
                                          //   errors?.responseOptions?.[index]
                                          //     ?.optionLabel
                                          // }
                                          size='small'
                                          label='Correct Response:'
                                          name='optionLabel'
                                          value={Number(d?.optionLabel)}
                                          disabled={action === 'View'}
                                          inputMode='decimal'
                                          onChange={(e: any) => {
                                            setFieldValue(
                                              `responseOptions.${index}.optionLabel`,
                                              e.target.value
                                            );
                                            columnOptionChange(
                                              {
                                                target: {
                                                  name: 'optionLabel',
                                                  value: e.target.value,
                                                },
                                              },
                                              index
                                            );
                                          }}
                                          type='number'
                                        />
                                        <TextField
                                          // error={ //@ts-ignore
                                          //   errors?.responseOptions?.[index]
                                          //   ?.optionContent
                                          // }
                                          size='small'
                                          label='Allowed Error (+/-):'
                                          name='optionContent'
                                          disabled={action === 'View'}
                                          value={Number(d?.optionContent)}
                                          onChange={(e: any) => {
                                            setFieldValue(
                                              `responseOptions.${index}.optionContent`,
                                              e.target.value
                                            );
                                            columnOptionChange(
                                              {
                                                target: {
                                                  name: 'optionContent',
                                                  value: e.target.value,
                                                },
                                              },
                                              index
                                            );
                                          }}
                                          type='number'
                                          inputMode='decimal'
                                        />
                                      </Grid>
                                    );
                                  }
                                  if (question?.responseType == 'Text') {
                                    return (
                                      <div className='grid grid-cols-12 mr-2'>
                                        <TextField
                                          // error={//@ts-ignore
                                          //   errors?.responseOptions?.[index]
                                          //     ?.optionContent
                                          // }
                                          className='col-start-3 col-span-full'
                                          fullWidth
                                          label='Correct Response'
                                          name='optionContent'
                                          value={d?.optionContent}
                                          onChange={(e: any) => {
                                            setFieldValue(
                                              `responseOptions.${index}.optionContent`,
                                              e.target.value
                                            );
                                            columnOptionChange(
                                              {
                                                target: {
                                                  name: 'optionContent',
                                                  value: e.target.value,
                                                },
                                              },
                                              index
                                            );
                                          }}
                                          type='text'
                                          disabled={action === 'View'}
                                          size='small'
                                        />
                                      </div>
                                    );
                                  }
                                }
                              )}
                            </div>

                            {/* button parts */}
                            <div
                              className={`${
                                question?.responseType === '' ||
                                question?.responseType === 'Number' ||
                                question?.responseType === 'Text' ||
                                question?.responseType === 'Open' ||
                                question?.responseType === 'None'
                                  ? 'hidden'
                                  : 'mt-5 grid grid-cols-4 gap-x-5'
                              }`}
                            >
                              <Button
                                size='small'
                                onClick={() => handleOptionAdd(setFieldValue)}
                                disabled={action === 'View'}
                              >
                                Add Option
                              </Button>

                              {question.responseType === 'Multiple' &&
                                question?.responseOptions?.every(
                                  (option: any) => {
                                    const res = option?.optionType !== 'none';
                                    return res;
                                  }
                                ) && (
                                  <Button
                                    size='small'
                                    onClick={() =>
                                      handleOptionAdd(setFieldValue, 'none')
                                    }
                                    disabled={action === 'View'}
                                  >
                                    Add None
                                  </Button>
                                )}

                              {(question.responseType === 'Single' ||
                                question.responseType === 'Multiple') &&
                                question?.responseOptions?.every(
                                  (option: any) => {
                                    const res = option?.optionType !== 'other';
                                    return res;
                                  }
                                ) && (
                                  <Button
                                    size='small'
                                    disabled={action === 'View'}
                                    onClick={() =>
                                      handleOptionAdd(setFieldValue, 'other')
                                    }
                                  >
                                    Add Other
                                  </Button>
                                )}

                              <Button
                                size='small'
                                onClick={() =>
                                  handleDeleteOption(setFieldValue)
                                }
                                disabled={action === 'View'}
                              >
                                Remove Option
                              </Button>
                            </div>
                          </div>

                          {/* 4 */}
                          <div
                            className={`bg-slate-50 col-span-3 flex flex-col flex-start gap-y-6 ${
                              data.responseType === 'Single' ||
                              data.responseType === 'Multiple'
                                ? 'py-10'
                                : ''
                            }`}
                          >
                            <FormControl size='small' variant='outlined'>
                              <InputLabel>Response Type:</InputLabel>
                              <Select
                                labelId='demo-select-small'
                                id='demo-select-small'
                                value={question?.responseType}
                                name='responseType'
                                label='Response Type:'
                                onChange={(e) => columnChange(e)}
                                defaultValue='Single'
                                disabled={action === 'View'}
                              >
                                <MenuItem value='None'>None</MenuItem>
                                <MenuItem value='Single'>Single</MenuItem>
                                <MenuItem value='Multiple'>Multiple</MenuItem>
                                <MenuItem value='Number'>Number</MenuItem>
                                <MenuItem value='Text'>Text</MenuItem>
                                <MenuItem value='Open'>Open</MenuItem>
                              </Select>
                            </FormControl>
                            <FormControl
                              size='small'
                              variant='outlined'
                              style={
                                question?.responseType == 'Single' ||
                                question?.responseType == 'Multiple'
                                  ? { marginTop: '10px', marginBottom: '10px' }
                                  : { display: 'none' }
                              }
                            >
                              <InputLabel>Options Orientation:</InputLabel>
                              <Select
                                labelId='demo-select-small'
                                id='demo-select-small'
                                value={question?.optionOrientation}
                                name='optionOrientation'
                                label='Options Orientation:'
                                onChange={(e) => columnChange(e)}
                                defaultValue='Vertical'
                                disabled={action === 'View'}
                              >
                                <MenuItem value='Vertical'>Vertical</MenuItem>
                                <MenuItem value='Horizontal'>
                                  Horizontal
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  {/* <pre>{JSON.stringify(question, null, 2)}</pre>
                values
                <pre>{JSON.stringify(values, null, 2)}</pre>
                touched
                <pre>{JSON.stringify(touched, null, 2)}</pre>
                errors
                <pre>{JSON.stringify(errors, null, 2)}</pre>
                <div className="mb-[20%]"></div> */}
                </Form>
              )}
            </Formik>
          </DialogContent>
          <DialogActions>
            <div className='flex gap-5 justify-around mt-5'>
              <Button
                size='large'
                variant='contained'
                style={{ textTransform: 'capitalize', width: '110px' }}
                onClick={handleClickClose}
              >
                Cancel
              </Button>
              <Button
                size='large'
                variant='contained'
                color='primary'
                type='submit'
                disabled={
                  action === 'View' || button
                    ? true
                    : formikRef?.current?.isValid
                    ? !formikRef?.current?.isValid
                    : true
                }
                onClick={handleSaveQuestion}
                style={{
                  textTransform: 'capitalize',
                  width: '110px',
                }}
              >
                Save
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      )}

      <AssessmentDiscipline open={openForm} setOpenForm={setOpenForm} />
    </main>
  );
};

export default AddEditAdaptiveAssessment;

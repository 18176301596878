import React, { useEffect, useState } from 'react';
import {
  deleteCourseAssessData,
  getAssessmentsByCourse,
  getCourseDetail,
} from '../../reduxStore/reducer/courseReducer';
import { MdDelete } from 'react-icons/md';
import { CellClickedEvent, ICellRendererParams } from 'ag-grid-community';
import { DialogProps, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../reduxStore/Store';
import { ServerSideGrid } from '../../utils/MasterGrid/ServerSideGrid';
import AddCourseAssessment from './AddCourseAssessment';
import Assessment from '../Assessment/Assessment';

const CourseAssessmentGridPage = () => {
  const [initialValues, setInitialValue] = React.useState<any>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [openAssessment, setOpenAssessment] = useState<boolean>(false);
  const [masterGridparams, setMasterGridParams] = useState<any>(null);
  const [assessmentInitialValues, setAssessmentInitialValues] = useState<any>();
  const [courseDetails, setCourseDetails] = useState<any>(null);

  const navigate = useNavigate();
  const { id } = useParams();
  const [gridApi, setGridApi] = useState<any>(null);
  const [columnApi, setColumnApi] = useState<any>(null);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const fetchCourseDetails = async () => {
      const details = await getCourseDetail({ id });
      setCourseDetails(details);
    };

    fetchCourseDetails();
  }, [id]);

  const assessmentDef = [
    {
      headerName: 'Assessment',
      field: 'official_name',
      width: 353,
      onCellClicked: (e: CellClickedEvent) => {
        navigate(`/administration/${e.data.id}/${id}`, {
          state: { courseName: courseDetails?.course_details_id?.name },
        });
      },
    },
    {
      headerName: 'Action',
      field: 'action',
      sortable: false,
      filter: false,
      headerClass: 'start-header',
      width: 100,
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <div className='flex justify-start'>
            <Tooltip title='Edit'>
              <IconButton
                onClick={() => {
                  const temp = params.data.id;
                  params.data.id = id;
                  getCourseDetail(params.data).then((data) => {
                    const dat = {
                      ...data,
                      official_name: params.data.official_name,
                      administration: params.data.no_of_administrations,
                      id: temp,
                      assessment_id: params.data.assessment_id,
                      no_of_administrations: params.data.no_of_administrations,
                      type: params.data.type,
                    };

                    setInitialValue({
                      id: dat.id ? dat.id : '',
                      assessment_id: {
                        official_name: dat?.official_name
                          ? dat.official_name
                          : '',
                        assessment_id: dat?.assessment_id
                          ? dat.assessment_id
                          : '',
                        code: params?.data?.code ? params?.data?.code : '',
                        type: dat?.type ? dat.type : '',
                      },
                      no_of_administrations: dat?.no_of_administrations
                        ? dat.no_of_administrations
                        : 2,
                      created_by: '',
                    });

                    setAssessmentInitialValues(dat);
                    setIsEdit(true);
                    setOpenAssessment(true);
                  });
                  params.data.id = temp; //reassign the changed id
                }}
              >
                <EditIcon className='float-right text-blue-600' />
              </IconButton>
            </Tooltip>

            <Tooltip title='Delete'>
              <IconButton
                onClick={() => {
                  Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    backdrop: true,
                    showCloseButton: true,
                    confirmButtonText: 'Yes, delete it!',
                    allowOutsideClick: false,
                    allowEscapeKey: true,
                    width: 300,
                    heightAuto: false,
                    customClass: {
                      popup: 'swal-title-color',
                      icon: 'swal-title-icon',
                      confirmButton: 'swal-confirm',
                    },
                  }).then(async (result: any) => {
                    if (result.isConfirmed) {
                      dispatch(deleteCourseAssessData(params, gridApi));
                    }
                  });
                }}
              >
                <MdDelete className='float-right text-red-600' />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const getAssessmentByCourse = (params: any) => {
    setMasterGridParams(params);
    params.data.id = id;
    getAssessmentsByCourse(params);
  };

  return (
    <main>
      <header className='header'>
        {courseDetails &&
          courseDetails.course_details_id &&
          `Course Assessment - ${courseDetails.course_details_id.name}`}{' '}
      </header>
      <div>
        <ServerSideGrid
          rowDataUrl={`/course/get-assessment-by-course/${id}`}
          columnDefs={assessmentDef}
          gridApi={gridApi}
          columnApi={columnApi}
          setGridApi={setGridApi}
          setColumnApi={setColumnApi}
          detailRowHeight={325}
        />
        {assessmentInitialValues && (
          <AddCourseAssessment
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            openAssessment={openAssessment}
            setOpenAssessment={setOpenAssessment}
            assessmentInitialValues={assessmentInitialValues}
            params={masterGridparams}
            getAssessmentByCourse={getAssessmentByCourse}
            initialValues={initialValues}
            setInitialValue={setInitialValue}
            gridApi={gridApi}
          />
        )}
      </div>
    </main>
  );
};

export default CourseAssessmentGridPage;
